import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import UserContext from "../../config/UserContext";
import {
  HttpService,
  API,
  swal,
  Title,
  toast,
  Messages,
  DateFormat,
  Common,
  Permission_keys,
  ExportToExcel,
} from "../../config/react";
import DataTable from "react-data-table-component";
import { useHistory } from "react-router-dom";
import Spinner from "../Spinner";
const Subscribers = () => {
  const history = useHistory();
  const [IsAuth, setIsAuth] = useState(false);
  const [SubscribersList, setSubscribersList] = useState([]);
  const user = useContext(UserContext);
  const [isForceUpdate, setForceUpdate] = useState(false);

  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
    },
    {
      name: "Email",
      selector: (row) => row.email,
    },
    {
      name: "Gender",
      selector: (row) => row.gender,
    },
    {
      name: "Created Date",
      selector: (row) => <DateFormat value={row.created_at}></DateFormat>,
    },
    {
      name: "Status",
      selector: (row) =>
        row.status == 1 ? <label>Active</label> : <label>Inactive</label>,
    },
    {
      name: "Action",
      selector: (row) => (
        <div className="action-btn">
          {row.status == 1 ? (
            <Link
              onClick={(e) => DeactivateSubscriber(e, row.id)}
              to="#"
              className="text-dark"
              title="Deactivate"
            >
              <i className="fa fa-trash-o" aria-hidden="true"></i>{" "}
            </Link>
          ) : (
            ""
          )}
        </div>
      ),
    },
  ];

  const DefaultFilters = {
    page: 1,
    search: "",
    page_size: 10,
  };
  const [CustomFilters, setCustomFilters] = useState(DefaultFilters);
  const [totalRows, settotalRows] = useState();

  useEffect(() => {
    if (!Common.validatePermission(Permission_keys.Newsletter)) {
      history.push("/");
    }
    setIsAuth(true);
    setForceUpdate(!isForceUpdate);
    document.title = Title.Subscriber;
    GetSubscribersList();
  }, [CustomFilters]);

  const handlePageChange = (e) => {
    setCustomFilters({ ...CustomFilters, ["page"]: e });
  };

  const handlePerRowsChange = (e) => {
    setCustomFilters({ ...CustomFilters, ["page_size"]: e });
  };

  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <input
        type="text"
        title="Search For Email."
        className="form-control col-md-3"
        placeholder="Search"
        onChange={(e) =>
          setCustomFilters({
            ...CustomFilters,
            ["page"]: 1,
            ["search"]: e.target.value,
          })
        }
      />
    );
  });

  const GetSubscribersList = () => {
    var newsletterEmailData = {
      page: CustomFilters.page,
      search: CustomFilters.search,
      page_size: CustomFilters.page_size,
    };
    HttpService.postApi(API.GetSubscribersList, newsletterEmailData).then(
      (response) => {
        if (response.data.status == true) {
          var data = response.data.result[1];

          setSubscribersList(data);
          setForceUpdate(!isForceUpdate);
          settotalRows(response.data.result[0][0].total_newsletter_email);
        }
      }
    );
  };

  const DeactivateSubscriber = (e, id) => {
    var param = {
      id: id,
    };
    swal(Messages.Swal_Deactivate_Prop).then(function (isConfirm) {
      if (isConfirm) {
        var param = {
          id: id,
        };
        HttpService.postApi(API.DeactivateSubscriber, param).then(
          (response) => {
            toast.success(Messages.Subscriber_Deactivated);
            GetSubscribersList();
            setForceUpdate(!isForceUpdate);
          }
        );
      }
    });
  };
  const handleExportExcel = () => {
    HttpService.getApi(API.GetSubscribersExcel).then((response) => {
      if (response.data.result) {
        ExportToExcel(response.data.result[0], "Subscribers");
      }
    });
  };

  return !IsAuth ? (
    <Spinner />
  ) : (
    <div className="content-wrapper pb-0">
      <div className="page-header">
        <h3 className="page-title"> {Title.Subscriber} </h3>
      </div>
      <div className="row">
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <button
                className="btn btn-primary float-right m-1"
                onClick={handleExportExcel}
              >
                Export Excel
              </button>
              <div className="table-responsive">
                <DataTable
                  // title="Subscriber List"
                  columns={columns}
                  data={SubscribersList}
                  pagination
                  paginationServer
                  paginationTotalRows={totalRows}
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                  subHeader
                  subHeaderComponent={subHeaderComponentMemo}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Subscribers;
