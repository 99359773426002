import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import {
  HttpService,
  API,
  swal,
  Title,
  toast,
  MaterialTable,
  Messages,
  DateFormat,
  Common,
  Permission_keys,
} from "../../config/react";
import DataTable from "react-data-table-component";
import { useHistory } from "react-router-dom";
import Spinner from "../Spinner";
const ContentManagement = () => {
  const history = useHistory();
  const [IsAuth, setIsAuth] = useState(false);
  const [CmsList, setCmsList] = useState([]);
  const [isForceUpdate, setForceUpdate] = useState(false);
  const columns = [
    {
      name: "Title",
      selector: (row) => row.title,
    },
    {
      name: "Page Code",
      selector: (row) => row.page_code,
    },
    {
      name: "Slug",
      selector: (row) => row.slug,
    },
    {
      name: "Created Date",
      selector: (row) => <DateFormat value={row.created_at}></DateFormat>,
    },
    {
      name: "Status",
      selector: (row) =>
        row.status == 1 ? <label>Active</label> : <label>Inactive</label>,
    },
    {
      name: "Action",
      selector: (row) => (
        <div className="action-btn">
          <Link
            to={"/contentManagement/edit/" + row.id}
            className="text-dark"
            title="Edit"
          >
            <i className="fa fa-pencil-square-o" aria-hidden="true"></i>{" "}
          </Link>
          <Link
            onClick={(e) => DeleteContentManagement(e, row.id)}
            to=""
            className="text-dark"
            title="Delete"
          >
            <i className="fa fa-trash-o" aria-hidden="true"></i>{" "}
          </Link>
        </div>
      ),
    },
  ];

  const DefaultFilters = {
    page: 1,
    search: "",
    page_size: 10,
  };
  const [CustomFilters, setCustomFilters] = useState(DefaultFilters);
  const [totalRows, settotalRows] = useState();

  useEffect(() => {
    if (!Common.validatePermission(Permission_keys.Content_Management)) {
      history.push("/");
    }
    setIsAuth(true);
    setForceUpdate(!isForceUpdate);
    document.title = Title.ContentManagement;
    GetContentManagementList();
  }, [CustomFilters]);

  const handlePageChange = (e) => {
    setCustomFilters({ ...CustomFilters, ["page"]: e });
  };

  const handlePerRowsChange = (e) => {
    setCustomFilters({ ...CustomFilters, ["page_size"]: e });
  };

  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <input
        type="text"
        title="Search For Title, Page Code, Slug."
        className="form-control col-md-3"
        placeholder="Search"
        onChange={(e) =>
          setCustomFilters({
            ...CustomFilters,
            ["page"]: 1,
            ["search"]: e.target.value,
          })
        }
      />
    );
  });

  const GetContentManagementList = () => {
    var pass_params = {
      page: CustomFilters.page,
      search: CustomFilters.search,
      page_size: CustomFilters.page_size,
    };
    HttpService.postApi(API.GetContentManagementAdminList, pass_params).then(
      (response) => {
        // setCmsList(response.data.result)

        if (response.data.status == true) {
          var data = response.data.result[1];
          // var data = response.data.result[0];

          setCmsList(data);
          setForceUpdate(!isForceUpdate);
          settotalRows(response.data.result[0][0].total_pages);
        }
      }
    );
  };

  const DeleteContentManagement = async (e, id) => {
    e.preventDefault();
    swal(Messages.Swal_Delete_Prop).then(function (isConfirm) {
      if (isConfirm) {
        var param = {
          id: id,
          IsDeleted: true,
        };

        HttpService.postApi(API.DeleteContentManagement, param).then(
          (response) => {
            toast.success(Messages.Record_Deleted);
            GetContentManagementList();
            setForceUpdate(!isForceUpdate);
          }
        );
      }
    });
  };

  return !IsAuth ? (
    <Spinner />
  ) : (
    <div className="content-wrapper pb-0">
      <div className="page-header">
        <h3 className="page-title"> {Title.ContentManagement} </h3>
        <div className="btn-group">
          <Link
            to="/contentManagement/add"
            className="btn btn-dark btn-fw rounded mr-2"
          >
            {" "}
            {Title.AddContentManagement}{" "}
          </Link>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="table-responsive">
                <DataTable
                  // title="Content Management List"
                  columns={columns}
                  data={CmsList}
                  pagination
                  paginationServer
                  paginationTotalRows={totalRows}
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                  subHeader
                  subHeaderComponent={subHeaderComponentMemo}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentManagement;
