import React, { useState, useEffect, useRef } from 'react';
import { useHistory, Link } from "react-router-dom";
import { HttpService, API, Title, toast, Messages, swal, Common, Permission_keys } from '../../../config/react'
import DataTable from 'react-data-table-component';
import Spinner from '../../Spinner';
import Moment from 'moment';
function SystemService(props) {
    const [isForceUpdate, setForceUpdate] = useState(false);
    const [ServiceLogs, setServiceLogs] = useState([])
    const history = useHistory();
    const [IsAuth, setIsAuth] = useState(false)
    const [labNivodaServiceStatus, setlabNivodaServiceStatus] = useState(false)
    const [NivodaLabSoldStatus, setNivodaLabSoldStatus] = useState(false)
    const columns = [
        {
            name: 'Service Name',
            selector: row => row.service_name,
        },
        {
            name: 'Start Time',
            selector: row => Moment(row.start_time).format('YYYY-MM-DD h:m:s'),
        },
        {
            name: 'End Time',
            selector: row => row.end_time != null ? Moment(row.end_time).format('YYYY-MM-DD h:m:s') : '',
        },
        {
            name: 'Download CSV',
            selector: (row) =>
                <div className="action-btn">
                    {row.download_csv == 1 ? 'Completed' : (row.download_csv == 0 ? 'In Progess' : (row.download_csv == 2 ? <><span data-toggle="tooltip" data-html="true" title={row.error_message}>Failed<svg
                        xmlns="http://www.w3.org/2000/svg" width="17" height="20" viewBox="0 0 24 24" fill="none" stroke="#000000d4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" className="feather feather-info ml-1 cursor"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="16" x2="12" y2="12"></line><line x1="12" y1="8" x2="12.01" y2="8"></line></svg></span></> : 'Not Started'))}
                </div>
        },
        {
            name: 'Insert in Temp Table',
            selector: row => row.insert_in_temp_table == 1 ? 'Completed' : (row.insert_in_temp_table == 0 ? 'In Progess' : (row.insert_in_temp_table == 2 ? 'Failed' : 'Not Started'))
        },
        {
            name: 'Insert in Database',
            selector: row => row.insert_in_database == 1 ? 'Completed' : (row.insert_in_database == 0 ? 'In Progess' : (row.insert_in_database == 2 ? 'Failed' : 'Not Started'))
        },
        {
            name: 'Insert in MongoDB',
            selector: row => row.insert_in_mongodb == 1 ? 'Completed' : (row.insert_in_mongodb == 0 ? 'In Progess' : (row.insert_in_mongodb == 2 ? 'Failed' : 'Not Started'))
        }
    ];

    const DefaultFilters = {
        page: 1,
        search: "",
        page_size: 10
    }
    const [CustomFilters, setCustomFilters] = useState(DefaultFilters)
    const [totalRows, settotalRows] = useState()
    const [NivodaServiceStatus, setNivodaServiceStatus] = useState(false)
    const [VendorServiceStatus, setVendorServiceStatus] = useState(false)
    const [NivodaSoldStatus, setNivodaSoldStatus] = useState(false)
    const [VendorSoldStatus, setVendorSoldStatus] = useState(false)
    const [diamondCount, setdiamondCount] = useState([])

    useEffect(() => {
        if (!Common.validatePermission(Permission_keys.System)) {
            history.push("/");
        }
        setIsAuth(true);
        setForceUpdate(!isForceUpdate)
        GetSettingOptions()
    }, [CustomFilters])

    useEffect(() => {
        GetSoldStatus();
        document.title = Title.SystemServices
    }, [])



    const GetSettingOptions = () => {
        var passData = {
            "page": CustomFilters.page,
            "search": CustomFilters.search,
            "page_size": CustomFilters.page_size
        }
        HttpService.postApi(API.GetServiceLogs, passData)
            .then(response => {
                if (response.data.status == true) {
                    var data = response.data.result[0];
                    if (data.length > 0) {
                        setServiceLogs(data);
                        setForceUpdate(!isForceUpdate);
                        settotalRows(data[0].total_count);
                        if (!NivodaServiceStatus) {
                            if (data[0].service_name == "Nivoda Service" && data[0].end_time == null && data[0].error_message == null)
                                setNivodaServiceStatus(true);
                            // data.forEach(element => {
                            //     if (element.service_name == "Nivoda Service" && element.end_time == null) {
                            //         setNivodaServiceStatus(true);
                            //     }
                            // });
                        }
                        if (!VendorServiceStatus) {
                            data.forEach(element => {
                                if (element.service_name == "Vendor Service" && element.end_time == null) {
                                    setVendorServiceStatus(true);
                                }
                            });
                        }
                    }
                }
            });
    }
    const handlePageChange = (e) => {
        setCustomFilters({ ...CustomFilters, ['page']: e });
    }

    const handlePerRowsChange = (e) => {
        setCustomFilters({ ...CustomFilters, ['page_size']: e });
    }

    const StartService = (service) => {
        var passData = {
            "service": service
        }
        HttpService.postApi(API.StartService, passData)
            .then(response => {
                if (response.data.status) {
                    if (service == "Nivoda") {
                        setNivodaServiceStatus(true);
                    }
                    else if (service == "lab") {
                        setlabNivodaServiceStatus(true);
                    }
                    else {
                        setVendorServiceStatus(true);
                    }
                    GetSettingOptions();
                }
            });
    }

    const StopService = (service) => {
        var passData = {
            "service": service
        }
        HttpService.postApi(API.StopService, passData)
            .then(response => {
                if (response.data.status) {
                    if (service == "Nivoda") {
                        setNivodaServiceStatus(false);
                    }
                    else if (service == "lab") {
                        setlabNivodaServiceStatus(false);
                    }
                    else {
                        setVendorServiceStatus(false);
                    }
                    GetSettingOptions();
                }
            });
    }

    const GetSoldStatus = () => {
        var passData = {}
        HttpService.postApi(API.GetImportCounts, passData)
            .then(response => {
                if (response.data.result[0][0].vendor_count == "0") {
                    setVendorSoldStatus(true);
                }
                if (response.data.result[0][0].nivoda_count == "0") {
                    setNivodaSoldStatus(true);
                }
                if (response.data.result[0][0].nivoda_lab_count == "0") {
                    setNivodaLabSoldStatus(true);
                }
                let arr = [];
                arr.push(response.data.result[0][0].nivoda_total_diamond)
                arr.push(response.data.result[0][0].pld_total_diamond)
                arr.push(response.data.result[0][0].nivoda_total_earth_diamond)
                arr.push(response.data.result[0][0].nivoda_total_lab_diamond)
                setdiamondCount(arr)
                setForceUpdate(!isForceUpdate);
            });
    }

    const SoldDiamonds = (service, diamodType) => {
        var passData = {
            "service": service,
            "diamodType": diamodType
        }
        HttpService.postApi(API.SetSoldDiamond, passData)
            .then(response => {
                toast.success(Messages.Diamond_Sold)
            });
    }

    const subHeaderComponentMemo = React.useMemo(() => {
        return (
            <input type="text" title="Search For Title" className='form-control col-md-3' placeholder='Search' onChange={e => setCustomFilters({ ...CustomFilters, ['search']: e.target.value })} />
        );
    });
    const DeleteSoldDiamonds = (type) => {
        var passData = {
            "diamodType": type
        }
        HttpService.postApi(API.DeleteSoldDiamonds, passData)
            .then(response => {
                toast.success(Messages.Diamond_Sold)
            });
    }
    const DeleteDiamondFromFile = () => {
        HttpService.postApi(API.DeleteDiamondFromFile, null)
            .then(response => {
                toast.success(Messages.Diamond_Sold)
            });
    }
    const ImportDiamond = () => {
        HttpService.postApi(API.GetNivodaDiamondFeeds, null)
            .then(response => {

            });
    }

    return !IsAuth ? <Spinner /> : (
        <div className="content-wrapper pb-0">
            <div className="page-header">
                <h3 className="page-title"> {Title.SystemService} </h3>
            </div>
            <div className="row">
                <div className="col-lg-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className='table'>
                                    <thead>
                                        <tr>
                                            <th>Service Name</th>
                                            <th>Total Diamonds</th>
                                            <th>Current Status</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Nivoda Diamond Import</td>
                                            <td>{diamondCount[0]}</td>

                                            <td>{NivodaServiceStatus ? "Running" : "Not Running"}</td>
                                            <td>
                                                {/* <button type="button" onClick={e => ImportDiamond("Nivoda")} className='btn btn-sm btn-primary'>Import Diamond</button> */}
                                                <button type="button" onClick={e => StartService("Nivoda")} className={NivodaServiceStatus ? 'btn btn-sm btn-primary mr-2 disabled' : 'btn btn-sm btn-primary mr-2'} disabled={NivodaServiceStatus}>Import Diamonds</button>
                                                <button type="button" onClick={e => StartService("sync_mongodb")} className='btn btn-sm btn-primary'>Sync MongoDB</button>
                                                {/* <button type="button" onClick={e => StopService("Nivoda")} className={NivodaServiceStatus ? 'btn btn-sm btn-primary' : 'btn btn-sm btn-primary d-none'}>Stop Service</button> */}
                                                {/* <button type="button" onClick={e => SoldDiamonds("Nivoda", "earth")} className={!NivodaServiceStatus && !NivodaSoldStatus ? 'btn btn-sm btn-primary d-none' : 'btn btn-sm btn-primary ml-2'}>Sold Diamonds</button> */}
                                                {/* <button type="button" onClick={e => DeleteSoldDiamonds("earth")} className='ml-2 btn btn-sm btn-primary'>Delete Sold Diamonds</button> */}
                                                {/* <button type="button" onClick={e => DeleteDiamondFromFile("earth")} className='ml-2 btn btn-sm btn-primary'>Delete from file</button> */}
                                            </td>
                                        </tr>
                                        {/* <tr>
                                            <td>Lab-Grown Nivoda Diamond Import</td>
                                            {console.log(diamondCount)}
                                            <td>{diamondCount[3]}</td>

                                            <td>{labNivodaServiceStatus ? "Running" : "Not Running"}</td>
                                            <td>
                                                <button type="button" onClick={e => StartService("lab")} className={labNivodaServiceStatus ? 'btn btn-sm btn-primary d-none' : 'btn btn-sm btn-primary'}>Start Service</button>
                                                <button type="button" onClick={e => StopService("lab")} className={labNivodaServiceStatus ? 'btn btn-sm btn-primary' : 'btn btn-sm btn-primary d-none'}>Stop Service</button>
                                                <button type="button" onClick={e => SoldDiamonds("Nivoda", "lab")} className={!labNivodaServiceStatus && !NivodaLabSoldStatus ? 'btn btn-sm btn-primary d-none' : 'btn btn-sm btn-primary ml-2'}>Sold Diamonds</button>
                                                <button type="button" onClick={e => DeleteSoldDiamonds("lab")} className='ml-2 btn btn-sm btn-primary'>Delete Sold Diamonds</button>
                                                <button type="button" onClick={e => DeleteDiamondFromFile("lab")} className='ml-2 btn btn-sm btn-primary'>Delete from file</button>
                                            </td>
                                        </tr> */}
                                        <tr>
                                            <td>PLD Diamond Import</td>
                                            <td>{diamondCount[1]}</td>
                                            <td>{VendorServiceStatus ? "Running" : "Not Running"}</td>
                                            <td>
                                                <button type="button" onClick={e => StartService("Vendor")} className={VendorServiceStatus ? 'btn btn-sm btn-primary d-none' : 'btn btn-sm btn-primary'}>Start Service</button>
                                                <button type="button" onClick={e => StopService("Vendor")} className={VendorServiceStatus ? 'btn btn-sm btn-primary' : 'btn btn-sm btn-primary d-none'}>Stop Service</button>
                                                <button type="button" onClick={e => SoldDiamonds("Vendor")} className={!VendorServiceStatus && !VendorSoldStatus ? 'btn btn-sm btn-primary d-none' : 'btn btn-sm btn-primary ml-2'}>Sold Diamonds</button>

                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="page-header">
                <h3 className="page-title"> {Title.ServiceLogs} </h3>
            </div>
            <div className="row">
                <div className="col-lg-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="table-responsive">
                                <DataTable
                                    columns={columns}
                                    data={ServiceLogs}
                                    // progressPending={loading}
                                    pagination
                                    paginationServer
                                    paginationTotalRows={totalRows}
                                    onChangeRowsPerPage={handlePerRowsChange}
                                    onChangePage={handlePageChange}
                                    subHeader
                                // subHeaderComponent={subHeaderComponentMemo}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SystemService;