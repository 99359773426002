import React, { useEffect, useState, useRef } from "react";
import { useParams, Link, useHistory } from "react-router-dom";
import {
  HttpService,
  API,
  Title,
  SimpleReactValidator,
  ReactStars,
  Messages,
  toast,
} from "../../../config/react";
function EditReview() {
  const urlParams = useParams();
  const history = useHistory();
  const validator = useRef(new SimpleReactValidator());
  const [Review, setReview] = useState([]);
  const [isForceUpdate, setForceUpdate] = useState(false);
  let submitbtnRef = useRef();
  const [Inputs, setInputs] = useState({
    id: 0,
    product_id: 0,
    sku: "",
    title: "",
    review: "",
    rating: 0,
    name: "",
    status: "",
  });
  useEffect(() => {
    document.title = Title.EditReview;
    GetReview();
  }, [urlParams.id]);
  const GetReview = () => {
    var param = {
      id: urlParams.id,
    };
    HttpService.postApi(API.GetReview, param).then((response) => {
      if (response.data.result) {
        setReview(response.data.result[0]);
        setInputs(response.data.result[0]);
        setForceUpdate(!isForceUpdate);
      }
    });
  };
  const changeRating = (rating) => {
    Inputs["rating"] = rating;
    setForceUpdate(!isForceUpdate);
  };
  const handleInputChanges =
    (prop) => (event: ChangeEvent<HTMLInputElement>) => {
      setInputs({ ...Inputs, [prop]: event.target.value });
      setForceUpdate(!isForceUpdate);
    };
  const SubmitForm = (e) => {
    if (!validator.current.allValid()) {
      validator.current.showMessages();
      setForceUpdate(!isForceUpdate);
      return;
    }

    if (submitbtnRef.current)
      submitbtnRef.current.setAttribute("disabled", "disabled");

    var param = {
      id: Inputs.id,
      title: Inputs.title,
      review: Inputs.review,
      rating: Inputs.rating,
      status: Inputs.status,
      product_id: Inputs.product_id,
      sku: Inputs.sku,
    };
    HttpService.postApi(API.UpdateReview, param).then((response) => {
      if (response.data.message) {
        toast.warning(response.data.message);
        setForceUpdate(!isForceUpdate);
        submitbtnRef.current.removeAttribute("disabled");
        return;
      }
      toast.success(Messages.Record_Updated);
      setForceUpdate(!isForceUpdate);
      history.push("/marketing/reviews");
    });
  };
  return (
    <div className="content-wrapper pb-0">
      <div className="page-header">
        <h3 className="page-title"> {Title.EditReview} </h3>
        <div className="btn-group">
          <button
            type="button"
            className="btn btn-dark btn-fw rounded mr-2"
            onClick={history.goBack}
          >
            {" "}
            Back{" "}
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <form className="forms-sample">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Product</label>
                      <Link
                        to={"/products/edit/" + Review.product_id}
                        className=""
                        target="_blank"
                      >
                        <p>{Review.product_name}</p>
                      </Link>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Author</label>
                      {Review.customer_id != 0 ? (
                        <Link
                          to={"/customers/edit/" + Review.customer_id}
                          className=""
                          target="_blank"
                        >
                          <p>{Review.customer_name}</p>
                        </Link>
                      ) : (
                        <p>{Review.customer_name}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Rating</label>
                      <div className="form-group rating-label my-3">
                        {[...Array(11)].map(
                          (x, i) =>
                            i != 0 && (
                              <label
                                key={i}
                                htmlFor={i}
                                className={
                                  i == Inputs.rating
                                    ? "me-3 cursor active"
                                    : "me-3 cursor"
                                }
                                defaultValue={Inputs.rating}
                                // onClick={() => changeRating(i)}
                              >
                                {i}
                              </label>
                            )
                        )}
                      </div>
                      {/* <ReactStars
                                                key={Inputs.rating}
                                                value={Inputs.rating}
                                                count={5}
                                                onChange={changeRating}
                                                size={50}
                                                activeColor="#ffd700"
                                                isHalf={true}
                                            /> */}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Status</label>
                      <select
                        className="form-control"
                        name="status"
                        onChange={handleInputChanges("status")}
                        value={Inputs.status}
                      >
                        <option value="pending">Pending</option>
                        <option value="approved">Approved</option>
                        <option value="rejected">Rejected</option>
                      </select>
                      {validator.current.message(
                        "Status",
                        Inputs.status,
                        "required",
                        { className: "error" }
                      )}
                    </div>
                  </div>
                  {/* <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Title</label>
                                            <input type="text" className="form-control" name="title"
                                                onChange={handleInputChanges("title")}
                                                value={Inputs.title} />
                                            {validator.current.message('Title', Inputs.title, 'max:255', { className: 'error' })}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Review</label>
                                            <textarea name="review" className='form-control' id="txtReview" rows={3} onChange={handleInputChanges("review")} value={Inputs.review}></textarea>
                                            {validator.current.message('Review', Inputs.review, 'max:1000', { className: 'error' })}
                                        </div>
                                    </div> */}
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <button
                      type="button"
                      ref={submitbtnRef}
                      onClick={SubmitForm}
                      className="btn btn-primary mr-2"
                    >
                      {" "}
                      Submit{" "}
                    </button>
                    <Link to="/marketing/reviews" className="btn btn-light">
                      Cancel
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditReview;
