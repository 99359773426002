import React, { useEffect, useState, useContext } from 'react';
import { HttpService, API, Title, Common, Permission_keys } from '../../config/react'
import { Link, useHistory } from 'react-router-dom';
import LineChartComponent from '../dashboard/LineChartComponent';
import Spinner from '../Spinner';

const LinkStatisticsByDay = () => {
    const history = useHistory();
    const [IsAuth, setIsAuth] = useState(false)
    const [ChartData, setChartData] = useState([])
    const [LinkData, setLinkData] = useState([])

    const [isForceUpdate, setForceUpdate] = useState(false);

    useEffect(() => {
        if (!Common.validatePermission(Permission_keys.Report)) {
            history.push("/");
        }
        setIsAuth(true);
        setForceUpdate(!isForceUpdate)
        GetLinkStatistics()
        GetUserConsentCounts()
        document.title = Title.LinkStatistics
    }, [])


    const GetLinkStatistics = () => {
        var param = {
            "search_date": new Date()
            // "search_date": '2023-01-17 12:44:04'
        }
        HttpService.postApi(API.GetQuickLinksByDay, param)
            .then(response => {
                if (response.data.status && response.data.result[0].length > 0) {
                    setLinkData(response.data.result[0]);
                }
            });
    }

    const GetUserConsentCounts = () => {
        HttpService.getApi(API.GetUserConsentByDay)
            .then(response => {
                console.log(response);
                if (response.data.status && response.data.result[0].length > 0) {
                    let data = { labels: [], values: [] };

                    response.data.result[0].forEach(element => {
                        data.values.push(element.count);
                        // data.labels.push(element.weekday);
                        data.labels.push(new Date(element.dt).toLocaleDateString());
                    });

                    setChartData(data);
                }
            });
    }
    const onChartClick = (index) => {
        let date = ChartData.labels[index] != undefined ? ChartData.labels[index] : '';
        history.push({
            pathname: '/report/linkstatistics/overall',
            state: { date: Common.GetDateTimeFormat(date, 'YYYY-MM-DD') }
        });
    }

    return !IsAuth ? <Spinner /> : (
        <div className="content-wrapper pb-0">
            <div className="page-header">
                <h3 className="page-title"> {Title.UsersPerDay} </h3>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="card grid-margin">
                        <div className="card-header d-none justify-content-between align-items-center">
                            <h5 className="card-title mb-0">Max Search Products</h5>
                        </div>
                        <div className="card-body text-center">
                            <div className="chart-wrapper user-chart-wrapper">
                                {
                                    (ChartData != undefined) ?
                                        <LineChartComponent data={ChartData} onChartClick={onChartClick} />
                                        : ""
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-md-6 col-lg-4 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className='w-100'>
                                <div class="card-header p-0 mb-3">
                                    <h3 className="title font-20">{Title.ClicksonLink}
                                        <small className="float-right font-16"><Link to={"/report/linkstatistics/overall"}>View other Links</Link></small>
                                    </h3>
                                </div>
                                <table className="table table-striped table-borderless">
                                    <tbody>
                                        <tr>
                                            <th className="w-75">Headline</th>
                                            <th>Clicks</th>
                                        </tr>
                                        {
                                            LinkData.length > 0 ?
                                                LinkData.map((item, index) => (
                                                    <tr>
                                                        <td className="w-75"><Link to={{ pathname: item.URL }} target="_blank">{item.label}</Link></td>
                                                        <td>{item.clicks}</td>
                                                    </tr>
                                                ))
                                                : <tr><td className="text-center" colSpan="2">No data available</td></tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};


export default LinkStatisticsByDay;