import React, { useState, useRef, useEffect, useContext, ChangeEvent } from 'react';
import { useHistory, useParams, Link } from "react-router-dom";
import UserContext from '../../../config/UserContext'
import { HttpService, API, SimpleReactValidator, Title, toast, Messages, Common, Permission_keys } from '../../../config/react'
import Spinner from '../../Spinner';

const AddDiamondMarkup = () => {
    const urlParams = useParams();
    const history = useHistory();
    const [IsAuth, setIsAuth] = useState(false)
    const validator = useRef(new SimpleReactValidator())
    const user = useContext(UserContext);
    const [ShapeCategory, setShapeCategory] = useState([]);
    const [isForceUpdate, setForceUpdate] = useState(false);
    const [Inputs, setInputs] = useState({
        ID: 0,
        from_carat: '',
        to_carat: '',
        markup_type: "percentage",
        markup: 0,
        fix_cost: 0,
        is_lab_diamond: false,
        shape_category_id: ''
    });

    useEffect(() => {
        if (!Common.validatePermission(Permission_keys.System)) {
            history.push("/");
        }
        setIsAuth(true);
        setForceUpdate(!isForceUpdate)
        document.title = Title.AddDiamondMarkup
        GetShapeCategory();
        if (urlParams.id != null) {
            document.title = Title.EditDiamondMarkup
            GetDiamondMarkupDetail()
        }
    }, [urlParams.id])

    const GetDiamondMarkupDetail = () => {
        var param = {
            "id": urlParams.id
        }
        HttpService.postApi(API.GetDiamondMarkup, param)
            .then(response => {
                if (response.data.result) {
                    setInputs(response.data.result[0][0])
                    setForceUpdate(!isForceUpdate)
                }
            });
    }
    const GetShapeCategory = () => {
        HttpService.getApi(API.GetShapeCategory)
            .then(response => {
                if (response.data.result) {
                    setShapeCategory(response.data.result)
                }
            });
    }

    const handleInputChanges =
        (prop) =>
            (event: ChangeEvent<HTMLInputElement>) => {
                if (prop == "status")
                    setInputs({ ...Inputs, [prop]: event.target.checked });
                else if (prop == "is_lab_diamond") {
                    if (event.target.value == "0")
                        setInputs({ ...Inputs, [prop]: false });
                    else
                        setInputs({ ...Inputs, [prop]: true });

                }
                else
                    setInputs({ ...Inputs, [prop]: event.target.value });
                setForceUpdate(!isForceUpdate)
            };

    const SubmitForm = (e) => {
        if (!validator.current.allValid()) {
            validator.current.showMessages();
            setForceUpdate(!isForceUpdate)
            return
        }

        var param = {
            "id": Inputs.ID,
            "from_carat": parseFloat(Inputs.from_carat).toFixed(3),
            "to_carat": parseFloat(Inputs.to_carat).toFixed(3),
            'markup_type': Inputs.markup_type,
            'markup': parseInt(Inputs.markup),
            'fix_cost': parseInt(Inputs.fix_cost),
            'is_lab_diamond': Inputs.is_lab_diamond,
            'shape_category_id': Inputs.shape_category_id,
            'created_by': user.id
        }
        HttpService.postApi(API.SetDiamondMarkup, param)
            .then(response => {
                if (response.data.message) {
                    toast.warning(response.data.message)
                    setForceUpdate(!isForceUpdate);
                    return
                }
                if (urlParams.id == null)
                    toast.success(Messages.Record_Added)
                else
                    toast.success(Messages.Record_Updated)

                setForceUpdate(!isForceUpdate);
                history.push('/system/diamondmarkup')
            });

    }
    return !IsAuth ? <Spinner /> : (
        <div className="content-wrapper pb-0">
            <div className="page-header">
                <h3 className="page-title mb-0"> {(urlParams.id == null) ? Title.AddDiamondMarkup : Title.EditDiamondMarkup} </h3>
                <div className="btn-group">
                    <button type="button" className="btn btn-dark btn-fw rounded mr-2" onClick={history.goBack}> Back </button>
                </div>
            </div>
            <div className="row">
                <div className="col-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <form className="forms-sample">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Carat From</label>
                                            <input type="number" className="form-control" name="from_carat" min="0"
                                                onChange={handleInputChanges("from_carat")}
                                                onKeyUp={handleInputChanges("from_carat")}
                                                value={Inputs.from_carat}
                                                onBlur={() => validator.current.showMessageFor("Carat From")}
                                                placeholder="Carat From" />
                                            {validator.current.message('Carat From', Inputs.from_carat, 'required', { className: 'error' })}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Carat To</label>
                                            <input type="number" className="form-control" name="to_carat" min="0"
                                                onChange={handleInputChanges("to_carat")}
                                                onKeyUp={handleInputChanges("to_carat")}
                                                value={Inputs.to_carat}
                                                onBlur={() => validator.current.showMessageFor("Carat To")}
                                                placeholder="Carat To" />
                                            {validator.current.message('Carat To', Inputs.to_carat, 'required', { className: 'error' })}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Markup Type</label>
                                            <select className='form-control' onChange={handleInputChanges("markup_type")} name="markup_type" value={Inputs.markup_type}>
                                                <option value="percentage">%</option>
                                                <option value="amount">Amount</option>
                                            </select>
                                            {validator.current.message('Markup Type', Inputs.markup_type, 'required', { className: 'error' })}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Markup {Inputs.markup_type == "percentage" ? "(%)" : ""}</label>
                                            <input type="number" className="form-control" name="markup" min="0"
                                                onChange={handleInputChanges("markup")}
                                                onKeyUp={handleInputChanges("markup")}
                                                value={Inputs.markup}
                                                onBlur={() => validator.current.showMessageFor("Markup")}
                                                placeholder="Markup" />
                                            {validator.current.message('Markup', Inputs.markup, 'required', { className: 'error' })}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Cost</label>
                                            <input type="number" className="form-control" name="fix_cost" min="0"
                                                onChange={handleInputChanges("fix_cost")}
                                                onKeyUp={handleInputChanges("fix_cost")}
                                                value={Inputs.fix_cost}
                                                onBlur={() => validator.current.showMessageFor("Cost")}
                                                placeholder="Cost" />
                                            {validator.current.message('Cost', Inputs.fix_cost, 'required', { className: 'error' })}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Type Of Diamond</label>
                                            <select className="form-control" name="is_lab_diamond"
                                                onChange={handleInputChanges("is_lab_diamond")} value={Inputs.is_lab_diamond == 1 ? "1" : "0"} >
                                                <option value="0">Earth Mined</option>
                                                <option value="1">Lab Grown</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Shape</label>
                                            <select className="form-control" name="shape_category_id"
                                                onChange={handleInputChanges("shape_category_id")} value={Inputs.shape_category_id} >
                                                <option value=''>Select</option>
                                                <option value='0'>ALL</option>
                                                {
                                                    ShapeCategory.map(item => (
                                                        <option key={item.id} value={item.id}>{item.name}</option>
                                                    ))
                                                }
                                            </select>
                                            {validator.current.message('Shape', Inputs.shape_category_id, 'required', { className: 'error' })}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <button type="button" onClick={SubmitForm} className="btn btn-primary mr-2"> Submit </button>
                                        <Link to="/system/diamondmarkup" className="btn btn-light">Cancel</Link>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddDiamondMarkup;