import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import UserContext from "../../config/UserContext";
import {
  HttpService,
  API,
  swal,
  Title,
  toast,
  Messages,
  DateFormat,
  Common,
  Permission_keys,
  ExportToExcel,
} from "../../config/react";
import DataTable from "react-data-table-component";
import { useHistory } from "react-router-dom";
import Spinner from "../Spinner";
const SearchTerm = () => {
  const history = useHistory();
  const [IsAuth, setIsAuth] = useState(false);
  const [SearchTerms, setSearchTerms] = useState([]);
  const user = useContext(UserContext);
  const [isForceUpdate, setForceUpdate] = useState(false);
  const DefaultFilters = {
    page: 1,
    search: "",
    page_size: 20,
  };
  const [CustomFilters, setCustomFilters] = useState(DefaultFilters);
  const [totalRows, settotalRows] = useState();
  const handlePageChange = (e) => {
    setCustomFilters({ ...CustomFilters, ["page"]: e });
  };

  const handlePerRowsChange = (e) => {
    setCustomFilters({ ...CustomFilters, ["page_size"]: e });
  };
  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <input
        type="text"
        title="Search For keyword"
        className="form-control col-md-3"
        placeholder="Search"
        onChange={(e) =>
          setCustomFilters({
            ...CustomFilters,
            ["page"]: 1,
            ["search"]: e.target.value,
          })
        }
      />
    );
  });
  const columns = [
    {
      name: "Keyword",
      selector: (row) => row.keyword,
    },
    {
      name: "Number of Uses",
      selector: (row) => row.no_of_users,
    },
    {
      name: "Number of Results",
      selector: (row) => row.no_of_result,
    },
    {
      name: "Last Search Date",
      selector: (row) => (
        <DateFormat value={row.updated_at ?? row.created_at}></DateFormat>
      ),
    },
    // ,
    // {
    //     name: 'Action',
    //     selector: (row) =>
    //         <div className="action-btn">
    //             <Link onClick={e => DeleteKeyword(e, row.id)} to="" className="text-dark" title="Delete" ><i className="fa fa-trash-o" aria-hidden="true"></i> </Link>
    //         </div>
    //     ,
    // },
  ];
  useEffect(() => {
    if (!Common.validatePermission(Permission_keys.Report)) {
      history.push("/");
    }
    setIsAuth(true);
    setForceUpdate(!isForceUpdate);
    GetKeywordList();
    document.title = Title.SearchTerms;
  }, [CustomFilters]);

  const GetKeywordList = () => {
    var param = {
      page: CustomFilters.page,
      search: CustomFilters.search,
      page_size: CustomFilters.page_size,
    };
    HttpService.postApi(API.GetSearchTerms, param).then((response) => {
      if (response.data.status == true && response.data.result != "") {
        var data = response.data.result[0];
        setSearchTerms(data);
        setForceUpdate(!isForceUpdate);
        settotalRows(response.data.result[0][0].count);
      } else {
        setSearchTerms([]);
        settotalRows(0);
      }
    });
  };
  const DeleteKeyword = async (e, id) => {
    e.preventDefault();
    swal(Messages.Swal_Delete_Prop).then(function (isConfirm) {
      if (isConfirm) {
        var param = {
          id: id,
          IsDeleted: true,
          deleted_by: user.id,
        };
        HttpService.postApi(API.DeleteBlog, param).then((response) => {
          toast.success(Messages.Record_Deleted);
          GetKeywordList();
          setForceUpdate(!isForceUpdate);
        });
      }
    });
  };
  const handleExportExcel = () => {
    HttpService.getApi(API.GetSearchTermsExcel).then((response) => {
      if (response.data.result) {
        ExportToExcel(response.data.result[0], "Search Terms");
      }
    });
  };
  return !IsAuth ? (
    <Spinner />
  ) : (
    <div className="content-wrapper pb-0">
      <div className="page-header">
        <h3 className="page-title"> {Title.SearchTerms} </h3>
        {/* <div className="btn-group">
                    <Link to="/blog/Addblog" className="btn btn-dark btn-fw rounded mr-2"> {Title.AddBlog} </Link>
                </div> */}
      </div>
      <div className="row">
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <button
                className="btn btn-primary float-right m-1"
                onClick={handleExportExcel}
              >
                Export Excel
              </button>
              <div className="table-responsive">
                <DataTable
                  columns={columns}
                  data={SearchTerms}
                  pagination
                  paginationServer
                  paginationTotalRows={totalRows}
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                  subHeader
                  subHeaderComponent={subHeaderComponentMemo}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SearchTerm;
