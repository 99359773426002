import React, { useEffect, useState, useContext, useRef } from 'react';
import { useParams, Link, useHistory } from "react-router-dom";
import UserContext from '../../config/UserContext'
import { HttpService, API, swal, Title, toast, MaterialTable, SimpleReactValidator, Messages, DateFormat, Common, Permission_keys } from '../../config/react'
import Moment from 'moment';
import Spinner from '../Spinner';
const OrdersDetails = () => {
    const urlParams = useParams();
    const history = useHistory();
    const validator = useRef(new SimpleReactValidator())
    const [IsAuth, setIsAuth] = useState(false)
    const [isForceUpdate, setForceUpdate] = useState(false)
    const [OrderDetailsList, setOrderDetailsList] = useState([])
    const [OrderStatusList, setOrderStatusList] = useState([])
    const [BillingAddressList, setBillingAddressList] = useState([])
    const [ShippingAddressList, setShippingAddressList] = useState([])
    const [ProductDetailsList, setProductDetailsList] = useState([])
    const user = useContext(UserContext);
    const textInput = React.useRef();
    const [checked, setChecked] = useState(false);
    const [selectedValue, setSelectedValue] = useState('');
    const [selectedStatus, setSelectedStatus] = useState('');
    //  const [myValue, setMyValue] = useState('');
    const [Inputs, setInputs] = useState({
        id: 0,
        refund_type: "full",
        refund_amount: ""
    });

    useEffect(() => {
        if (!Common.validatePermission(Permission_keys.Products)) {
            history.push("/");
        }
        setIsAuth(true);
        setForceUpdate(!isForceUpdate)
        document.title = Title.OrdersDetails
        GetOrderDetailsList();
    }, [urlParams.id])

    const GetOrderDetailsList = () => {
        var param = {
            "OrderNumber": urlParams.id
        }
        HttpService.postApi(API.GetOrderDetailsList, param)
            .then(response => {
                var data = response.data.result[0][0];
                let addresses = Common.GetJSON(data.addresses)
                var productlist = data.products;
                productlist = Common.GetJSON(productlist);
                productlist.sort((a, b) => a.id - b.id)
                console.log(productlist);
                productlist?.map((item) => {
                    item.product_options = JSON.parse(item.product_options)
                });
                setOrderDetailsList(data)
                // console.log(data);
                setProductDetailsList(productlist);
                if (addresses != null) {
                    setBillingAddressList(addresses[0]);
                    setShippingAddressList(addresses[1]);
                }
                // Inputs['status'] = data.order_status
                setSelectedValue(data.order_status_id);
                if (OrderDetailsList.remaining_refund_amount != null) {
                    setInputs({ ...Inputs, ['refund_amount']: OrderDetailsList.remaining_refund_amount })
                }
                else {
                    setInputs({ ...Inputs, ['refund_amount']: OrderDetailsList.final_price })
                }
                setForceUpdate(!isForceUpdate)
                GetOrderStatusList(data.order_status_id);
            });
    }

    const GetOrderStatusList = (statusId) => {
        HttpService.getApi(API.GetOrderStatusList)
            .then(response => {
                var data = response.data.result;
                var status = data.filter(x => x.id == statusId)[0];
                for (var i = 0; i < data.length; i++) {
                    if (data[i].id == status.id || (data[i].status == "CANCELLED" && status.status != "REFUNDED")) {
                        data[i].is_disable = false;
                    }
                    else {
                        data[i].is_disable = true;
                    }
                }
                if (status.status == "PENDING") {
                    data.filter(x => x.status == "CONFIRMED")[0].is_disable = false;
                }
                else if (status.status == "CONFIRMED") {
                    data.filter(x => x.status == "INPROCESS")[0].is_disable = false;
                }
                else if (status.status == "INPROCESS") {
                    data.filter(x => x.status == "ON HOLD")[0].is_disable = false;
                }
                else if (status.status == "ON HOLD") {
                    data.filter(x => x.status == "SHIPPING")[0].is_disable = false;
                }
                else if (status.status == "SHIPPING") {
                    data.filter(x => x.status == "COMPLETED")[0].is_disable = false;
                }
                else if (status.status == "CANCELLED") {
                    data.filter(x => x.status == "REFUNDED")[0].is_disable = false;
                }
                setOrderStatusList(data)
                setSelectedStatus(status.status);
            });
    }

    const handleInputChanges = (prop) => (e) => {
        if (prop == "status") {
            var value = e.target.value;
            var status = OrderStatusList.filter(x => x.id == value)[0];
            setSelectedStatus(status.status);
            setSelectedValue(e.target.value);
        }
        else {
            if (prop == "refund_type" && e.target.value == "partial") {
                setInputs({ ...Inputs, ['refund_amount']: 0 })
            }
            else if (prop == "refund_type") {
                if (OrderDetailsList.remaining_refund_amount != null) {
                    setInputs({ ...Inputs, ['refund_amount']: OrderDetailsList.remaining_refund_amount })
                }
                else {
                    setInputs({ ...Inputs, ['refund_amount']: OrderDetailsList.final_price })
                }
            }
            setInputs({ ...Inputs, [prop]: e.target.value })
            setForceUpdate(!isForceUpdate)
        }
    };
    const UpdateOrderStatus = async (e) => {
        e.preventDefault()
        if (!validator.current.allValid()) {
            validator.current.showMessages();
            setForceUpdate(!isForceUpdate)
            return
        }
        var OrderData = {
            "_order_id": OrderDetailsList.id,
            "status": selectedValue,
            "refund_type": Inputs.refund_type,
            "refund_amount": Inputs.refund_amount,
            "is_refund": selectedStatus == "REFUNDED" ? 1 : 0,
            "is_customer_notified": checked,
            "comment": textInput.current.value
        }
        HttpService.postApi(API.UpdateOrderStatus, OrderData)
            .then(response => {
                if (response.data.status == 1) {
                    toast.success(response.data.result);
                    textInput.current.value = "";
                    validator.current.hideMessages();
                    setChecked(false)
                    GetOrderDetailsList();
                }
            });
    }


    return !IsAuth ? <Spinner /> : (
        // <div>
        //     <div className="page-header">
        //         <h3 className="page-title"> {Title.OrdersDetails} </h3>

        //     </div>
        //     <div className="row">
        //         <div className="col-lg-12 grid-margin stretch-card">
        //             <div className="card">
        //                 <div className="card-body">
        //                     <button className="btn btn-primary btn-fw rounded filter-btn mr-2" onClick={e => UpdateOrderStatus(e)}>status</button>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </div>
        <div className="content-wrapper pb-0">
            <div className="page-header">
                <h3 className="page-title">{Title.OrdersDetails} </h3>
                <div className="btn-group">
                    <button type="button" className="btn btn-dark btn-fw rounded mr-2" onClick={history.goBack}> Back </button>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="row justify-content-between">
                                <div className="col-md-12">
                                    <div className="card-header p-0 pb-2 mb-3"><h3 className="title font-20">Order & Account Information</h3></div>
                                </div>

                                <div className="col-md-5">
                                    <div className="address-info">
                                        <h4>Order {OrderDetailsList.order_number}</h4>
                                        <table className="table table-striped table-borderless">
                                            <tbody>
                                                <tr>
                                                    <td>Order Date</td>
                                                    {/* <td className="text-right">Jan 31, 2022, 7:07:01 PM</td>  */}
                                                    <td className="text-right">{Moment(OrderDetailsList.created_at).format("MMM DD YYYY, h:mm:ss a")}</td>

                                                </tr>
                                                <tr>
                                                    <td>Order Status</td>
                                                    <td className="text-right">{OrderDetailsList.order_status}</td>
                                                </tr>
                                                {/* <tr>
                                                    <td>Purchased From</td>
                                                    <td  className="text-right"> </td>
                                                </tr>  */}

                                                <tr>
                                                    <td>Nivoda Order ID</td>
                                                    <td className="text-right">{OrderDetailsList.nivoda_order_id}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-md-5">
                                    <div className="address-info">
                                        <h4>Account Information</h4>
                                        <table className="table table-striped table-borderless">
                                            <tbody>
                                                <tr>
                                                    <td>Customer Name</td>
                                                    <td className="text-right">{OrderDetailsList.firstname} {OrderDetailsList.lastname}</td>
                                                </tr>
                                                <tr>
                                                    <td>Email</td>
                                                    <td className="text-right"><a href={"mailto:" + OrderDetailsList.email}>{OrderDetailsList.email}</a></td>
                                                </tr>
                                                <tr>
                                                    <td>Phone</td>
                                                    <td className='text-right'>{OrderDetailsList.phone}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="row justify-content-between">
                                <div className="col-md-12">
                                    <div className="card-header p-0 pb-2 mb-3"><h3 className="title font-20">Address Information</h3></div>
                                </div>

                                <div className="col-md-5">
                                    <div className="address-info">
                                        <h4>Billing Address {/*<a href="#" className="edit-link font-13"> Edit</a>*/}</h4>
                                        <p>
                                            {BillingAddressList.firstname}   {BillingAddressList.lastname}<br></br>
                                            {BillingAddressList.street}      {BillingAddressList.country}<br></br>
                                            Pin code : {BillingAddressList.postcode}<br></br>

                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-5">
                                    <div className="address-info">
                                        <h4>Shipping Address {/*<a href="#" className="edit-link font-13"> Edit</a>*/}</h4>
                                        <p>
                                            {ShippingAddressList.firstname}   {ShippingAddressList.lastname}<br></br>
                                            {ShippingAddressList.street}      {ShippingAddressList.country}<br></br>
                                            Pin code : {ShippingAddressList.postcode}<br></br>

                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="row justify-content-between">
                                <div className="col-md-12">
                                    <div className="card-header p-0 pb-2 mb-3"><h3 className="title font-20">Payment & Shipping Method</h3></div>
                                </div>

                                <div className="col-md-5">
                                    <div className="address-info">
                                        <h4>Payment Information</h4>
                                        <p>{Common.GetPaymentMethod(OrderDetailsList.payment_method)}</p>
                                        <p>The order was placed using {OrderDetailsList.currency_name}.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-5">
                                    <div className="address-info">
                                        <h4>Shipping & Handling Information</h4>
                                        <p>
                                            {/* <b>Marketplace Rates - Multi Shipping :</b> $5.00 */}
                                            <b>Free</b>

                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="row justify-content-between">
                                <div className="col-md-12">
                                    <div className="card-header p-0 pb-2 mb-3"><h3 className="title font-20">Items Ordered</h3></div>
                                </div>

                                <div className="col-md-12">
                                    <div className="table-responsive">
                                        <table className="table text-center">
                                            <thead>
                                                <tr>
                                                    <th>Product</th>
                                                    <th>Engraving</th>
                                                    <th>Certficate</th>
                                                    <th>Price</th>
                                                    <th>Qty</th>
                                                    {/* <th>Tax Amount</th> */}
                                                    <th>Dis Amount</th>
                                                    <th>Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {ProductDetailsList?.map((item) => {
                                                    let engraving = item.engraving != null ? JSON.parse(item.engraving) : [];
                                                    return <tr>
                                                        <td>
                                                            {item.name + ' ' + (item.metal_quality_value != null && item.metal_quality_value != 'null' ? item.metal_quality_value : '')}<br />
                                                            {
                                                                !item.is_diamond ?
                                                                    <>
                                                                        <b>Ring Size:</b> {item.size_country + ' ' + item.size}<br />
                                                                        {
                                                                            item.shank_metal != null && item.shank_metal != undefined &&
                                                                            <p className="product-item-sku">Shank/Shoulder Metal : {item.shank_metal}</p>
                                                                        }
                                                                    </>
                                                                    :
                                                                    ''
                                                            }
                                                        </td>
                                                        {
                                                            engraving.text != undefined ?
                                                                <td>
                                                                    <b>Text:</b> {engraving.text}<br />
                                                                    <b>Font Style:</b> {engraving.font_style}
                                                                </td>
                                                                :
                                                                <td>N/A</td>
                                                        }

                                                        <td> {item.product_options?.filter(x => x.title == 'Certificate')[0]?.value}</td>
                                                        <td>{OrderDetailsList.symbol} {item.price}</td>
                                                        <td>{item.qty}</td>
                                                        {/* <td>{OrderDetailsList.symbol}{item.tax_price}</td> */}
                                                        <td>{OrderDetailsList.symbol}{item.discount_amount}</td>
                                                        <td>{OrderDetailsList.symbol}{item.final_price}</td>
                                                    </tr>
                                                })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="row justify-content-between">
                                <div className="col-md-12">
                                    <div className="card-header p-0 pb-2 mb-3"><h3 className="title font-20">Order Total</h3></div>
                                </div>

                                <div className="col-md-5">
                                    <div className="address-info">
                                        {/* <h4>Notes for this Order</h4> */}
                                        <form className="mt-4" onSubmit={UpdateOrderStatus}>
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1"><strong>Status</strong></label>
                                                <select id="inputState" className="form-control w-50" value={selectedValue} onChange={handleInputChanges('status')} >
                                                    {
                                                        OrderStatusList.map(status => (
                                                            <option value={status.id} hidden={status.is_disable}>{status.status}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                            {
                                                selectedStatus == "REFUNDED" && (OrderDetailsList.final_price != OrderDetailsList.total_refunded_amount)?
                                                    <div className="row">
                                                        <div className='col-md-12'>
                                                            <div className="form-group">
                                                                <label htmlFor="exampleInputEmail1"><strong>Refund Type</strong></label>
                                                                <select name="refund_type" className="form-control w-50" value={Inputs.refund_type} onChange={handleInputChanges('refund_type')} >
                                                                    <option value="full">Full</option>
                                                                    <option value="partial">Partial</option>
                                                                </select>
                                                                {validator.current.message('Refund Type', Inputs.refund_type, 'required', { className: 'error' })}
                                                            </div>
                                                            {
                                                                Inputs.refund_type == "partial" ?
                                                                    <div className="form-group">
                                                                        <label htmlFor="exampleInputEmail1"><strong>Refund Amount</strong></label>
                                                                        <input type="number" name="refund_amount" step="1" value={Math.round(Inputs.refund_amount)} className='form-control' onChange={handleInputChanges('refund_amount')} />
                                                                        {validator.current.message('Refund Amount', Inputs.refund_amount, 'required|min:1|max:' + (OrderDetailsList.remaining_refund_amount != null ? Math.round(OrderDetailsList.remaining_refund_amount) : OrderDetailsList.final_price) + ',num', { className: 'error' })}
                                                                    </div>
                                                                    : ""
                                                            }
                                                        </div>
                                                    </div>
                                                    : ""
                                            }
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1"><strong>Comment</strong></label>
                                                <textarea type="text" className="form-control" ref={textInput}></textarea>
                                            </div>
                                            <div className="form-group form-check mb-2">
                                                <input type="checkbox" className="form-check-input ml-0 checkbox" id="exampleCheck1" checked={checked} onChange={(e) => setChecked(e.target.checked)} />
                                                <label className="form-check-label" htmlFor="exampleCheck1">Notify Customer by Email</label>
                                            </div>
                                            <div className="form-group">
                                                <div className="btn-group">
                                                <button className="btn btn-dark btn-fw rounded"> Submit </button>
                                                </div>
                                            </div>

                                        </form>
                                    </div>
                                </div>
                                <div className="col-md-5">
                                    <div className="address-info">
                                        <h4>Order Totals</h4>
                                        <table className="table table-striped table-borderless">

                                            <tbody>
                                                <tr>
                                                    <th>Subtotal</th>
                                                    <th className="text-right">{OrderDetailsList.symbol}{OrderDetailsList.order_total}</th>
                                                </tr>
                                                <tr>
                                                    <td className="border-bottom">Discount</td>
                                                    <td className="text-right border-bottom">{OrderDetailsList.symbol}{OrderDetailsList.discount_amount}</td>
                                                </tr>
                                                <tr>
                                                    <td className="border-bottom">Shipping</td>
                                                    <td className="text-right border-bottom">Free</td>
                                                </tr>
                                                <tr>
                                                <td className="border-bottom">VAT/Tax ({OrderDetailsList.tax_percentage}%)</td>
                                                    <td className="text-right border-bottom">{OrderDetailsList.symbol}{OrderDetailsList.tax_amount}</td>
                                                </tr>
                                                {
                                                    (OrderDetailsList.card_fee && OrderDetailsList.payment_method != 'bank_transfer') > 0 &&
                                                    <tr>
                                                        <td className="border-bottom">Card Fee</td>
                                                        <td className="text-right border-bottom">{OrderDetailsList.symbol}{OrderDetailsList.card_fee}</td>
                                                    </tr>
                                                }
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <th>Grand Total</th>
                                                    <th className="text-right">{OrderDetailsList.symbol}{OrderDetailsList.final_price}</th>
                                                </tr>
                                                <tr>
                                                    <th>Total Paid</th>
                                                    <th className="text-right">{OrderDetailsList.symbol}{OrderDetailsList.final_price}</th>
                                                </tr>
                                                <tr>
                                                    <th>Total Refunded</th>
                                                    <th className="text-right">{OrderDetailsList.symbol}{OrderDetailsList.total_refunded_amount}</th>
                                                </tr>
                                                <tr>
                                                    <th>Total Due</th>
                                                    <th className="text-right">{OrderDetailsList.symbol}0.00</th>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OrdersDetails;