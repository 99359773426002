import React, { useEffect, useState, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import UserContext from "../../../config/UserContext";
import {
  HttpService,
  API,
  swal,
  Title,
  toast,
  Messages,
  DateFormat,
  Common,
  Permission_keys,
  ExportToExcel,
} from "../../../config/react";
import DataTable from "react-data-table-component";
import Spinner from "../../Spinner";
const Users = () => {
  const history = useHistory();
  const [IsAuth, setIsAuth] = useState(false);
  const [UsersList, setUsersList] = useState([]);
  const user = useContext(UserContext);
  const [isForceUpdate, setForceUpdate] = useState(false);

  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
    },
    {
      name: "Email",
      selector: (row) => row.email,
    },
    {
      name: "Status",
      selector: (row) =>
        row.is_active == 1 ? <label>Active</label> : <label>In Active</label>,
    },
    {
      name: "Created Date",
      selector: (row) => <DateFormat value={row.created_at}></DateFormat>,
    },
    {
      name: "Action",
      selector: (row) => (
        <div className="action-btn">
          {
            <div className="action-btn">
              <Link
                to={"/users/edit/" + row.id}
                className="text-dark"
                title="Edit"
              >
                <i className="fa fa-pencil-square-o" aria-hidden="true"></i>{" "}
              </Link>
              <Link
                onClick={(e) => DeleteUser(e, row.id)}
                to=""
                className="text-dark"
                title="Delete"
              >
                <i className="fa fa-trash-o" aria-hidden="true"></i>{" "}
              </Link>
            </div>
          }
        </div>
      ),
    },
  ];

  const DefaultFilters = {
    page: 1,
    search: "",
    page_size: 10,
  };
  const [CustomFilters, setCustomFilters] = useState(DefaultFilters);
  const [totalRows, settotalRows] = useState();
  useEffect(() => {
    document.title = Title.Users;
  }, []);
  useEffect(() => {
    if (!Common.validatePermission(Permission_keys.role_permission)) {
      history.push("/");
    }
    setIsAuth(true);
    setForceUpdate(!isForceUpdate);
    GetUsersList();
  }, [CustomFilters]);

  const GetUsersList = () => {
    var param = {
      page: CustomFilters.page,
      search: CustomFilters.search,
      page_size: CustomFilters.page_size,
    };
    HttpService.postApi(API.GetUsers, param).then((response) => {
      if (response.data.status == true) {
        var data = response.data.result[1];
        setUsersList(data);
        if (response.data.result[0].length > 0)
          settotalRows(response.data.result[0][0].total);
        setForceUpdate(!isForceUpdate);
      }
    });
  };
  const handlePageChange = (e) => {
    setCustomFilters({ ...CustomFilters, ["page"]: e });
  };

  const handlePerRowsChange = (e) => {
    setCustomFilters({ ...CustomFilters, ["page_size"]: e });
  };

  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <input
        type="text"
        title="Search For Name, Email."
        className="form-control col-md-3"
        placeholder="Search"
        onChange={(e) =>
          setCustomFilters({ ...CustomFilters, ["search"]: e.target.value })
        }
      />
    );
  });

  const DeleteUser = async (e, id) => {
    e.preventDefault();
    swal(Messages.Swal_Delete_Prop).then(function (isConfirm) {
      if (isConfirm) {
        var param = {
          id: id,
        };
        HttpService.postApi(API.DeleteUser, param).then((response) => {
          toast.success(Messages.Record_Deleted);
          GetUsersList();
          setForceUpdate(!isForceUpdate);
        });
      }
    });
  };
  const handleExportExcel = () => {
    HttpService.getApi(API.GetUserExcel).then((response) => {
      if (response.data.result) {
        ExportToExcel(response.data.result[0], "Users");
      }
    });
  };
  return !IsAuth ? (
    <Spinner />
  ) : (
    <div className="content-wrapper pb-0">
      <div className="page-header">
        <h3 className="page-title"> {Title.Users} </h3>
        <div className="btn-group">
          <Link to="/users/add" className="btn btn-dark btn-fw rounded mr-2">
            {" "}
            Add User{" "}
          </Link>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <button
                className="btn btn-primary float-right"
                onClick={handleExportExcel}
              >
                Export Excel    
              </button>
              <div className="table-responsive">
                <DataTable
                  columns={columns}
                  data={UsersList}
                  // progressPending={loading}
                  // pagination
                  paginationServer
                  // paginationTotalRows={totalRows}
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                  subHeader
                  // subHeaderComponent={subHeaderComponentMemo}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Users;
