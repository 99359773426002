import React, { useEffect, useState, useContext } from 'react';
import { Link } from "react-router-dom";
import { HttpService, API, swal, Title, toast, MaterialTable, Messages, DateFormat, Common, Permission_keys } from '../../config/react'
import DataTable from 'react-data-table-component';
import { DateRangePickerComponent } from '@syncfusion/ej2-react-calendars';
import '../../assests/fonts/mdi/css/material.css'
import { useHistory } from 'react-router-dom';
import Spinner from '../Spinner';
const Appointment = () => {
    const history = useHistory();
    const [IsAuth, setIsAuth] = useState(false)
    const [AppointmentList, setAppointment] = useState([])
    const [isForceUpdate, setForceUpdate] = useState(false);
    const [AppointmentUsers, setAppointmentUsers] = useState([]);
    const [totalRows, settotalRows] = useState()
    const [ShowFilters, setShowFilters] = useState(false);
    const [ServiceTypeList, setServiceTypeList] = useState([]);
    const DefaultFilters = {
        page: 1,
        search: "",
        page_size: 10,
        assignee: "",
        status: "",
        service_type: 0,
        booking_from: "",
        booking_to: "",
        assignee_name: "",
        sort_column: "",
        sort_direction: ""
    }
    const [CustomFilters, setCustomFilters] = useState(DefaultFilters)
    const columns = [
        {
            name: 'Appointment Date',
            sortable: true,
            sortField: 'booking_date',
            selector: row => <DateFormat value={row.booking_date}></DateFormat>,
        },
        {
            name: 'Service',
            sortable: true,
            sortField: 'service_type',
            selector: (row) =>
                <div>
                    <label> {row.title} </label><br></br>
                    {row.service_type == 1 ?
                        <label>  (In Store)</label> : <label>  (Virtual)</label>}
                </div>
        },
        {
            name: 'Name',
            selector: (row) => <label> {row.name}</label>
        },
        {
            name: 'E-Mail',
            selector: (row) => <label> {row.email} </label>
        },
        {
            name: 'Contact No',
            selector: (row) => '+' + row.contact_number
        },
        {
            name: 'Status',
            selector: row => row.status,
        },
        {
            name: 'Assignee',
            selector: row => row.assignee,
        },
        {
            name: 'Booking Date',
            sortable: true,
            sortField: 'created_at',
            selector: row => <DateFormat value={row.created_at}></DateFormat>,

        },
        {
            name: 'Action',
            selector: (row) =>
                <div className="action-btn">
                    <Link to={"/Appointments/edit/" + row.id} className="text-dark" title="Edit"><i className="fa fa-pencil-square-o" aria-hidden="true"></i> </Link>
                </div>
            ,
        },
    ];

    useEffect(() => {
        if (!Common.validatePermission(Permission_keys.Book_An_Appointment)) {
            history.push("/");
        }
        setIsAuth(true);
        setForceUpdate(!isForceUpdate)
        document.title = Title.AppointmentTitle
        GetAppointmentList()
        GetAppointmentUsers()
        GetServiceList()
    }, [CustomFilters])

    const handleSort = (e, direction) => {
        setCustomFilters({ ...CustomFilters, ['sort_column']: e.sortField, ['sort_direction']: direction });
    }

    const handleInputChanges = (e, fieldName) => {
        e.preventDefault();
        if (fieldName == 'booking_date') {
            if (e.value != null) {
                CustomFilters['booking_from'] = e.value[0];
                CustomFilters['booking_to'] = e.value[1];
            } else {
                CustomFilters['booking_from'] = "";
                CustomFilters['booking_to'] = "";
            }
        }
        else if (fieldName == 'assignee') {
            let assigneeName = AppointmentUsers.filter(item => item.id == e.target.value)
            assigneeName = assigneeName.length > 0 ? assigneeName[0].name : '';
            CustomFilters[fieldName] = e.target.value;
            CustomFilters['assignee_name'] = assigneeName;
        }
        else {
            CustomFilters[fieldName] = e.target.value;
        }
        setForceUpdate(!isForceUpdate);
    };
    const GetServiceList = () => {
        var param = {
            "id": null
        }
        HttpService.postApi(API.Getservices, param, false)
            .then(response => {
                setServiceTypeList(response.data.result[0])
            })
    }
    const GetAppointmentUsers = () => {
        HttpService.getApi(API.GetAppointmentUsers)
            .then(response => {
                if (response.data.result) {
                    var data = response.data.result[0];
                    setAppointmentUsers(data);
                }
            });
    }
    const GetAppointmentList = () => {
        var param = {
            "page": CustomFilters.page,
            "search": CustomFilters.search,
            "page_size": CustomFilters.page_size,
            "assignee": CustomFilters.assignee,
            "status": CustomFilters.status,
            "sort_column": CustomFilters.sort_column,
            "sort_direction": CustomFilters.sort_direction,
            "service_type": CustomFilters.service_type,
            "booking_from": CustomFilters['booking_from'] != "" ? new Date(CustomFilters['booking_from']).toISOString().slice(0, 19).replace('T', ' ') : "",
            "booking_to": CustomFilters['booking_to'] != "" ? new Date(CustomFilters['booking_to']).toISOString().slice(0, 19).replace('T', ' ') : ""
        }
        HttpService.postApi(API.GetAppointment, param)
            .then(response => {
                setAppointment(response.data.result[0])
                if (response.data.result[0].length > 0)
                    settotalRows(response.data.result[0][0].total_count)
            });
    }

    const handlePageChange = (e) => {
        setCustomFilters({ ...CustomFilters, ['page']: e });
    }

    const handlePerRowsChange = (e) => {
        setCustomFilters({ ...CustomFilters, ['page_size']: e });
    }

    const subHeaderComponentMemo = React.useMemo(() => {
        return (
            <input type="text" title="Search For Service, Name, E-mail, Contact No." className='form-control col-md-3' placeholder='Search' onChange={e => setCustomFilters({ ...CustomFilters, ['page']: 1, ['search']: e.target.value })} />
        );
    });
    const openFilterBox = () => {
        if (ShowFilters) {
            setShowFilters(false);
        }
        else {
            setShowFilters(true);
        }
    }
    const ClearFilter = (e) => {
        // e.preventDefault()
        setCustomFilters(DefaultFilters);
        setForceUpdate(!isForceUpdate);
        GetAppointmentList();
    }
    return !IsAuth ? <Spinner /> : (
        <div className="content-wrapper pb-0">
            <div className="page-header">
                <h3 className="page-title"> {Title.AppointmentTitle} </h3>
                <div className="btn-group ml-auto">
                    <Link to="#" onClick={openFilterBox} className="btn btn-primary btn-fw rounded filter-btn mr-2"> Filters </Link>
                </div>
            </div>
            <div className={ShowFilters ? "filter-bar d-block" : "filter-bar"} id="filter-bar">
                <div className="grid-margin w-100 stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <form>
                                <div className="row">
                                    <div className="col-md-6 col-lg-3">
                                        <div className="form-group">
                                            <label>Assignee</label>
                                            <select className="form-control" name="assignee" onChange={(e) => handleInputChanges(e, 'assignee')} value={CustomFilters.assignee}>
                                                <option value="">Select</option>
                                                {
                                                    AppointmentUsers.map((item, index) => (
                                                        <option value={item.id} key={index}>{item.name}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-3">
                                        <div className="form-group">
                                            <label>Status</label>
                                            <select className="form-control" name="status" onChange={(e) => handleInputChanges(e, 'status')} value={CustomFilters.status}>
                                                <option value="">Select</option>
                                                <option value="Pending">Pending</option>
                                                <option value="In progress">In progress</option>
                                                <option value="Successful">Successful</option>
                                                <option value="Unsuccessful">Unsuccessful</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-3">
                                        <div className="form-group">
                                            <label>Service Type</label>
                                            <select className="form-control" name="service_type" onChange={(e) => handleInputChanges(e, 'service_type')} value={CustomFilters.service_type}>
                                                <option value="">Select</option>
                                                {
                                                    ServiceTypeList.map((item, index) => (
                                                        <option value={item.id} key={index}>{item.title}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-3">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Booking Date</label>
                                            <DateRangePickerComponent onChange={(e) => handleInputChanges(e, 'booking_date')}></DateRangePickerComponent>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-12 pr-2 text-right">
                                        <div className="btn-group">
                                            <Link to="#" className="btn btn-dark btn-fw-130 rounded mr-2" onClick={e => GetAppointmentList(e)}>Apply</Link>
                                        </div>
                                        <div className="btn-group ml-auto">
                                            {/* <Link to="#" className="btn btn-primary btn-fw rounded mr-2" onClick={e => ClearFilter(e)}> Cancel </Link> */}
                                            <span className="filter-result-repeat"><a><button type="button" onClick={e => ClearFilter(e)} className="btn btn-primary btn-fw-130 rounded mr-2">Clear</button></a></span>
                                        </div> 
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="table-responsive">
                                <DataTable
                                    columns={columns}
                                    data={AppointmentList}
                                    // progressPending={loading}
                                    pagination
                                    paginationServer
                                    paginationTotalRows={totalRows}
                                    onChangeRowsPerPage={handlePerRowsChange}
                                    onChangePage={handlePageChange}
                                    subHeader
                                    subHeaderComponent={subHeaderComponentMemo}
                                    onSort={handleSort}
                                    sortServer
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Appointment;