import React, { useState, useRef, useEffect, useContext } from 'react';
import { useHistory, useParams, Link } from "react-router-dom";
import UserContext from '../../config/UserContext'
import { HttpService, API, SimpleReactValidator, Title, toast, Messages, Nestable, Common, Permission_keys, FileUploadWithPreview, getBase64 } from '../../config/react'
import Spinner from '../Spinner';
const AddProductAttribute = () => {
    var ImageArry = [];
    const urlParams = useParams();
    const bottomRef = useRef();
    const history = useHistory();
    const [IsAuth, setIsAuth] = useState(false)
    const validator = useRef(new SimpleReactValidator())
    const user = useContext(UserContext);
    const [isForceUpdate, setForceUpdate] = useState(false);
    const [OptionItems, setOptionItems] = useState([])
    const [Inputs, setInputs] = useState({
        id: 0,
        attribute_name: "",
        attribute_code: "",
        input_type: "",
        is_required: 0,
        use_in_search: 0,
        show_in_storefront: 0,
        default_value: "",
        status: 0,
        options: [],
        option_id: []
    });
    var attr_Images = '';
    useEffect(() => {
        if (!Common.validatePermission(Permission_keys.Attribute)) {
            history.push("/");
        }
        setIsAuth(true);
        setForceUpdate(!isForceUpdate)
        document.title = Title.AddProductAttribute
        if (urlParams.id != null) {
            document.title = Title.EditProductAttribute
            GetProductAttributeDetail()
        }
    }, [urlParams.id])

    const handleInputChanges = (e) => {
        var field = e.target.name, fieldval = e.target.value;

        if (field == "status" || field == "is_required" || field == "use_in_search" || field == "show_in_storefront")
            Inputs[field] = e.target.checked;
        else
            Inputs[field] = fieldval;
        setForceUpdate(!isForceUpdate);
    }

    const handleText = i => e => {
        let options = [...Inputs.options]
        let index = options.findIndex(x => x.value === i);
        options[index] = { id: options[index].id != undefined ? options[index].id : '', value: e.target.value, sort_order: options.length > 0 ? Math.max(...options.map(o => o.sort_order)) + 1 : 0 };
        // options.push({id: "", value: e.target.value, sort_order: 0});
        setInputs({ ...Inputs, ['options']: options });
        //Inputs['options'] = options;
        setForceUpdate(!isForceUpdate);
    }

    const handleDelete = i => e => {
        e.preventDefault()
        let options = [...Inputs.options]
        options = options.filter(x => x.id != i);
        let optionIds = [];
        options.map(function (optionItem, key) {
            optionIds.push(optionItem.id);
        })
        Inputs['option_id'] = optionIds;
        Inputs['options'] = options;
        setForceUpdate(!isForceUpdate);
    }

    const addOption = (e) => {
        e.preventDefault()
        let options = Inputs.options
        if (options === undefined || options === null) options = []
        options.push({
          id: Math.floor(1000 + Math.random() * 9000),
          value: '',
          sort_order: options.length > 0 ? Math.max(...options.map((o) => o.sort_order)) + 1 : 0
        })
        setInputs({ ...Inputs, options })
        setForceUpdate(!isForceUpdate)
      }

    const GetProductAttributeDetail = () => {
        var param = {
            "id": urlParams.id
        }
        HttpService.postApi(API.GetProductAttributeDetail, param)
            .then(response => {
                if (response.data.result[0]) {
                    var data = response.data.result[0];
                    data.map(item => (
                        Object.keys(item).map(function (key, value) {
                            if (key == "attribute_options" && item["attribute_options"] != null) {
                                var options = Common.GetJSON(item["attribute_options"]);
                                let IsOptionValues = options.filter(x => x.value != null).length > 0
                                if (IsOptionValues) {
                                    options.sort(function (a, b) {
                                        return a.sort_order - b.sort_order;
                                    });
                                    Inputs['options'] = options;
                                    options.map(function (optionItem, key) {
                                        Inputs['option_id'].push(optionItem.id);
                                        setTimeout(() => {
                                            initImagePlugin(optionItem.id, optionItem.value_image)
                                        }, 500);
                                    })
                                    setOptionItems(options);
                                }
                            }
                            else {
                                Inputs[key] = item[key];
                            }
                        })
                    ))
                    setForceUpdate(!isForceUpdate)
                }
            });
    }
    const initImagePlugin = (imageId = null, image = null) => {
        if (image != '' && image != null) {
            attr_Images = new FileUploadWithPreview("attr_Images_" + imageId, {
                text: {
                    chooseFile: 'Choose Image...'
                },
                presetFiles: [
                    image,
                ]
            });
        }
        else {
            attr_Images = new FileUploadWithPreview("attr_Images_" + imageId, {
                text: {
                    chooseFile: 'Choose Image...'
                }
            });
        }
    }
    window.addEventListener("fileUploadWithPreview:imagesAdded", function (e) {
        const imageType = e.detail.cachedFileArray[0].type;
        var type = ''
        if (e.detail.cachedFileArray[0].type.includes('svg'))
            type = 'svg'
        else
            type = e.detail.cachedFileArray[0].type.split('/')[1];

        const size = e.detail.cachedFileArray[0].size;
        const Id = e.detail.uploadId.split('_')[2]
        if (imageType == 'image/jpeg' || imageType == 'image/jpg' || imageType == 'image/png' || imageType == 'image/svg+xml') {
            if (size <= 2097152) {
                getBase64(e.detail.cachedFileArray[0], (result) => {
                    ImageArry.push({ 'imageId': Id, 'image': result, 'type': '.' + type })
                });
            }
        }
        else {

        }
    });

    window.addEventListener("fileUploadWithPreview:clearButtonClicked", function (e) {
        if (ImageArry.length > 0) {
            const Id = e.detail.uploadId.split('_')[2]
            ImageArry = ImageArry.filter(x => x.imageId != Id)
        }
    });
    const SubmitForm = (e) => {
        if (!validator.current.allValid()) {
            validator.current.showMessages();
            setForceUpdate(!isForceUpdate)
            return
        }
        var options = [];
        var selectedOptionIds = Inputs.option_id;
        for (var i = 0; i < selectedOptionIds.length; i++) {
            if (selectedOptionIds[i] > 0) {
                options.push(selectedOptionIds[i]);
            }
        }
        var newOptions = Inputs.options;
        Inputs.options = [];
        for (let index = 0; index < newOptions.length; index++) {
            const element = newOptions[index];
            var image = ImageArry.filter(x => x.imageId == element.id)
            if (image.length > 0)
                Inputs.options.push({ 'id': element.id, 'sort_order': element.sort_order, 'value': element.value, 'value_image': Inputs.attribute_name + '_' + element.value.replaceAll(' ', '_') + image[0].type })
            else
                Inputs.options.push({ 'id': element.id, 'sort_order': element.sort_order, 'value': element.value, 'value_image': null })
        }

        var param = {
            "id": Inputs.id,
            "attribute_name": Inputs.attribute_name,
            "attribute_code": Inputs.attribute_code,
            "input_type": Inputs.input_type,
            "is_required": Inputs.is_required,
            "use_in_search": Inputs.use_in_search,
            "show_in_storefront": Inputs.show_in_storefront,
            "default_value": Inputs.default_value,
            "status": Inputs.status,
            "attribute_options": Inputs.options,
            "attribute_options_images": ImageArry,
            "selected_options_id": options.join(","),
            "Userid": user.id
        }
        if (urlParams.id == null) {
            HttpService.postApi(API.AddProductAttribute, param)
                .then(response => {
                    //console.log(response);
                    if (response.data.message) {
                        toast.warning(response.data.message)
                        setForceUpdate(!isForceUpdate);
                        return
                    }
                    ImageArry = [];
                    toast.success(Messages.Record_Added)
                    setForceUpdate(!isForceUpdate);
                    history.push('/attribute/productattribute')
                });
        }
        else {
            HttpService.postApi(API.UpdateProductAttribute, param)
                .then(response => {
                    //console.log(response);
                    if (response.data.message) {
                        toast.warning(response.data.message)
                        setForceUpdate(!isForceUpdate);
                        return
                    }
                    ImageArry = [];
                    toast.success(Messages.Record_Updated)
                    setForceUpdate(!isForceUpdate);
                    history.push('/attribute/productattribute')
                });
        }

    }
    const styles = {
        position: "relative",
        padding: "10px 15px",
        fontSize: "15px",
        border: "1px solid #f9fafa",
        background: "#f9fafa",
        cursor: "move"
    };
    const renderItem = (item) => {
        // console.log(item);
        return (
            <div style={styles}>
                <div className="row form-group mb-0" >
                    {/* <label className="col-md-12">Option</label> */}
                    <div className="col-md-4 pr-1">
                        <input
                            type="text"
                            className="form-control"
                            name="options[]"
                            data-id={item.item.id}
                            placeholder="Option"
                            onChange={handleText(item.item.value)}
                            value={item.item.value}
                        />
                    </div>
                    <div className="col-md-6 product-attr-section">
                        <div className="custom-file-container" data-upload-id={"attr_Images_" + item.item.id}>
                            <label className="custom-file-container__custom-file">
                                <input type="file" className="custom-file-container__custom-file__custom-file-input" accept=".png,.jpg,.jpeg,.svg" aria-label="Choose Image" />
                                <input type="hidden" name="MAX_FILE_SIZE" value="110485760" />
                                <span className="custom-file-container__custom-file__custom-file-control"></span>
                            </label>
                            <div className="btn-remove-image">
                                <a href={void (0)} className="custom-file-container__image-clear" data-upload-id={item.item.id} title="Remove Image">&times;</a>
                                <div className="custom-file-container__image-preview">

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-2 pl-1">
                        <button className="btn btn-danger input-btn btn-block" onClick={handleDelete(item.item.id)}>X</button>
                    </div>
                </div>
            </div>
        );
    }
    const changeOptionOrder = (item) => {
        let attributeOptions = [];
        item.items.map((item, index) => {
            attributeOptions.push({ option_id: item.id, label: item.text, sort_order: index });
        });
        let param = {
            'items': attributeOptions
        }
        HttpService.postApi(API.ChangeAttributeOptionOrder, param)
            .then(response => {
                //console.log(response);
            });
    }
    return !IsAuth ? <Spinner /> : (
        <div className="content-wrapper pb-0">
            <div className="page-header">
                <h3 className="page-title mb-0"> {(urlParams.id == null) ? Title.AddProductAttribute : Title.EditProductAttribute} </h3>
                <div className="btn-group">
                    <button type="button" className="btn btn-dark btn-fw rounded mr-2" onClick={history.goBack}> Back </button>
                </div>
            </div>
            <div className="row">
                <div className="col-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <form className="forms-sample">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Attribute Name</label>
                                            <input type="text" className="form-control" name="attribute_name" onChange={handleInputChanges} defaultValue={Inputs.attribute_name} placeholder="Attribute Name" />
                                            {validator.current.message('Attribute Name', Inputs.attribute_name, 'required', { className: 'error' })}
                                        </div>
                                        <div className="form-group">
                                            <label>Attribute Code</label>
                                            <input type="text" className="form-control" name="attribute_code" onChange={handleInputChanges} defaultValue={Inputs.attribute_code} placeholder="Attribute Code" />
                                            {validator.current.message('Attribute Code', Inputs.attribute_code, 'required', { className: 'error' })}
                                        </div>
                                        <div className="form-group">
                                            <label>Input Type</label>
                                            <select className="form-control" name="input_type" value={Inputs.input_type} onChange={handleInputChanges}>
                                                <option value="">--Select--</option>
                                                <option value="dropdown">Dropdown</option>
                                                <option value="text">Textbox</option>
                                                <option value="radio">Radio Button</option>
                                                <option value="radio_image">Radio Button With Image</option>
                                                <option value="checkbox">Checkbox</option>
                                                <option value="range_slider">Range Slider</option>
                                                <option value="slider">Slider</option>
                                            </select>
                                            {validator.current.message('Input Type', Inputs.input_type, 'required', { className: 'error' })}
                                        </div>
                                        <div className="form-group">
                                            <label>Default Value</label>
                                            <input type="text" className="form-control" name="default_value" onChange={handleInputChanges} defaultValue={Inputs.default_value} placeholder="Default Value" />
                                            {validator.current.message('Default Value', Inputs.default_value, 'required', { className: 'error' })}
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-3">
                                                <label htmlFor="exampleInputUsername" className="col-sm-12">Required</label>
                                                <div className="col-sm-12">
                                                    <div className="custom-control custom-switch mt-1">
                                                        <input type="checkbox" className="custom-control-input" name="is_required" onChange={handleInputChanges} id="is_required" checked={Inputs.is_required} />
                                                        <label className="custom-control-label" htmlFor="is_required"> </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-3">
                                                <label htmlFor="exampleInputUsername1" className="col-sm-12">Searchable</label>
                                                <div className="col-sm-12">
                                                    <div className="custom-control custom-switch mt-1">
                                                        <input type="checkbox" className="custom-control-input" name="use_in_search" onChange={handleInputChanges} id="use_in_search" checked={Inputs.use_in_search} />
                                                        <label className="custom-control-label" htmlFor="use_in_search"> </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-3">
                                                <label htmlFor="exampleInputUsername2" className="col-sm-12">Visible</label>
                                                <div className="col-sm-12">
                                                    <div className="custom-control custom-switch mt-1">
                                                        <input type="checkbox" className="custom-control-input" name="show_in_storefront" onChange={handleInputChanges} id="show_in_storefront" checked={Inputs.show_in_storefront} />
                                                        <label className="custom-control-label" htmlFor="show_in_storefront"> </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-3">
                                                <label htmlFor="exampleInputUsername3" className="col-sm-12">Status</label>
                                                <div className="col-sm-12">
                                                    <div className="custom-control custom-switch mt-1">
                                                        <input type="checkbox" className="custom-control-input" name="status" onChange={handleInputChanges} id="status" checked={Inputs.status} />
                                                        <label className="custom-control-label" htmlFor="status"> </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-5">
                                            <div className="col-md-6">
                                                <button type="button" onClick={SubmitForm} className="btn btn-primary mr-2"> Submit </button>
                                                <Link to="/attribute/productattribute" className="btn btn-light">Cancel</Link>
                                            </div>
                                        </div>
                                        {/* {
                                            Inputs.input_type == "" || Inputs.input_type != 'text' &&
                                        } */}
                                    </div>
                                    <div className={Inputs['input_type'] == 'dropdown' || Inputs['input_type'] == 'radio' || Inputs['input_type'] == 'checkbox' || Inputs['input_type'] == 'range_slider' || Inputs['input_type'] == 'radio_image' ? "col-md-6" : "col-md-6 d-none"}>
                                        <div className="col-md-12 text-right">
                                            <button className="btn btn-primary" onClick={addOption}>Add Option</button>
                                        </div>
                                        <p>Options</p>
                                        <div ref={bottomRef} ></div>
                                        <Nestable
                                            items={Inputs['options']}
                                            renderItem={renderItem}
                                            collapsed={false}
                                            onChange={changeOptionOrder}
                                            maxDepth={1}
                                        />

                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddProductAttribute;