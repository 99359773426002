import React, { useEffect, useState, useContext } from 'react';
import { Link } from "react-router-dom";
import { HttpService, API, swal, Title, toast, MaterialTable, Messages, DateFormat,Common,Permission_keys } from '../../config/react'
import DataTable from 'react-data-table-component';
import { DateRangePickerComponent } from '@syncfusion/ej2-react-calendars';
import '../../assests/fonts/mdi/css/material.css'
import { useHistory } from 'react-router-dom';
import Spinner from '../Spinner';
const AppointmentReport = () => {
    const history = useHistory();
    const [IsAuth, setIsAuth] = useState(false)
    const [AppointmentReportData, setAppointmentReportData] = useState([]);
    const [AppointmentUsers, setAppointmentUsers] = useState([]);
    const [isForceUpdate, setForceUpdate] = useState(false);
    const [ShowFilters, setShowFilters] = useState(false);
    const [ShowDateRange, setShowDateRange] = useState(false);
    const DefaultFilters = {
        page: 1,
        page_size: 10,
        filter_type: "",
        from_date: "",
        to_date: "",
        assignee: ""
    }
    const [CustomFilters, setCustomFilters] = useState(DefaultFilters)
    const columns = [
        {
            name: 'Assignee',
            selector: (row) => row.name
        },
        {
            name: ' In Progress',
            selector: (row) => row.in_progress
        },
        {
            name: 'Successful',
            selector: (row) => row.successful
        },
        {
            name: 'Unsuccessful',
            selector: row => row.unsuccessful,
        },
    ];

    useEffect(() => {
        if (!Common.validatePermission(Permission_keys.Book_An_Appointment)) {
            history.push("/");
        }
        setIsAuth(true);
        setForceUpdate(!isForceUpdate)
        document.title = Title.AppointmentReport
        GetAppointmentUsers()
    }, [])

    useEffect(() => {
        GetAppointmentReport()
    }, [CustomFilters])

    const GetAppointmentUsers = () => {
        HttpService.getApi(API.GetAppointmentUsers)
            .then(response => {
                if (response.data.result) {
                    var data = response.data.result[0];
                    setAppointmentUsers(data);
                }
            });
    }
    const GetAppointmentReport = () => {
        var param = {
            "page": CustomFilters.page,
            "page_size": CustomFilters.page_size,
            "filter_type": CustomFilters.filter_type,
            "from_date": CustomFilters.from_date != "" ? new Date(CustomFilters.from_date + ' GMT').toISOString().slice(0, 19).replace('T', ' ') : "",
            "to_date": CustomFilters.to_date != "" ? new Date(CustomFilters.to_date + ' GMT').toISOString().slice(0, 19).replace('T', ' ') : "",
            "assignee": CustomFilters.assignee
        }

        HttpService.postApi(API.GetAppointmentReport, param)
            .then(response => {
                setAppointmentReportData(response.data.result[0])
            });
    }
    const handlePageChange = (e) => {
        setCustomFilters({ ...CustomFilters, ['page']: e });
    }

    const handlePerRowsChange = (e) => {
        setCustomFilters({ ...CustomFilters, ['page_size']: e });
    }
    const handleInputChanges = (e, fieldName) => {
        e.preventDefault();
        if (fieldName == 'booking_date') {
            if (e.value != null && CustomFilters['filter_type'] == 'custom') {
                CustomFilters['from_date'] = e.value[0];
                CustomFilters['to_date'] = e.value[1];
            } else {
                CustomFilters['from_date'] = "";
                CustomFilters['to_date'] = "";
            }
        }
        else {
            CustomFilters[fieldName] = e.target.value;
        }
        setForceUpdate(!isForceUpdate);
    };
    const ClearFilter = (e) => {
        // e.preventDefault()
        setCustomFilters(DefaultFilters);
        setForceUpdate(!isForceUpdate);
    }

    const openFilterBox = () => {
        if (ShowFilters) {
            setShowFilters(false);
        }
        else {
            setShowFilters(true);
        }
    }
    return !IsAuth ? <Spinner /> : (
        <div className="content-wrapper pb-0">
            <div className="page-header">
                <h3 className="page-title"> {Title.AppointmentReport} </h3>
                <div className="btn-group ml-auto">
                    <Link to="#" onClick={openFilterBox} className="btn btn-primary btn-fw rounded filter-btn mr-2"> Filters </Link>
                </div>
            </div>
            <div className={ShowFilters ? "filter-bar d-block" : "filter-bar"} id="filter-bar">
                <div className="grid-margin w-100 stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <form>
                                <div className="row">
                                    <div className="col-md-6 col-lg-3">
                                        <div className="form-group">
                                            <label>Filter Type</label>
                                            <select className="form-control" name="filter_type" onChange={(e) => handleInputChanges(e, 'filter_type')} value={CustomFilters.filter_type}>
                                                <option value="">Select</option>
                                                <option value="week">Week</option>
                                                <option value="month">Month</option>
                                                <option value="year">Year</option>
                                                <option value="custom">Custom Date</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-3">
                                        <div className="form-group">
                                            <label>Assignee</label>
                                            <select className="form-control" name="assignee" onChange={(e) => handleInputChanges(e, 'assignee')} value={CustomFilters.assignee}>
                                                <option value="">Select</option>
                                                {
                                                    AppointmentUsers.map((item, index) => (
                                                        <option value={item.id} key={index}>{item.name}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className={CustomFilters.filter_type == 'custom' ? "col-md-6 col-lg-3" : "d-none"}>
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Date</label>
                                            <DateRangePickerComponent onChange={(e) => handleInputChanges(e, 'booking_date')}></DateRangePickerComponent>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-3 pr-2 mt-1 pt-3">
                                        <div className="btn-group">
                                            <Link to="#" className="btn btn-dark btn-fw-130 rounded mr-2" onClick={e => GetAppointmentReport(e)}>Apply</Link>
                                        </div>
                                        <div className="btn-group ml-auto">
                                            {/* <Link to="#" className="btn btn-primary btn-fw rounded mr-2" onClick={e => ClearFilter(e)}> Cancel </Link> */}
                                            <span className="filter-result-repeat"><a><button type="button" onClick={e => ClearFilter(e)} className="btn btn-primary btn-fw-130 rounded mr-2">Clear </button></a></span>
                                        </div> 
                                    </div>
                                </div>
                                 
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="table-responsive">
                                <DataTable
                                    columns={columns}
                                    data={AppointmentReportData}
                                    // progressPending={loading}
                                    pagination
                                    paginationServer
                                    onChangeRowsPerPage={handlePerRowsChange}
                                    onChangePage={handlePageChange}
                                    subHeader
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AppointmentReport;