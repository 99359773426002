
import React, { useState, useRef, useEffect } from 'react';
import { useHistory, useParams, Link } from "react-router-dom";
import { HttpService, API, SimpleReactValidator, Title, toast, Messages, DateFormat, RouteLinks, Common, Permission_keys } from '../../config/react'
import Spinner from '../Spinner';

const EditAppointment = () => {

    const urlParams = useParams();
    const [isForceUpdate, setForceUpdate] = useState(false)
    const [AppointmentUsers, setAppointmentUsers] = useState([]);
    const history = useHistory();
    const [IsAuth, setIsAuth] = useState(false)
    const validator = useRef(new SimpleReactValidator())
    const currencies = Common.getCurrencies();
    useEffect(() => {
        if (!Common.validatePermission(Permission_keys.Book_An_Appointment)) {
            history.push("/");
        }
        setIsAuth(true);
        setForceUpdate(!isForceUpdate)
        document.title = Title.AddService
        if (urlParams.id != null) {
            document.title = Title.EditService
            GetAppointmentUsers();
            GetAppointmentDetail();
        }
    }, [urlParams.id])
    const [Inputs, setInputs] = useState({
        id: 0,
        remarks: "",
        status: '',
        user_id: ''
    });

    const handleInputChanges = (e) => {
        var field = e.target.name, fieldval = e.target.value;
        Inputs[field] = fieldval;

        setForceUpdate(!isForceUpdate);
    }

    const SubmitForm = (e) => {
        if (!validator.current.allValid()) {
            validator.current.showMessages();
            setForceUpdate(!isForceUpdate)
            return
        }
        var param = {
            "id": Inputs.id,
            "remarks": Inputs.remarks,
            "status": Inputs.status ?? 'Pending',
            "user_id": Inputs.user_id,
            "is_from_admin_panel": true
        }
        HttpService.postApi(API.setAppointment, param)
            .then(response => {
                if (response.data.message) {
                    toast.warning(response.data.message)
                    setForceUpdate(!isForceUpdate);
                    return
                }
                if (urlParams.id == null)
                    toast.success(Messages.Record_Added)
                else
                    toast.success(Messages.Record_Updated)
                setForceUpdate(!isForceUpdate);
                history.push('/Appointments')
            });
    }

    const GetAppointmentDetail = () => {
        var param = {
            "page": 1,
            "search": '',
            "page_size": 10,
            "id": urlParams.id,
        }
        HttpService.postApi(API.GetAppointment, param, false)
            .then(response => {
                if (response.data.result) {
                    var data = response.data.result[0];
                    var service = data.filter(x => x.id == urlParams.id)[0];
                    //console.log(service);
                    setInputs(service)
                    setForceUpdate(!isForceUpdate)
                }
            });
    }
    const GetAppointmentUsers = () => {
        HttpService.getApi(API.GetAppointmentUsers)
            .then(response => {
                if (response.data.result) {
                    var data = response.data.result[0];
                    setAppointmentUsers(data);
                }
            });
    }

    const GoBack = () => {
        if (history.length > 2) {
            history.goBack()
        }
        else {
            history.push(RouteLinks.Appointment);
        }

    }
    return !IsAuth ? <Spinner /> : (
        <div className="content-wrapper pb-0">
            <div className="page-header">
                <h3 className="page-title mb-0"> {Title.EditAppointment} </h3>
                <div className="btn-group">
                    <button type="button" className="btn btn-dark btn-fw rounded mr-2" onClick={GoBack}> Back </button>
                </div>
            </div>
            <div className="row">
                <div className="col-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <form className="forms-sample">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-group row">
                                            <label className="col-4"><b>Name</b></label>
                                            <label className="col-8">{Inputs.name}</label>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group row">
                                            <label className="col-4"><b>Email</b></label>
                                            <label className="col-8">{Inputs.email}</label>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group row">
                                            <label className="col-4"><b>Phone</b></label>
                                            <label className="col-8">{'+' + Inputs.contact_number}</label>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group row">
                                            <label className="col-4"><b>Appointment Date</b></label>
                                            <label className="col-8">{new Date(Inputs.booking_date).toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true })}</label>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group row">
                                            <label className="col-4"><b>Service Type</b></label>
                                            <label className="col-8">{Inputs.service_type == 1 ? 'In Store' : 'Virtual'}</label>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group row">
                                            <label className="col-4"><b>Title</b></label>
                                            <label className="col-8">{Inputs.title}</label>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group row">
                                            <label className="col-4"><b>Booking Date</b></label>
                                            <label className="col-8"><DateFormat value={Inputs.created_at}></DateFormat></label>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group row">
                                            <label className="col-4"><b>Item Description</b></label>
                                            <label className="col-8">{Inputs.item_description}</label>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group row">
                                            <label className="col-4"><b>Budget</b></label>
                                            <label className="col-8">{Inputs.budget != null ? currencies[Inputs.currency] + Inputs.budget : ''} &nbsp;{Inputs.budget != '' && Inputs.budget != null ? '(' + Inputs.currency + ')' : ''}</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Remarks</label>
                                            <textarea rows={5} type="textarea" className="form-control" name="remarks" onChange={handleInputChanges} value={Inputs.remarks} placeholder="Remarks" />
                                            {validator.current.message('remarks', Inputs.remarks, 'required|max:255', { className: 'error' })}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Status</label>
                                            <select className="form-control" name="status" onChange={handleInputChanges} value={Inputs.status}>
                                                <option value="Pending">Pending</option>
                                                <option value="In progress">In progress</option>
                                                <option value="Successful">Successful</option>
                                                <option value="Unsuccessful">Unsuccessful</option>
                                            </select>
                                            {/* {validator.current.message('Status', Inputs.status, 'required', { className: 'error' })} */}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Assign To</label>
                                            <select className="form-control" name="user_id" onChange={handleInputChanges} value={Inputs.user_id}>
                                                <option value="">Select</option>
                                                {
                                                    AppointmentUsers.map((item, index) => (
                                                        <option value={item.id} key={index}>{item.name}</option>
                                                    ))
                                                }
                                            </select>
                                            {/* {validator.current.message('Status', Inputs.status, 'required', { className: 'error' })} */}
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <button type="button" onClick={SubmitForm} className="btn btn-primary mr-2"> Submit </button>
                                        <Link to="/Appointments" className="btn btn-light">Cancel</Link>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default EditAppointment;