import React, { useEffect } from 'react';
import { RouteLinks } from './react'
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Dashboard from "../component/dashboard/Dashboard"
import DiamondShape from '../component/system/diamond_shape/DiamondShape'
import AddDiamondShape from '../component/system/diamond_shape/AddDiamondShape'
import DiamondMarkup from '../component/system/diamond_markup/DiamondMarkup'
import AddDiamondMarkup from '../component/system/diamond_markup/AddDiamondMarkup'
import Metal from '../component/system/metal/Metal'
import AddMetal from '../component/system/metal/AddMetal'
import Form from "../component/form/form"
import MetalQuality from '../component/system/metal_quality/MetalQuality'
import AddMetalQuality from '../component/system/metal_quality/AddMetalQuality'
import DiamondQuality from '../component/system/diamond_quality/DiamondQuality'
import AddDiamondQuality from '../component/system/diamond_quality/AddDiamondQuality'
import Products from '../component/products/Products'
import AddProducts from '../component/products/AddProducts'
import ImportProducts from '../component/products/ImportProducts'
import ProductSize from '../component/system/product_size/ProductSize'
import AddProductSize from '../component/system/product_size/AddProductSize'
import Categories from '../component/category/Categories'
import AddCategory from '../component/category/AddCategory'
import ContentManagement from '../component/contentManagement/ContentManagement'
import CacheManagement from '../component/CacheManagement/CacheManagement'
import AddContentManagement from '../component/contentManagement/AddContentManagement'
import NavigationMenu from '../component/system/navigation_menu/NavigationMenu'
import AddCustomer from '../component/customer/AddCustomer'
import Customers from '../component/customer/Customers'
import CustomerGroup from '../component/system/customer_group/CustomerGroup'
import AddCustomerGroup from '../component/system/customer_group/AddCustomerGroup'
import ProductAttribute from '../component/attribute/Attribute'
import AddProductAttribute from '../component/attribute/AddAttribute'
import ProductAttributeSet from '../component/attribute/AttributeSet'
import AddProductAttributeSet from '../component/attribute/AddAttributeSet'
import Banner from '../component/banner/banner';
import AddBanner from '../component/banner/AddBanner';
import Diamond from '../component/diamond/Diamond';
import Orders from '../component/sales/Orders';
import OrdersDetails from '../component/sales/OrdersDetails';
import Coupons from '../component/coupons/Coupons'
import AddCoupons from '../component/coupons/AddCoupons'
import NewsletterList from '../component/newsletter/Newsletter';
import AddNewsletter from '../component/newsletter/AddNewsletter';
import SubscriberList from '../component/newsletter/Subscribers';
import ContactForm from '../component/ContactForm/ContactForm';
import Reviews from '../component/marketing/reviews/Reviews';
import EditReview from '../component/marketing/reviews/EditReview';
import Instagram from '../component/system/settings/Instagram';
import Settings from '../component/system/settings/Settings';
import AddSettingOption from '../component/system/settings/AddSettingOption';
import AddService from '../component/services/AddService'
import Services from '../component/services/services'
import Appointment from "../component/appointment/appointment";
import EditAppointment from "../component/appointment/editappointment";
import Error from '../component/Error';
import AddCatalogPriceRule from '../component/CatalogPriceRule/AddCatalogPriceRule';
import CatalogPriceRule from '../component/CatalogPriceRule/CatalogPriceRule';
import SystemService from '../component/system/system_service/service';
import BlogCategory from '../component/blog/BlogCategory';
import Blogs from '../component/blog/Blogs';
import AddBlogCategory from '../component/blog/AddBlogCategory';
import AddBlog from '../component/blog/AddBlog';
import LinkStatistics from '../component/report/LinkStatistics';
import Roles from '../component/userManagement/roles/Roles'
import AddRole from '../component/userManagement/roles/AddRole'
import Permissions from '../component/userManagement/permissions/Permissions'
import AddPermission from '../component/userManagement/permissions/AddPermission'
import VirtualRingBuilder from '../component/VirtualRingBuilder/VirtualRingBuilder';
import AddUser from '../component/userManagement/user/AddUser'
import Users from '../component/userManagement/user/Users'
import SearchTerm from '../component/report/SearchTerm';
import AppointmentReport from "../component/appointment/AppointmentReport";
import Dashboard2 from '../component/dashboard/Dashboard2';
import LinkStatisticsByDay from '../component/report/LinkStatisticsByDay';
import Dashboard3 from '../component/dashboard/Dashboard3';
const RouterConfig = () => {
    return (
        <div>
            <Switch >
                <Route exact path="/dashboard" component={Dashboard} ></Route>
                <Route exact path="/dashboard2" component={Dashboard2} ></Route>
                <Route exact path="/dashboard3" component={Dashboard3} ></Route>

                {/*Report  */}
                <Route exact path="/report/linkstatistics/overall" component={LinkStatistics} ></Route>
                <Route exact path="/report/link-statistics" component={LinkStatisticsByDay} ></Route>
                <Route exact path="/report/searchterm" component={SearchTerm} ></Route>

                {/* Diamond Shapes */}
                <Route exact path="/system/diamondshape" component={DiamondShape} ></Route>
                <Route exact path="/system/diamondshape/add" component={AddDiamondShape} ></Route>
                <Route exact path="/system/diamondshape/edit/:id" component={AddDiamondShape} ></Route>

                {/* Diamond Quality */}
                <Route exact path="/system/diamondquality" component={DiamondQuality} ></Route>
                <Route exact path="/system/diamondquality/add" component={AddDiamondQuality} ></Route>
                <Route exact path="/system/diamondquality/edit/:id" component={AddDiamondQuality} ></Route>

                {/* Diamond Markup */}
                <Route exact path="/system/diamondmarkup" component={DiamondMarkup} ></Route>
                <Route exact path="/system/diamondmarkup/add" component={AddDiamondMarkup} ></Route>
                <Route exact path="/system/diamondmarkup/edit/:id" component={AddDiamondMarkup} ></Route>

                {/* Metal */}
                <Route exact path="/system/metal" component={Metal} ></Route>
                <Route exact path="/system/metal/edit/:id" component={AddMetal} ></Route>

                {/* Metal Quality*/}
                <Route exact path="/system/metalquality" component={MetalQuality} ></Route>
                <Route exact path="/system/metalquality/add" component={AddMetalQuality} ></Route>
                <Route exact path="/system/metalquality/edit/:id" component={AddMetalQuality} ></Route>

                {/* Product */}

                <Route exact path="/products/add" component={AddProducts} ></Route>
                <Route exact path="/products/importexcel" component={ImportProducts} ></Route>
                <Route exact path="/products/edit/:id" component={AddProducts} ></Route>
                <Route exact path="/products/:category" component={Products} ></Route>

                {/* Coupons */}
                <Route exact path="/coupons" component={Coupons} ></Route>
                <Route exact path="/coupons/add" component={AddCoupons} ></Route>
                <Route exact path="/coupons/edit/:id" component={AddCoupons} ></Route>

                {/* Order */}
                <Route exact path="/sales/orders" component={Orders} ></Route>
                <Route exact path="/order/view/order_id/:id" component={OrdersDetails} ></Route>

                {/* Product size */}
                <Route exact path="/system/productsize" component={ProductSize} ></Route>
                <Route exact path="/system/productsize/add" component={AddProductSize} ></Route>
                <Route exact path="/system/productsize/edit/:id" component={AddProductSize} ></Route>

                {/* Settings */}
                <Route exact path="/system/settings" component={Settings} ></Route>
                <Route exact path="/system/settings/edit/:id" component={AddSettingOption} ></Route>
                <Route exact path="/system/settings/add" component={AddSettingOption} ></Route>
                <Route exact path="/system/settings/instagram" component={Instagram} ></Route>
                <Route exact path="/system/settings/instagram/auth" component={Instagram} ></Route>

                {/* Category */}
                <Route exact path="/categories" component={Categories} ></Route>
                <Route exact path="/categories/add" component={AddCategory} ></Route>
                <Route exact path="/categories/edit/:id" component={AddCategory} ></Route>

                {/* Content Management */}
                <Route exact path="/contentManagement" component={ContentManagement} ></Route>
                <Route exact path="/contentManagement/add" component={AddContentManagement} ></Route>
                <Route exact path="/contentManagement/edit/:id" component={AddContentManagement} ></Route>

                {/* Cache Management */}
                <Route exact path="/cacheManagement" component={CacheManagement} ></Route>

                {/* Navigation Menu */}
                <Route exact path="/system/navigation-menu" component={NavigationMenu} ></Route>
                <Route exact path="/system/navigation-menu/edit/:id" component={NavigationMenu} ></Route>
                {/* Customer */}
                <Route exact path="/customers" component={Customers} ></Route>
                <Route exact path="/customers/add" component={AddCustomer} ></Route>
                <Route exact path="/customers/edit/:id" component={AddCustomer} ></Route>

                {/* Customer Group */}
                <Route exact path="/system/customer-group" component={CustomerGroup} ></Route>
                <Route exact path="/system/customer-group/add" component={AddCustomerGroup} ></Route>
                <Route exact path="/system/customer-group/edit/:id" component={AddCustomerGroup} ></Route>

                {/* Product Attribute */}
                <Route exact path="/attribute/productattribute" component={ProductAttribute} ></Route>
                <Route exact path="/attribute/productattribute/add" component={AddProductAttribute} ></Route>
                <Route exact path="/attribute/productattribute/edit/:id" component={AddProductAttribute} ></Route>

                {/* Product Attribute Set */}
                <Route exact path="/attribute/attributeset" component={ProductAttributeSet} ></Route>
                <Route exact path="/attribute/attributeset/add" component={AddProductAttributeSet} ></Route>
                <Route exact path="/attribute/attributeset/edit/:id" component={AddProductAttributeSet} ></Route>

                {/* Banner */}
                <Route exact path="/widgets/banner" component={Banner} ></Route>
                <Route exact path="/widgets/banner/add" component={AddBanner} ></Route>
                <Route exact path="/widgets/banner/edit/:id" component={AddBanner} ></Route>

                {/* Newsletter */}
                <Route exact path="/newsletter/list" component={NewsletterList} ></Route>
                <Route exact path="/newsletter/add" component={AddNewsletter} ></Route>
                <Route exact path="/newsletter/subscribers" component={SubscriberList} ></Route>

                {/* Contact Form */}
                <Route exact path="/contact_form" component={ContactForm} ></Route>

                <Route exact path="/diamond" component={Diamond} ></Route>

                {/* Product Reviews */}
                <Route exact path="/marketing/reviews" component={Reviews} ></Route>
                <Route exact path="/marketing/reviews/edit/:id" component={EditReview} ></Route>

                {/* Catalog Price Rule*/}
                <Route exact path="/marketing/catalog-price-rule/add" component={AddCatalogPriceRule} ></Route>
                <Route exact path="/marketing/catalog-price-rule/" component={CatalogPriceRule} ></Route>
                <Route exact path="/marketing/catalog-price-rule/edit/:id" component={AddCatalogPriceRule} ></Route>

                {/*Services  */}
                <Route exact path="/services/addService" component={AddService} ></Route>
                <Route exact path="/services" component={Services} ></Route>
                <Route exact path="/services/edit/:id" component={AddService} ></Route>

                <Route exact path={RouteLinks.Appointment} component={Appointment} ></Route>
                <Route exact path={RouteLinks.Edit_Appointment} component={EditAppointment} ></Route>
                <Route exact path={RouteLinks.AppointmentReport} component={AppointmentReport} ></Route>

                <Route exact path="/system/service" component={SystemService} ></Route>

                {/* Blog */}
                <Route exact path="/blog/blogcategory" component={BlogCategory} ></Route>
                <Route exact path="/blog/blogs" component={Blogs} ></Route>
                <Route exact path="/blog/blogcategory/edit/:id" component={AddBlogCategory} ></Route>

                <Route exact path="/blog/Addblogcategory" component={AddBlogCategory} ></Route>
                <Route exact path="/blog/Addblog" component={AddBlog} ></Route>
                <Route exact path="/blog/Addblog/edit/:id" component={AddBlog} ></Route>

                {/* User Management */}
                <Route exact path="/role/add" component={AddRole} ></Route>
                <Route exact path="/roles" component={Roles} ></Route>
                <Route exact path="/role/edit/:id" component={AddRole} ></Route>
                <Route exact path="/users/add" component={AddUser} ></Route>
                <Route exact path="/users" component={Users} ></Route>
                <Route exact path="/users/edit/:id" component={AddUser} ></Route>

                <Route exact path="/permission/add" component={AddPermission} ></Route>
                <Route exact path="/permissions" component={Permissions} ></Route>
                <Route exact path="/permission/edit/:id" component={AddPermission} ></Route>
                {/* Virtual Ring Builder */}
                <Route exact path="/virtual-ring-builder" component={VirtualRingBuilder} ></Route>

                <Route exact path="/form" component={Form} ></Route>
                <Route exact path="/error" component={Error} ></Route>
                <Route exact path="/" component={Dashboard} ></Route>
                <Route exact path="" component={Dashboard} ></Route>
                {/* <Route exact path="/" component={Dashboard3} ></Route>
                <Route exact path="" component={Dashboard3} ></Route> */}



            </Switch >
        </div >
    );
};

export default RouterConfig;
