import React, { useState, useEffect } from 'react';
// import Logo from '/assests/images/logo-dark.png'
//import UserProfile from '/assests/images/faces/face1.jpg'
import { Link, useLocation } from 'react-router-dom';
import { S3_BUCKET_URL } from '../../config/HttpService';
const Navigation = () => {
  const path = useLocation();
  let parameters = new URLSearchParams(path.search);
  const [isActive, setActive] = useState('');
  const [isForceUpdate, setForceUpdate] = useState(false);
  const SalesSubMenu = ["Orders", "Invoices", "CreditMemos"]
  const SystemSubMenu = ["DiamondShape", "DiamondQuality", "Metal", "MetalQuality", "ProductSize", "NavigationMenu", "CustomerGroup"]
  const AttributeSubMenu = ["ProductAttribute", "AttributeSet"]
  const ServiceSubMenu = ["Services", "AttributeSet"]
  const WidgetsSubMenu = ["BannerAttribute"]
  const MarketingSubMenu = ["Reviews"]
  const Permissions = JSON.parse(localStorage.getItem('PermissionList'));
  const UserPermissions = JSON.parse(localStorage.appState).permission == null ? [] : JSON.parse(localStorage.appState).permission;
  let type = parameters.get('type');
  useEffect(() => {
    // alert(isActive)
    setActive(path.pathname);
  }, [path]);

  const toggleClass = (e) => {
    //setActive(e.target.name);
    setForceUpdate(!isForceUpdate);
  };
  const validatePermission = (_key) => {
    //return UserPermissions.map(x=>x.name).includes(Permissions.filter(x=>x.key==key)[0].name)
    if ((UserPermissions == null || UserPermissions.length == 0) || (Permissions == null || Permissions.length == 0)) {
      localStorage.removeItem('appState');
      window.location.href = "/login";
    }
    if (UserPermissions.map(x => x.name).includes(Permissions.filter(x => x.key == 'all-master').length > 0 ? Permissions.filter(x => x.key == 'all-master')[0].name : null)) {
      return true;
    }
    return UserPermissions.map(x => x.name).includes(Permissions.filter(x => x.key == _key).length > 0 ? Permissions.filter(x => x.key == _key)[0].name : null)
  };


  return (
    < nav className="sidebar sidebar-offcanvas" id="sidebar" >
      <ul className="nav accordion" id="accordionMenu">
        <li className="nav-item">
          <Link className="nav-link text-center py-0 bg-transparent d-block" to="/">
            <img className="sidebar-brand-logo" src={S3_BUCKET_URL + 'assests/images/logo-dark.png'} alt="logo" />
            <img className="sidebar-brand-logomini" src={S3_BUCKET_URL + 'assests/images/logo-dark.png'} alt="logo" />
          </Link>
        </li>
        <li className="nav-item nav-profile border-top">
          <a href="#" className="nav-link d-flex justify-content-center bg-transparent mb-3">
            <div className="nav-profile-image">
              <img src={S3_BUCKET_URL + 'assests/images/faces/face1.jpg'} alt="profile" />

            </div>
            <div className="nav-profile-text d-flex ml-0 mb-3 flex-column">
              <span className="font-weight-semibold mb-1 mt-2 text-center">Antonio Olson</span>
            </div>
          </a>
        </li>

        <li name="Dashboard" className={'nav-item ' + ((isActive == '/') ? 'active' : '')} onClick={toggleClass} >
          <Link className="nav-link" name="Dashboard" to="/">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#3e4b5b" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trello"><rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect><rect x="7" y="7" width="3" height="9"></rect><rect x="14" y="7" width="3" height="5"></rect></svg>
            <span className="menu-title">Dashboard</span>
          </Link>
        </li>
        {validatePermission('sales') ?
          <li className={'nav-item ' + ((isActive == '/sales/orders' || isActive == '/sales/invoices') ? 'active' : '')}  >
            <a className="nav-link" data-toggle="collapse" data-target="#sales">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#3e4b5b" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-dollar-sign"><line x1="12" y1="1" x2="12" y2="23"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg>
              <span className="menu-title">Sales</span><i className="menu-arrow"></i>
            </a>
            <div className={'collapse ' + ((isActive == '/sales/orders' || isActive == '/sales/invoices') ? 'show' : '')} id="sales" data-parent="#accordionMenu">
              <ul className="nav flex-column sub-menu">
                <li className={'nav-item ' + ((isActive == '/sales/orders') ? 'active' : '')} onClick={toggleClass} >
                  <Link name="Orders" className="nav-link" to='/sales/orders'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#3e4b5b" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-book"><path d="M4 19.5A2.5 2.5 0 0 1 6.5 17H20"></path><path d="M6.5 2H20v20H6.5A2.5 2.5 0 0 1 4 19.5v-15A2.5 2.5 0 0 1 6.5 2z"></path></svg>
                    Orders
                  </Link>
                </li>
                <li className={'nav-item ' + ((isActive == '/sales/invoices') ? 'active' : '')} onClick={toggleClass} >
                  <a name="Invoices" className="nav-link" href="#">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#3e4b5b" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-file-text"><path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path><polyline points="14 2 14 8 20 8"></polyline><line x1="16" y1="13" x2="8" y2="13"></line><line x1="16" y1="17" x2="8" y2="17"></line><polyline points="10 9 9 9 8 9"></polyline></svg>
                    Invoices
                  </a>
                </li>
                <li className={'nav-item ' + ((isActive == '/sales/creditmemo') ? 'active' : '')} onClick={toggleClass} >
                  <a name="CreditMemos" className="nav-link" href="#">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#3e4b5b" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-file"><path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path><polyline points="13 2 13 9 20 9"></polyline></svg>
                    Credit Memos
                  </a>
                </li>
              </ul>
            </div>
          </li> : null}
        {validatePermission('products') ?
          <li className={'nav-item ' + (isActive == '/products/ring' ? 'active' : '')} onClick={toggleClass} >
            <Link name="Products" className="nav-link" to="/products/ring">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#3e4b5b" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-package"><line x1="16.5" y1="9.4" x2="7.5" y2="4.21"></line><path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path><polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline><line x1="12" y1="22.08" x2="12" y2="12"></line></svg>
              <span className="menu-title">Products</span>
            </Link>
          </li>
          : null
        }
        {validatePermission('earrings') ?
          <li className={'nav-item ' + (isActive == '/products/earring' ? 'active' : '')} onClick={toggleClass} >
            <Link name="Earrings" className="nav-link" to="/products/earring">
              <img src={S3_BUCKET_URL + 'assests/images/menu-icon/menu-earrings.svg'} width="20" className='opacity-4' />
              <span className="menu-title">Earrings</span>
            </Link>
          </li> : null
        }
        {validatePermission('diamonds') ?
          <li className={'nav-item ' + (isActive == '/diamond' && type == null ? 'active' : '')} onClick={toggleClass} >
            <Link name="Diamond" className="nav-link" to="/diamond">
              <img src={S3_BUCKET_URL + 'assests/images/menu-icon/menu-diamond.svg'} width="20" className='opacity-4' />
              <span className="menu-title">Diamonds</span>
            </Link>
          </li> : null}
        {validatePermission('gemstones') ?
          <li className={'nav-item ' + (isActive == '/diamond' && type == 'gemstone' ? 'active' : '')} onClick={toggleClass} >
            <Link name="Diamond" className="nav-link" to="/diamond?type=gemstone">
              <img src={S3_BUCKET_URL + 'assests/images/menu-icon/menu-gemstone.svg'} width="18" />
              <span className="menu-title">Gemstones</span>
            </Link>
          </li> : null}
        {validatePermission('categories') ?
          <li className={'nav-item ' + (isActive == '/categories' ? 'active' : '')} onClick={toggleClass}>
            <Link name="Categories" className="nav-link" to="/categories" >
              <img src={S3_BUCKET_URL + 'assests/images/menu-icon/menu-category.svg'} width="20" />
              <span className="menu-title">Categories</span>
            </Link>
          </li>
          : null}
        {validatePermission('customers') ?
          <li className={'nav-item ' + (isActive == '/customers' ? 'active' : '')} onClick={toggleClass}>
            <Link name="Customers" className="nav-link" to="/customers">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#3e4b5b" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-users"><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg>
              <span className="menu-title">Customers</span>
            </Link>
          </li> : null}


        {/* <li className={'nav-item ' + (isActive == '/blog/blogs' ? 'active' : '')} onClick={toggleClass}>
          <Link name="Blogs" className="nav-link" to="/blog/blogs" >
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#3e4b5b" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-box"><path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path><polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline><line x1="12" y1="22.08" x2="12" y2="12"></line></svg>
            <span className="menu-title">Blogs</span>
          </Link>
        </li> */}
        {/* <li className="nav-item">
          <a className="nav-link" href="#">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#3e4b5b" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-archive"><polyline points="21 8 21 21 3 21 3 8"></polyline><rect x="1" y="3" width="22" height="5"></rect><line x1="10" y1="12" x2="14" y2="12"></line></svg>
            <span className="menu-title">Stores</span>
          </a>
        </li> */}


        {/* widgets END */}
        {validatePermission('book_an_appointment') ?
          <li className={'nav-item ' + ((isActive == '/services' || isActive == '/appointments' || isActive == '/appointments/report') ? 'active' : '')}>
            <a className="nav-link" data-toggle="collapse" data-target="#services">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#3e4b5b" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
              <span className="menu-title">Book An Appointment</span><i className="menu-arrow"></i>
            </a>
            <div className={'collapse ' + ((isActive == '/services' || isActive == '/appointments') ? 'show' : '')} id="services" data-parent="#accordionMenu">
              <ul className="nav flex-column sub-menu">
                <li className={'nav-item ' + (isActive == '/services' ? 'active' : '')} onClick={toggleClass}>
                  <Link name="Service" className="nav-link" to="/services">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#3e4b5b" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-settings"><circle cx="12" cy="12" r="3"></circle><path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path></svg>
                    Services
                  </Link>
                </li>
                <li className={'nav-item ' + (isActive == '/appointments' ? 'active' : '')} onClick={toggleClass}>
                  <Link name="appointment" className="nav-link" to="/appointments">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#3e4b5b" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                    Appointments
                  </Link>
                </li>
                <li className={'nav-item ' + (isActive == '/appointments/report' ? 'active' : '')} onClick={toggleClass}>
                  <Link name="appointment_report" className="nav-link" to="/appointments/report">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#3e4b5b" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-clipboard"><path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path><rect x="8" y="2" width="8" height="4" rx="1" ry="1"></rect></svg>
                    Report
                  </Link>
                </li>
              </ul>
            </div>
          </li> : null}
        {validatePermission('content_management') ?
          <li className={'nav-item ' + (isActive == '/contentManagement' ? 'active' : '')} onClick={toggleClass}>
            <Link name="ContentManagement" className="nav-link" to="/contentManagement">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#3e4b5b" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-file-text"><path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path><polyline points="14 2 14 8 20 8"></polyline><line x1="16" y1="13" x2="8" y2="13"></line><line x1="16" y1="17" x2="8" y2="17"></line><polyline points="10 9 9 9 8 9"></polyline></svg>
              <span className="menu-title">Content Management</span>
            </Link>
          </li> : null
        }
        {validatePermission('cache_management') ?
          <li className={'nav-item ' + (isActive == '/cacheManagement' ? 'active' : '')} onClick={toggleClass}>
            <Link name="CacheManagement" className="nav-link" to="/cacheManagement">
              <img src={S3_BUCKET_URL + 'assests/images/menu-icon/menu-catch-mgt.svg'} width="22" />
              <span className="menu-title">Cache Management</span>
            </Link>
          </li> : null}
        {/* widgets start */}
        {validatePermission('widgets') ?
          <li className={'nav-item ' + (isActive == '/widgets/banner' ? 'active' : '')}>
            <a className="nav-link" data-toggle="collapse" data-target="#banner">
              <img src={S3_BUCKET_URL + 'assests/images/menu-icon/menu-attribute.svg'} width="20" className='opacity-4' />
              {/* <img src={S3_BUCKET_URL + 'assests/images/menu-icon/menu-widget.svg'} width="18" /> */}
              <span className="menu-title">Widgets</span><i className="menu-arrow"></i>
            </a>
            <div className={'collapse ' + (isActive == '/widgets/banner' ? 'show' : '')} id="banner" data-parent="#accordionMenu">
              <ul className="nav flex-column sub-menu">
                <li className={'nav-item ' + (isActive == '/widgets/banner' ? 'active' : '')} onClick={toggleClass}>
                  <Link name="BannerAttribute" className="nav-link" to="/widgets/banner">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#3e4b5b" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-image"><rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect><circle cx="8.5" cy="8.5" r="1.5"></circle><polyline points="21 15 16 10 5 21"></polyline></svg>
                    Banner
                  </Link>
                </li>
              </ul>
            </div>
          </li> : null}

        {/* widgets END */}
        {validatePermission('widgets') ?
          <li className={'nav-item ' + ((isActive == '/attribute/productattribute' || isActive == '/attribute/attributeset') ? 'active' : '')}>
            <a className="nav-link" data-toggle="collapse" data-target="#attribute">
              <img src={S3_BUCKET_URL + 'assests/images/menu-icon/menu-attribute.svg'} width="20" className='opacity-4' />
              <span className="menu-title">Attribute</span><i className="menu-arrow"></i>
            </a>
            <div className={'collapse ' + ((isActive == '/attribute/productattribute' || isActive == '/attribute/attributeset') ? 'show' : '')} id="attribute" data-parent="#accordionMenu">
              <ul className="nav flex-column sub-menu">
                <li className={'nav-item ' + (isActive == '/attribute/productattribute' ? 'active' : '')} onClick={toggleClass}>
                  <Link name="ProductAttribute" className="nav-link" to="/attribute/productattribute">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#3e4b5b" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-package"><line x1="16.5" y1="9.4" x2="7.5" y2="4.21"></line><path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path><polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline><line x1="12" y1="22.08" x2="12" y2="12"></line></svg>
                    Product
                  </Link>
                </li>
                <li className={'nav-item ' + (isActive == '/attribute/attributeset' ? 'active' : '')} onClick={toggleClass}>
                  <Link name="AttributeSet" className="nav-link" to="/attribute/attributeset">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#3e4b5b" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-package"><line x1="16.5" y1="9.4" x2="7.5" y2="4.21"></line><path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path><polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline><line x1="12" y1="22.08" x2="12" y2="12"></line></svg>
                    Attribute Set
                  </Link>
                </li>
              </ul>
            </div>
          </li> : null}
        {validatePermission('report') ?
          <li className={'nav-item ' + ((isActive == '/report/link-statistics' || isActive == '/report/searchterm') ? 'active' : '')}>
            <a className="nav-link" data-toggle="collapse" data-target="#report">              
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#3e4b5b" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-activity"><polyline points="22 12 18 12 15 21 9 3 6 12 2 12"></polyline></svg>
              <span className="menu-title">Report</span><i className="menu-arrow"></i>
            </a>
            <div className="collapse" id="report" data-parent="#accordionMenu">
              <ul className="nav flex-column sub-menu">

                <li className={'nav-item ' + (isActive == 'SearchTerms' ? 'active' : '')} onClick={toggleClass} >
                  <Link name="SearchTerms" className="nav-link" to="/report/searchterm">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#3e4b5b" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-book-open"><path d="M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2z"></path><path d="M22 3h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7z"></path></svg>
                    Search Terms
                  </Link>
                </li>

                <li className={'nav-item ' + (isActive == '/report/link-statistics' ? 'active' : '')} onClick={toggleClass} >
                  <Link name="Link Statistics" className="nav-link" to="/report/link-statistics">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#3e4b5b" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-package"><line x1="16.5" y1="9.4" x2="7.5" y2="4.21"></line><path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path><polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline><line x1="12" y1="22.08" x2="12" y2="12"></line></svg>
                    Link Statistics
                  </Link>
                </li>
                {/* <li className={'nav-item ' + (isActive == 'AbandonedCarts' ? 'active' : '')} onClick={toggleClass} >
                <a name="AbandonedCarts" className="nav-link" href="#">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#3e4b5b" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-shopping-bag"><path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"></path><line x1="3" y1="6" x2="21" y2="6"></line><path d="M16 10a4 4 0 0 1-8 0"></path></svg>
                  Abandoned Carts
                </a>
              </li>
              <li className={'nav-item ' + (isActive == 'ProductsinCart' ? 'active' : '')} onClick={toggleClass} >
                <a name="ProductsinCart" className="nav-link" href="#">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#3e4b5b" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-shopping-cart"><circle cx="9" cy="21" r="1"></circle><circle cx="20" cy="21" r="1"></circle><path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path></svg>
                  Products in Cart
                </a>
              </li> */}
              </ul>
            </div>
          </li> : null}
        {validatePermission('blogs') ? <li className={'nav-item ' + ((isActive == '/blog/blogcategory' || isActive == '/blog/blogs') ? 'active' : '')}>
          <a className="nav-link" data-toggle="collapse" data-target="#blog">
            <img src={S3_BUCKET_URL + 'assests/images/menu-icon/menu-blog.svg'} width="20" className='opacity-4' />
            <span className="menu-title">Blog</span><i className="menu-arrow"></i>
          </a>
          <div className={'collapse ' + ((isActive == '/blog/blogcategory' || isActive == '/blog/blogs') ? 'show' : '')} id="blog" data-parent="#accordionMenu">
            <ul className="nav flex-column sub-menu">
              <li className={'nav-item ' + (isActive == '/blog/blogcategory' ? 'active' : '')} onClick={toggleClass} >
                <Link name="Blog Category" className="nav-link" to="/blog/blogcategory">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#3e4b5b" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-bookmark"><path d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z"></path></svg>
                  Blog Category
                </Link>
              </li>
              <li className={'nav-item ' + (isActive == '/blog/blogs' ? 'active' : '')} onClick={toggleClass} >
                <Link name="Subscriber" className="nav-link" to="/blog/blogs">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#3e4b5b" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-tag"><path d="M20.59 13.41l-7.17 7.17a2 2 0 0 1-2.83 0L2 12V2h10l8.59 8.59a2 2 0 0 1 0 2.82z"></path><line x1="7" y1="7" x2="7.01" y2="7"></line></svg>
                  Blogs
                </Link>
              </li>
            </ul>
          </div>
        </li> : null}
        {validatePermission('newsletter') ?
          <li className={'nav-item ' + ((isActive == '/newsletter/list' || isActive == '/newsletter/subscribers') ? 'active' : '')}>
            <a className="nav-link" data-toggle="collapse" data-target="#newsletter">
              <img src={S3_BUCKET_URL + 'assests/images/menu-icon/menu-newsletter.svg'} width="24" className='opacity-4' />
              <span className="menu-title">Newsletter </span><i className="menu-arrow"></i>
            </a>
            <div className={'collapse ' + ((isActive == '/newsletter/list' || isActive == '/newsletter/subscribers') ? 'show' : '')} id="newsletter" data-parent="#accordionMenu">
              <ul className="nav flex-column sub-menu">
                <li className={'nav-item ' + (isActive == '/newsletter/list' ? 'active' : '')} onClick={toggleClass} >
                  <Link name="Newsletter" className="nav-link" to="/newsletter/list">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#3e4b5b" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-mail"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg>
                    Newsletter
                  </Link>
                </li>
                <li className={'nav-item ' + (isActive == '/newsletter/subscribers' ? 'active' : '')} onClick={toggleClass} >
                  <Link name="Subscriber" className="nav-link" to="/newsletter/subscribers">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#3e4b5b" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-tag"><path d="M20.59 13.41l-7.17 7.17a2 2 0 0 1-2.83 0L2 12V2h10l8.59 8.59a2 2 0 0 1 0 2.82z"></path><line x1="7" y1="7" x2="7.01" y2="7"></line></svg>
                    Subscribers List
                  </Link>
                </li>
              </ul>
            </div>
          </li> : null}
        {validatePermission('contact_form') ?
          <li className={'nav-item ' + (isActive == '/contact_form' ? 'active' : '')} onClick={toggleClass}>
            <Link name="Contact-Form" className="nav-link" to="/contact_form">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#3e4b5b" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-phone"><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg>
              <span className="menu-title">Contact Us</span>
            </Link>
          </li> : null}
        {validatePermission('coupons') ?
          <li className={'nav-item ' + (isActive == '/coupons' ? 'active' : '')} onClick={toggleClass} >
            <Link name="Coupons" className="nav-link" to="/coupons">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#3e4b5b" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-tag"><path d="M20.59 13.41l-7.17 7.17a2 2 0 0 1-2.83 0L2 12V2h10l8.59 8.59a2 2 0 0 1 0 2.82z"></path><line x1="7" y1="7" x2="7.01" y2="7"></line></svg>
              <span className="menu-title">Coupons</span>
            </Link>
          </li> : null}
        {validatePermission('marketing') ?
          <li className={'nav-item ' + (isActive == '/marketing/catalog-price-rule' ? 'active' : '')}>
            <a className="nav-link" data-toggle="collapse" data-target="#marketing">  
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#3e4b5b" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-percent"><line x1="19" y1="5" x2="5" y2="19"></line><circle cx="6.5" cy="6.5" r="2.5"></circle><circle cx="17.5" cy="17.5" r="2.5"></circle></svg>
            <span className="menu-title">Marketing </span><i className="menu-arrow"></i>
            </a>
            <div className={'collapse ' + (isActive == '/marketing/catalog-price-rule' ? 'show' : '')} id="marketing" data-parent="#accordionMenu">
              <ul className="nav flex-column sub-menu">
                <li className={'nav-item ' + (isActive == '/marketing/catalog-price-rule' ? 'active' : '')} onClick={toggleClass} >
                  <Link name="CatelogPriceRule" className="nav-link" to="/marketing/catalog-price-rule">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#3e4b5b" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-bookmark"><path d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z"></path></svg>
                    Catalog Price Rule
                  </Link>
                </li>
                <li className={'nav-item ' + (isActive == 'CartPriceRule' ? 'active' : '')} onClick={toggleClass} >
                  <a name="CartPriceRule" className="nav-link" href="#">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#3e4b5b" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-tag"><path d="M20.59 13.41l-7.17 7.17a2 2 0 0 1-2.83 0L2 12V2h10l8.59 8.59a2 2 0 0 1 0 2.82z"></path><line x1="7" y1="7" x2="7.01" y2="7"></line></svg>
                    Cart Price Rule
                  </a>
                </li>
                <li className={'nav-item ' + (isActive == '/marketing/reviews' ? 'active' : '')} onClick={toggleClass} >
                <Link name="Reviews" className="nav-link" to="/marketing/reviews">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#3e4b5b" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-package"><line x1="16.5" y1="9.4" x2="7.5" y2="4.21"></line><path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path><polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline><line x1="12" y1="22.08" x2="12" y2="12"></line></svg>
                  Reviews
                </Link>
              </li>
              </ul>
            </div>
          </li> : null}

        {/* {validatePermission('role_permission')? */}
        {validatePermission('role_permission') ?
          <li className={'nav-item ' + ((isActive == '/roles' || isActive == '/users' || isActive == '/permissions') ? 'active' : '')}>
            <a className="nav-link" data-toggle="collapse" data-target="#user-management">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#3e4b5b" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
              <span className="menu-title">User Management </span><i className="menu-arrow"></i>
            </a>
            <div className={'collapse ' + ((isActive == '/roles' || isActive == '/users' || isActive == '/permissions') ? 'show' : '')} id="user-management" data-parent="#accordionMenu">
              <ul className="nav flex-column sub-menu">
                <li className={'nav-item ' + (isActive == '/roles' ? 'active' : '')} onClick={toggleClass} >
                  <Link name="Roles" className="nav-link" to="/roles">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#3e4b5b" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-bookmark"><path d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z"></path></svg>
                    Roles
                  </Link>
                </li>
                <li className={'nav-item ' + (isActive == '/users' ? 'active' : '')} onClick={toggleClass} >
                  <Link name="Users" className="nav-link" to="/users">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#3e4b5b" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-tag"><path d="M20.59 13.41l-7.17 7.17a2 2 0 0 1-2.83 0L2 12V2h10l8.59 8.59a2 2 0 0 1 0 2.82z"></path><line x1="7" y1="7" x2="7.01" y2="7"></line></svg>
                    Users
                  </Link>
                </li>
                <li className={'nav-item ' + (isActive == '/permissions' ? 'active' : '')} onClick={toggleClass} >
                  <Link name="Permissions" className="nav-link" to="/permissions">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#3e4b5b" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-tag"><path d="M20.59 13.41l-7.17 7.17a2 2 0 0 1-2.83 0L2 12V2h10l8.59 8.59a2 2 0 0 1 0 2.82z"></path><line x1="7" y1="7" x2="7.01" y2="7"></line></svg>
                    Permissions
                  </Link>
                </li>
              </ul>
            </div>
          </li> : null}
        {validatePermission('virtual_Ring_builder_request') ?
          <li className={'nav-item ' + (isActive == '/virtual-ring-builder' ? 'active' : '')} onClick={toggleClass}>
            <Link name="VirtualRingRequest" className="nav-link" to="/virtual-ring-builder">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#3e4b5b" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-package"><line x1="16.5" y1="9.4" x2="7.5" y2="4.21"></line><path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path><polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline><line x1="12" y1="22.08" x2="12" y2="12"></line></svg>
              <span className="menu-title">Virtual Ring Builder Request</span>
            </Link>
          </li> : null}
        {validatePermission('System') ?
          <li className={'nav-item ' + ((isActive == '/system/diamondshape' || isActive == '/system/diamondquality' || isActive == '/system/diamondmarkup' || isActive == '/system/metal' || isActive == '/system/metalquality' || isActive == '/system/productsize' || isActive == '/system/navigation-menu' || isActive == '/system/customer-group' || isActive == '/system/settings' || isActive == '/system/settings/instagram') ? 'active' : '')}>
            <a className="nav-link" data-toggle="collapse" data-target="#system">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#3e4b5b" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-settings"><circle cx="12" cy="12" r="3"></circle><path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path></svg>
              <span className="menu-title">System</span><i className="menu-arrow"></i>
            </a>
            <div className={'collapse ' + ((isActive == '/system/diamondshape' || isActive == '/system/diamondquality' || isActive == '/system/diamondmarkup' || isActive == '/system/metal' || isActive == '/system/metalquality' || isActive == '/system/productsize' || isActive == '/system/navigation-menu' || isActive == '/system/customer-group' || isActive == '/system/settings' || isActive == '/system/settings/instagram') ? 'show' : '')} id="system" data-parent="#accordionMenu">
              <ul className="nav flex-column sub-menu">
                <li className={'nav-item ' + (isActive == '/system/diamondshape' ? 'active' : '')} onClick={toggleClass}>
                  <Link name="DiamondShape" className="nav-link" to="/system/diamondshape">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#3e4b5b" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-package"><line x1="16.5" y1="9.4" x2="7.5" y2="4.21"></line><path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path><polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline><line x1="12" y1="22.08" x2="12" y2="12"></line></svg>
                    Diamond Shape
                  </Link>
                </li>
                <li className={'nav-item ' + (isActive == '/system/diamondquality' ? 'active' : '')} onClick={toggleClass}>
                  <Link name="DiamondQuality" className="nav-link" to="/system/diamondquality">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#3e4b5b" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-package"><line x1="16.5" y1="9.4" x2="7.5" y2="4.21"></line><path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path><polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline><line x1="12" y1="22.08" x2="12" y2="12"></line></svg>
                    Diamond Quality
                  </Link>
                </li>
                <li className={'nav-item ' + (isActive == '/system/diamondmarkup' ? 'active' : '')} onClick={toggleClass}>
                  <Link name="DiamondMarkup" className="nav-link" to="/system/diamondmarkup">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#3e4b5b" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-package"><line x1="16.5" y1="9.4" x2="7.5" y2="4.21"></line><path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path><polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline><line x1="12" y1="22.08" x2="12" y2="12"></line></svg>
                    Diamond Markup
                  </Link>
                </li>
                <li className={'nav-item ' + (isActive == '/system/metal' ? 'active' : '')} onClick={toggleClass}>
                  <Link name="Metal" className="nav-link" to="/system/metal">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#3e4b5b" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-package"><line x1="16.5" y1="9.4" x2="7.5" y2="4.21"></line><path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path><polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline><line x1="12" y1="22.08" x2="12" y2="12"></line></svg>
                    Metal Type
                  </Link>
                </li>
                <li className={'nav-item ' + (isActive == '/system/metalquality' ? 'active' : '')} onClick={toggleClass}>
                  <Link name="MetalQuality" className="nav-link" to="/system/metalquality">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#3e4b5b" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-package"><line x1="16.5" y1="9.4" x2="7.5" y2="4.21"></line><path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path><polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline><line x1="12" y1="22.08" x2="12" y2="12"></line></svg>
                    Metal Quality
                  </Link>
                </li>
                <li className={'nav-item ' + (isActive == '/system/productsize' ? 'active' : '')} onClick={toggleClass}>
                  <Link name="ProductSize" className="nav-link" to="/system/productsize">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#3e4b5b" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-package"><line x1="16.5" y1="9.4" x2="7.5" y2="4.21"></line><path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path><polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline><line x1="12" y1="22.08" x2="12" y2="12"></line></svg>
                    Product Size
                  </Link>
                </li>
                <li className={'nav-item ' + (isActive == '/system/navigation-menu' ? 'active' : '')} onClick={toggleClass}>
                  <Link name="NavigationMenu" className="nav-link" to="/system/navigation-menu">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#3e4b5b" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-package"><line x1="16.5" y1="9.4" x2="7.5" y2="4.21"></line><path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path><polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline><line x1="12" y1="22.08" x2="12" y2="12"></line></svg>
                    Navigation Menu
                  </Link>
                </li>
                <li className={'nav-item ' + (isActive == '/system/customer-group' ? 'active' : '')} onClick={toggleClass}>
                  <Link name="CustomerGroup" className="nav-link" to="/system/customer-group">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#3e4b5b" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-package"><line x1="16.5" y1="9.4" x2="7.5" y2="4.21"></line><path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path><polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline><line x1="12" y1="22.08" x2="12" y2="12"></line></svg>
                    Customer Group
                  </Link>
                </li>
                <li className={'nav-item ' + (isActive == '/system/settings' ? 'active' : '')} onClick={toggleClass}>
                  <Link name="Settings" className="nav-link" to="/system/settings">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#3e4b5b" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-package"><line x1="16.5" y1="9.4" x2="7.5" y2="4.21"></line><path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path><polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline><line x1="12" y1="22.08" x2="12" y2="12"></line></svg>
                    Settings
                  </Link>
                </li>
                <li className={'nav-item ' + (isActive == '/system/settings/instagram' ? 'active' : '')} onClick={toggleClass}>
                  <Link name="Instagram" className="nav-link" to="/system/settings/instagram">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#3e4b5b" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-package"><line x1="16.5" y1="9.4" x2="7.5" y2="4.21"></line><path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path><polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline><line x1="12" y1="22.08" x2="12" y2="12"></line></svg>
                    Instagram
                  </Link>
                </li>
                <li className={'nav-item ' + (isActive == '/system/service' ? 'active' : '')} onClick={toggleClass}>
                  <Link name="Services" className="nav-link" to="/system/service">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#3e4b5b" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-package"><line x1="16.5" y1="9.4" x2="7.5" y2="4.21"></line><path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path><polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline><line x1="12" y1="22.08" x2="12" y2="12"></line></svg>
                    Services
                  </Link>
                </li>
              </ul>
            </div>
          </li> : null}
      </ul>
    </nav >
  );
};

export default Navigation;
