import React, { useEffect, useState } from "react";
import * as Title from "../../constants/PageTitle";
import { HttpService, API } from "../../config/react";
import { Link } from "react-router-dom";
const Dashboard = () => {
  const [DashboardCounts, setDashboardCounts] = useState([]);
  const [BestSellerProduct, setBestSellerProduct] = useState([]);
  const [lastOrderProducts, setlastOrderProduct] = useState([]);
  useEffect(() => {
    document.title = Title.Dashboard;
    GetCounts();
    GetBestSellingProduct();
    GetlastOrderProduct();
  }, []);

  const GetCounts = () => {
    HttpService.getApi(API.DashboardCounts, null).then((response) => {
      setDashboardCounts(response.data.result[0][0]);
    });
  };
  const GetBestSellingProduct = () => {
    HttpService.getApi(API.BestSellerProduct, null).then((response) => {
      setBestSellerProduct(response.data.result);
    });
  };
  const GetlastOrderProduct = () => {
    HttpService.getApi(API.lastorderProducts, null).then((response) => {
      setlastOrderProduct(response.data.result);
    });
  };
  return (
    <div className="content-wrapper pb-0">
      {/* <div className="alert alert-warning alert-dismissible fade show" role="alert">
                You should check in on some of those fields below. <strong>Holy guacamole!</strong>
                <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div className="alert alert-success alert-dismissible fade show" role="alert">
                You should check in on some of those fields below. <strong>Holy guacamole!</strong>
                <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div className="alert alert-danger alert-dismissible fade show" role="alert">
                You should check in on some of those fields below. <strong>Holy guacamole!</strong>
                <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div> */}

      <div className="row">
        <div className="col-sm-6  col-lg-3 stretch-card grid-margin">
          <div className="card">
            <div className="card-body p-3">
              <div className="d-flex border-bottom align-items-center justify-content-between">
                <div className="card-title text-gray font-13 mb-0 font-weight-normal">
                  Online Orders
                </div>
                <div className="d-flex text-muted font-20">
                  <select className="form-control select-custom outline-0">
                    <option>Monthly</option>
                    <option>Yearly</option>
                  </select>
                </div>
              </div>
              <h2 className="font-weight-bold mb-3 pt-3 d-flex align-items-center justify-content-center text-info">
                {" "}
                {DashboardCounts.total_orders}
                {/* <span className="border-curcle badge-pill badge badge-success ml-2">4,5%<i className="mdi mdi-arrow-up"></i></span> */}
              </h2>
              <div className="line-chart-wrapper d-none">
                <canvas id="linechart" height="80"></canvas>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-lg-3 stretch-card grid-margin">
          <div className="card">
            <div className="card-body p-3">
              <div className="d-flex border-bottom align-items-center justify-content-between">
                <div className="card-title py-2 text-gray font-13 mb-0 font-weight-semibold">
                  Total Diamonds
                </div>
                <div className="d-none text-muted font-20">
                  <select className="form-control select-custom outline-0">
                    <option>This Monthly</option>
                    <option>Yearly</option>
                  </select>
                </div>
              </div>
              <div className="total-diamond-part text-center pt-3">
                <div className="pt-2 card-title text-gray font-13 mb-1 font-weight-semibold">
                  {" "}
                  Total Natural Diamonds
                </div>
                <h2 className=" font-weight-bold mb-3 d-flex justify-content-center align-items-center text-success">
                  {DashboardCounts.total_earth_mined_diamonds}
                </h2>
              </div>
              <div className="total-diamond-part text-center">
                <div className="pt-2 card-title text-gray font-13 mb-1 font-weight-semibold">
                  {" "}
                  Total LAB Diamonds
                </div>
                <h2 className=" font-weight-bold mb-0 d-flex justify-content-center align-items-center text-dark">
                  {DashboardCounts.total_lab_grown_diamonds}
                </h2>
              </div>

              <div className="bar-chart-wrapper d-none">
                <canvas id="barchart" height="80"></canvas>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-6  col-lg-3 stretch-card grid-margin">
          <div className="card">
            <div className="card-body p-3">
              <div className="d-flex border-bottom align-items-center justify-content-between">
                <div className="card-title py-2 text-gray font-13 mb-0 font-weight-semibold">
                  Total Products
                </div>
              </div>
              <h2 className="font-weight-bold mb-3 pt-3 d-flex align-items-center justify-content-center text-primary-blue">
                {" "}
                {DashboardCounts.total_products}
                {/* <span className="border-curcle badge-pill badge badge-success ml-2">4,5%<i className="mdi mdi-arrow-up"></i></span> */}
              </h2>
              <div className="line-chart-wrapper d-none">
                <canvas id="linechart" height="80"></canvas>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-lg-3 stretch-card grid-margin">
          <div className="card">
            <div className="card-body p-3">
              <div className="d-flex border-bottom align-items-center justify-content-between">
                <div className="card-title py-2 text-gray font-13 mb-0 font-weight-semibold">
                  Customers
                </div>
              </div>
              <h2 className="font-weight-bold mb-3 pt-3 d-flex align-items-center justify-content-center text-warning">
                {DashboardCounts.total_customers}
                {/* <span className="border-curcle badge-pill badge badge-success ml-2">4,5%<i className="mdi mdi-arrow-up"></i></span> */}
              </h2>
              <div className="bar-chart-wrapper d-none">
                <canvas id="barchart" height="80"></canvas>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* table row starts here  */}
      <div className="row">
        <div className="col-xl-8">
          <div className="card p-3 grid-margin">
            <ul className="nav nav-tabs mb-4" id="myTab" role="tablist">
              <li className="nav-item">
                <a
                  className="nav-link active"
                  id="bestseller-tab"
                  data-toggle="tab"
                  href="#bestseller"
                  role="tab"
                  aria-controls="bestseller"
                  aria-selected="true"
                >
                  Best Sellers
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  id="mvproducts-tab"
                  data-toggle="tab"
                  href="#mvproducts"
                  role="tab"
                  aria-controls="mvproducts"
                  aria-selected="false"
                >
                  Most Viewed Products
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  id="customer-tab"
                  data-toggle="tab"
                  href="#customer"
                  role="tab"
                  aria-controls="customer"
                  aria-selected="false"
                >
                  New Customers
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  id="reviews-tab"
                  data-toggle="tab"
                  href="#reviews"
                  role="tab"
                  aria-controls="reviews"
                  aria-selected="false"
                >
                  Reviews
                </a>
              </li>
            </ul>
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="bestseller"
                role="tabpanel"
                aria-labelledby="bestseller-tab"
              >
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Image</th>
                        <th>Product</th>
                        <th>SKU</th>
                        <th>Price</th>
                        <th>Quantity</th>
                      </tr>
                    </thead>
                    <tbody>
                      {BestSellerProduct.map((item) => {
                        return (
                          <tr>
                            <td>
                              <img
                                src={item.base_image}
                                className="mr-2 product-img"
                                alt="image"
                              />
                            </td>
                            <td>
                              <span>{item.name}</span>
                            </td>
                            <td>{item.sku}</td>
                            <td>{item.formated_price}</td>
                            <td>{item.Quantity}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="mvproducts"
                role="tabpanel"
                aria-labelledby="mvproducts-tab"
              >
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Image</th>
                        <th>Product</th>
                        <th>Price</th>
                        <th>Quantity</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <img
                            src="./assests/images/product_images/ring.jpg"
                            className="mr-2 product-img"
                            alt="image"
                          />
                        </td>
                        <td>
                          <span>Product Name</span>
                        </td>
                        <td>$200</td>
                        <td>18</td>
                      </tr>
                      <tr>
                        <td>
                          <img
                            src="./assests/images/product_images/ring.jpg"
                            className="mr-2 product-img"
                            alt="image"
                          />
                        </td>
                        <td>
                          <span>Product Name</span>
                        </td>
                        <td>$200</td>
                        <td>18</td>
                      </tr>
                      <tr>
                        <td>
                          <img
                            src="./assests/images/product_images/ring.jpg"
                            className="mr-2 product-img"
                            alt="image"
                          />
                        </td>
                        <td>
                          <span>Product Name</span>
                        </td>
                        <td>$200</td>
                        <td>18</td>
                      </tr>
                      <tr>
                        <td>
                          <img
                            src="./assests/images/product_images/ring.jpg"
                            className="mr-2 product-img"
                            alt="image"
                          />
                        </td>
                        <td>
                          <span>Product Name</span>
                        </td>
                        <td>$200</td>
                        <td>18</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="customer"
                role="tabpanel"
                aria-labelledby="customer-tab"
              >
                ...
              </div>
              <div
                className="tab-pane fade"
                id="reviews"
                role="tabpanel"
                aria-labelledby="reviews-tab"
              >
                ...
              </div>
            </div>
          </div>

          <div className="card grid-margin">
            <div className="card-header d-flex justify-content-between align-items-center">
              <h5 className="card-title mb-0">Total Sales</h5>
              <select className="form-control select-custom" id="total-sales">
                <option>Monthly</option>
                <option>Yearly</option>
              </select>
            </div>
            <div className="card-body"></div>
          </div>

          <div className="card p-3 grid-margin">
            <ul className="nav nav-tabs mb-4" id="myTab" role="tablist">
              <li className="nav-item">
                <a
                  className="nav-link active"
                  id="lastorders-tab"
                  data-toggle="tab"
                  href="#lastorders"
                  role="tab"
                  aria-controls="lastorders"
                  aria-selected="true"
                >
                  Last Orders
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  id="lastsearchterm-tab"
                  data-toggle="tab"
                  href="#lastsearchterm"
                  role="tab"
                  aria-controls="lastsearchterm"
                  aria-selected="false"
                >
                  Last Search Terms
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  id="topsearchterm-tab"
                  data-toggle="tab"
                  href="#topsearchterm"
                  role="tab"
                  aria-controls="topsearchterm"
                  aria-selected="false"
                >
                  Top Search Terms
                </a>
              </li>
            </ul>
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="lastorders"
                role="tabpanel"
                aria-labelledby="lastorders-tab"
              >
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Image</th>
                        <th>Item</th>
                        <th>Customer</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {lastOrderProducts.map((item) => {
                        return (
                          <tr>
                            <td>
                              <img
                                src={item.base_image}
                                className="mr-2 product-img"
                                alt="image"
                              />
                            </td>
                            <td>
                              <Link
                                to={"/order/view/order_id/" + item.order_number}
                              >
                                <span>{item.name}</span>
                              </Link>
                            </td>
                            <td>{item.customer_name}</td>
                            <td>{item.formated_price}</td>
                          </tr>
                        );
                      })}
                      {/* <tr>
                                                <td>
                                                    <img src="./assests/images/product_images/ring.jpg" className="mr-2 product-img" alt="image" /></td>
                                                <td>
                                                    <span>Rohabani Bangles</span>
                                                </td>
                                                <td>Diana Scott</td>
                                                <td>$200</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <img src="./assests/images/product_images/ring.jpg" className="mr-2 product-img" alt="image" /></td>
                                                <td>
                                                    <span>Rohabani Bangles</span>
                                                </td>
                                                <td>Diana Scott</td>
                                                <td>$200</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <img src="./assests/images/product_images/ring.jpg" className="mr-2 product-img" alt="image" /></td>
                                                <td>
                                                    <span>Rohabani Bangles</span>
                                                </td>
                                                <td>Diana Scott</td>
                                                <td>$200</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <img src="./assests/images/product_images/ring.jpg" className="mr-2 product-img" alt="image" /></td>
                                                <td>
                                                    <span>Rohabani Bangles</span>
                                                </td>
                                                <td>Diana Scott</td>
                                                <td>$200</td>
                                            </tr> */}
                    </tbody>
                  </table>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="lastsearchterm"
                role="tabpanel"
                aria-labelledby="lastsearchterm-tab"
              >
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Image</th>
                        <th>Product</th>
                        <th>Price</th>
                        <th>Quantity</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <img
                            src="./assests/images/product_images/ring.jpg"
                            className="mr-2 product-img"
                            alt="image"
                          />
                        </td>
                        <td>
                          <span>Product Name</span>
                        </td>
                        <td>$200</td>
                        <td>18</td>
                      </tr>
                      <tr>
                        <td>
                          <img
                            src="./assests/images/product_images/ring.jpg"
                            className="mr-2 product-img"
                            alt="image"
                          />
                        </td>
                        <td>
                          <span>Product Name</span>
                        </td>
                        <td>$200</td>
                        <td>18</td>
                      </tr>
                      <tr>
                        <td>
                          <img
                            src="./assests/images/product_images/ring.jpg"
                            className="mr-2 product-img"
                            alt="image"
                          />
                        </td>
                        <td>
                          <span>Product Name</span>
                        </td>
                        <td>$200</td>
                        <td>18</td>
                      </tr>
                      <tr>
                        <td>
                          <img
                            src="./assests/images/product_images/ring.jpg"
                            className="mr-2 product-img"
                            alt="image"
                          />
                        </td>
                        <td>
                          <span>Product Name</span>
                        </td>
                        <td>$200</td>
                        <td>18</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="topsearchterm"
                role="tabpanel"
                aria-labelledby="topsearchterm-tab"
              >
                ...
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-4 grid-margin">
          <div className="card grid-margin">
            <div className="card-header d-flex justify-content-between align-items-center">
              <h5 className="card-title mb-0">Activity</h5>
            </div>
            <div className="card-body">
              <select className="form-control select-custom ml-auto outline-0 mb-2">
                <option>Today</option>
              </select>
              <div className="d-flex border-bottom py-3">
                <div className="pl-0">
                  <p className="mb-1 text-black font-14">
                    {" "}
                    Jennifer Scott changed product status from active to draft :{" "}
                    <strong>Frill Stud Earrings</strong>
                  </p>
                  <span className="font-12 text-gray">
                    October 6, 2021. 2:15 PM
                  </span>
                </div>
              </div>
              <div className="d-flex border-bottom py-3">
                <div className="pl-0">
                  <p className="mb-1 text-black font-14">
                    {" "}
                    Jennifer Scott changed product status from active to draft :{" "}
                    <strong>Frill Stud Earrings</strong>
                  </p>
                  <span className="font-12 text-gray">
                    October 6, 2021. 2:15 PM
                  </span>
                </div>
              </div>
              <div className="d-flex border-bottom py-3">
                <div className="pl-0">
                  <p className="mb-1 text-black font-14">
                    {" "}
                    Jennifer Scott changed product status from active to draft :{" "}
                    <strong>Frill Stud Earrings</strong>
                  </p>
                  <span className="font-12 text-gray">
                    October 6, 2021. 2:15 PM
                  </span>
                </div>
              </div>
              <div className="d-flex pt-3">
                <div className="pl-0">
                  <p className="mb-1 text-black font-14">
                    {" "}
                    Jennifer Scott changed product status from active to draft :{" "}
                    <strong>Frill Stud Earrings</strong>
                  </p>
                  <span className="font-12 text-gray">
                    October 6, 2021. 2:15 PM
                  </span>
                </div>
              </div>

              <div className="pt-3 text-center">
                <p className="mb-5 text-black font-12">
                  <a href="#" className="text-gray">
                    <u>View all recent activity</u>
                  </a>
                </p>
              </div>
              <hr />
              <div className="pt-4">
                <h5 className="mb-2 text-black font-12">At a Glance</h5>
              </div>
              <div className="pt-4">
                <div className="row">
                  <div className="col-md-6">
                    <a
                      href="#"
                      className="d-flex font-12 text-gray align-items-center mb-3"
                    >
                      <img
                        src="./assests/images/message-square.svg"
                        alt="Comments"
                        className="mr-2"
                      />
                      <span>1 Comments</span>
                    </a>
                  </div>
                  <div className="col-md-6">
                    <a
                      href="#"
                      className="d-flex font-12 text-gray align-items-center mb-3"
                    >
                      <img
                        src="./assests/images/post.svg"
                        alt="Posts"
                        className="mr-2"
                      />
                      <span>13 Posts</span>
                    </a>
                  </div>
                  <div className="col-md-6">
                    <a
                      href="#"
                      className="d-flex font-12 text-gray align-items-center mb-3"
                    >
                      <img
                        src="./assests/images/file-text.svg"
                        alt="Pages"
                        className="mr-2"
                      />
                      <span>7 Pages</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
