import Moment from 'moment';
const DateFormat = (props) => {
    if(props.value !== null && props.value !== ''){
        let date = props.value.replace('T', ' ').replace('.000Z', '')
        return new Date(date).toLocaleDateString('en-GB', {month : 'numeric', day : 'numeric', year : 'numeric', hour:'numeric', minute:'numeric', hour12: true})
    }
    else{
        return '';
    }
}
export default DateFormat;