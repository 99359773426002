import React, { useEffect, useState, useContext } from 'react';
import { Link } from "react-router-dom";    
import { HttpService, API, swal, Title, toast, MaterialTable, Messages, DateFormat } from '../../config/react'
import DataTable from 'react-data-table-component';

const Services=()=>{
    const [ServicesList, setServiceList] = useState([])
    const [isForceUpdate, setForceUpdate] = useState(false);

    const columns = [
        {
            name: 'Image',
            selector: row => <img src={row.image} className="my-2" width="100" onError={(e) => { e.target.onerror = null; e.target.src = row.altimage }} />
        },
        {
            name: 'Title',
            selector: row => <label> {row.title} </label>
        },
        {
            name: 'Service Type',
            selector: row => row.service_type == 1 ? <label>In Store</label> : <label>Virtual</label>
        },
        {
            name: 'Status',
            selector: row => row.status == 1 ? <label>Active</label> : <label>Inactive</label>
        },
        {
            name: 'Action',
            selector: (row) =>
                <div className="action-btn">
                    <Link to={"/services/edit/" + row.id} className="text-dark" title="Edit"><i className="fa fa-pencil-square-o" aria-hidden="true"></i> </Link>
                    <Link onClick={e => DeleteService(e, row.id)} to="" className="text-dark" title="Delete" ><i className="fa fa-trash-o" aria-hidden="true"></i> </Link>
                </div>
            ,
        },
    ];

    const DefaultFilters = {
        page: 1,
        search: "",
        page_size: 10
    }
    const [CustomFilters, setCustomFilters] = useState(DefaultFilters)
    const [totalRows, settotalRows] = useState()

    useEffect(() => {
        document.title = Title.Service        
        GetServicesList()
        
    }, [CustomFilters])
    const DeleteService = async (e, id) => {
        e.preventDefault()
        swal(Messages.Swal_Delete_Prop).then(function (isConfirm) {
            if (isConfirm) {
                var param = {
                    "id": id                   
                }
                HttpService.postApi(API.DeleteService, param)
                    .then(response => {
                        toast.success(Messages.Record_Deleted)
                        GetServicesList()
                        setForceUpdate(!isForceUpdate)
                    });
            }

        });
    }
    
    const GetServicesList = () => {
        var passData = {
            "page": CustomFilters.page,
            "search": CustomFilters.search,
            "page_size": CustomFilters.page_size
        }
        HttpService.postApi(API.GetServiceAdminList, passData)
        .then(response => {
            if (response.data.status == true) {
                var data = response.data.result[1];
                // var data = response.data.result[0];
                
                setServiceList(data)
                setForceUpdate(!isForceUpdate)
                settotalRows(response.data.result[0][0].total_row)
            }
        });
    }
    const handlePageChange = (e) => {
        setCustomFilters({ ...CustomFilters, ['page']: e });
    }

    const handlePerRowsChange = (e) => {
        setCustomFilters({ ...CustomFilters, ['page_size']: e });
    }

    const subHeaderComponentMemo = React.useMemo(() => {
		return (
            <input type="text" title="Search for Title, Service Type." className='form-control col-md-3' placeholder='Search' onChange={e => setCustomFilters({ ...CustomFilters, ['search']: e.target.value })} />
		);
	});
    return(
        <div className="content-wrapper pb-0">
        <div className="page-header">
            <h3 className="page-title"> {Title.Service} </h3>
            <div className="btn-group">
                <Link to="/services/addservice" className="btn btn-dark btn-fw rounded mr-2"> Add Service </Link>
            </div>
        </div>
        <div className="row">
                <div className="col-lg-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="table-responsive">
                            <DataTable
                                    columns={columns}
                                    data={ServicesList}
                                    pagination
                                    paginationServer
                                    paginationTotalRows={totalRows}
                                    onChangeRowsPerPage={handlePerRowsChange}
                                    onChangePage={handlePageChange}
                                    subHeader
			                        subHeaderComponent={subHeaderComponentMemo}
                                />
                            
                                {/* <MaterialTable
                                    title=""
                                    columns={[
                                        {
                                            title: 'Image', field: 'image', sorting: false,
                                            render: rowData => <img src={rowData.image} width="100" onError={(e) => { e.target.onerror = null; e.target.src = rowData.altimage }} />
                                        },
                                        {
                                            title: 'Title', field: 'title', type: 'datetime',
                                            render: rowData => <label> {rowData.title} </label>
                                        },
                                        {
                                            title: 'Service Type', field: 'service_type',
                                            render: rowData => rowData.service_type == 1 ? <label>In Store</label> : <label>Virtual</label>
                                        },
                                        {
                                            title: 'Status', field: 'status',
                                            render: rowData => rowData.status == 1 ? <label>Active</label> : <label>Inactive</label>
                                        },
                                        {
                                            title: 'Action', field: 'id', className: "table_icon", sorting: false,
                                            render: rowData =>
                                                <div className="action-btn">
                                                    <Link to={"/services/edit/" + rowData.id} className="text-dark" title="Edit"><i className="fa fa-pencil-square-o" aria-hidden="true"></i> </Link>
                                                    <Link onClick={e => DeleteService(e, rowData.id)} to="" className="text-dark" title="Delete" ><i className="fa fa-trash-o" aria-hidden="true"></i> </Link>
                                                </div>
                                        }
                                    ]}

                                    data={ServicesList}
                                    options={{
                                        thirdSortClick: false,
                                        //exportButton: true,
                                        pageSize: 10,
                                        emptyRowsWhenPaging: false,
                                        //filtering: true,
                                        exportCsv: (columns, data) => {
                                            alert('You should develop a code to export ' + data.length + ' rows');
                                        }
                                    }}
                                /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Services;