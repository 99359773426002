import { useState } from 'react'
import { Modal } from 'react-bootstrap';
import { DateFormat, API, HttpService, toast, Messages } from '../../config/react'
const ProductDetail = (props) => {

    const product = props.props.product != undefined ? props.props.product[0].filter(x => x.sku == props.props.sku)[0] : [];
    const productMetalAttributes = props.props.product != undefined ? (props.props.product[4]?.filter(x => x.sku == product.sku && x.attribute_name.toLowerCase() == "metal")) : [];
    const productStoneAttributes = props.props.product != undefined ? (props.props.product[4]?.filter(x => x.sku == product.sku && x.attribute_name.toLowerCase() == "clarity")) : [];
    const [input, setInput] = useState(product.price);
    const [CurrencySign, setCurrencySign] = useState(product.formated_price.substring(1, 0));
    const closeModal = () => {
        props.onCloseModal(true);
    }

    const UpdateProductPrice = (items) => {
        let price = input.replace(',', '')
        var param = {
            "_sku": items.sku,
            "_price": price
        }
        if (price != items.GB_price && price != '') {
            HttpService.postApi(API.UpdateProductPrice, param)
                .then(response => {
                    if (response.data.status) {
                        toast.success(Messages.update_price)
                        props.onCloseModal(true);
                    }
                });
        }
    }

    return (
        <Modal show={props.props.IsShowProductModal} onHide={closeModal} keyboard={true} dialogClassName="w-1100" size="lg">
            <Modal.Header>
                <div>
                    <h4 className='mb-0'><b>Product Detail</b></h4>
                </div>
                <button type="button" className="btn-close" aria-label="Close" onClick={closeModal}></button>
            </Modal.Header>
            <Modal.Body>
                <div className="container-fluid p-0">
                    <div className="row">
                        <div className="col-lg-6 stretch-card">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row justify-content-between">
                                        <div className="col-md-12">
                                            <div className="card-header mb-3 py-1 px-0">Detail</div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="address-info">
                                                <table className="table table-striped table-borderless table-diamond-detail">
                                                    <tbody>
                                                        {
                                                            product?.id != undefined &&
                                                            <>
                                                                <tr>
                                                                    <td className='text-black'>Name:</td>
                                                                    <td>{product.name}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className='text-black'>SKU:</td>
                                                                    <td className="text-wrap text-break">{product.sku}</td>
                                                                </tr>

                                                                <tr>
                                                                    <td className='text-black'>Description:</td>
                                                                    <td className="text-wrap">{(product.description != "" && product.description != null && product.description != 'null' ? product.description : '-')}</td>
                                                                </tr>

                                                                <tr>
                                                                    <td className='text-black'>Weight:</td>
                                                                    <td>{product.weight}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className='text-black'>Parent SKU:</td>
                                                                    <td>{product.parent_sku}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className='text-black'>Price:</td>
                                                                    <td>
                                                                        <div class="input-group">
                                                                            <div class="input-group-prepend">
                                                                                <span class="input-group-text gray-light py-2" id="basic-addon1">{CurrencySign}</span>
                                                                            </div>
                                                                            <input type="text" onInput={e => setInput(e.target.value)} value={input} className="form-control py-2 h-auto" aria-describedby="basic-addon1" />
                                                                            <button type="button" value="Sold" onClick={() => UpdateProductPrice(product)} className='btn py-2 btn-primary rounded border-0 ml-2'>Update</button>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className='text-black'>Image:</td>
                                                                    <td><img className="my-2" width="95px" height="95px" src={product.base_image} style={{ width: '150px', height: '150px' }}></img></td>
                                                                </tr>
                                                                <tr>
                                                                    <td className='text-black'>Created At:</td>
                                                                    <td><DateFormat value={product.created_at}></DateFormat></td>
                                                                </tr>
                                                            </>
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row justify-content-between">
                                        <div className="col-md-12">
                                            <div className="card-header mb-3 py-1 px-0">SEO Information</div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="address-info">
                                                <table className="table table-striped table-borderless table-diamond-detail">
                                                    <tbody>
                                                        {
                                                            product.id != undefined &&
                                                            <>
                                                                <tr>
                                                                    <td className='text-black'>Meta Title:</td>
                                                                    <td>{(product.meta_title != '' && product.meta_title != null && product.meta_title != 'null') ? product.meta_title : '-'}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className='text-black'>Meta Keywords:</td>
                                                                    <td>{(product.meta_keywords != "" && product.meta_keywords != null && product.meta_keywords != 'null') ? product.meta_keywords : '-'}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className='text-black'>Meta Description:</td>
                                                                    <td className="text-wrap">{(product.meta_description != '' && product.meta_description != null && product.meta_description != 'null') ? product.meta_description : '-'}</td>
                                                                </tr>
                                                            </>
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {(productMetalAttributes?.filter(x => x.attribute_name.toLowerCase() == "metal").length != 0) &&
                                <div className="card mt-4">
                                    <div className="card-body">
                                        <div className="row justify-content-between">
                                            <div className="col-md-12">
                                                <div className="card-header mb-3 py-1 px-0">Metal</div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="address-info">
                                                    <table className="table table-striped table-borderless table-diamond-detail">
                                                        <tbody>
                                                            <tr>
                                                                <th className='text-black'>Metal Quality</th>
                                                                <th className='text-black'>Weight</th>
                                                            </tr>
                                                            <tr>
                                                                <td>{productMetalAttributes[0].value}</td>
                                                                <td>{(product.weight != '' && product.weight != 'null' ? product.weight : '-')}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                            {product.side_diamonds_pieces > 0 &&
                                <div className="card mt-4">
                                    <div className="card-body">
                                        <div className="row justify-content-between">
                                            <div className="col-md-12">
                                                <div className="card-header mb-3 py-1 px-0">Diamond</div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="address-info">
                                                    <table className="table table-striped table-borderless table-diamond-detail">
                                                        <tbody>
                                                            <tr>
                                                                <th className='text-black'>Size</th>
                                                                <th className='text-black'>Pieces</th>
                                                                <th className='text-black'>Weight</th>
                                                            </tr>
                                                            <tr>
                                                                <td>{(product.side_diamonds_size != '' && product.side_diamonds_size != null && product.side_diamonds_size != 'null' ? product.side_diamonds_size : '-')}</td>
                                                                <td>{(product.side_diamonds_pieces != '' && product.side_diamonds_pieces != null && product.side_diamonds_pieces != 'null' ? product.side_diamonds_pieces : '-')}</td>
                                                                <td>{(product.side_diamonds_weight != '' && product.side_diamonds_weight != null && product.side_diamonds_weight != 'null' ? product.side_diamonds_weight : '-')}</td>
                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                            <div className="card mt-4">
                                <div className="card-body">
                                    <div className="row justify-content-between">
                                        <div className="col-md-12">
                                            <div className="card-header mb-3 py-1 px-0">Product Rating Information</div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="address-info">
                                                <table className="table table-striped table-borderless table-diamond-detail">
                                                    <tbody>
                                                        <tr>
                                                            <th className='text-black'>Avg Rating</th>
                                                            <th className='text-black'>No. of Ratings</th>
                                                        </tr>
                                                        <tr>
                                                            <td>{product.avg_review}</td>
                                                            <td>{product.total_rating}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        {(productStoneAttributes?.filter(x => x.attribute_name.toLowerCase() == "clarity").length != 0) &&
                            <div className="col-lg-6 stretch-card">
                                <div className="card mt-4">
                                    <div className="card-body">
                                        <div className="row justify-content-between">
                                            <div className="col-md-12">
                                                <div className="card-header mb-3 py-1 px-0">Stone</div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="address-info">
                                                    <table className="table table-striped table-borderless table-diamond-detail">
                                                        <tbody>
                                                            <tr>
                                                                <th className='text-black'>Clarity</th>
                                                                <th className='text-black'>Carat</th>
                                                                <th className='text-black'>Pieces</th>
                                                            </tr>
                                                            <tr>
                                                                <td>{(productStoneAttributes[0].value != '' && productStoneAttributes[0].value != null && productStoneAttributes[0].value != 'null' ? productStoneAttributes[0].value : '-')}</td>
                                                                <td>{(product.stone_weight != '' && product.stone_weight != null && product.stone_weight != 'null' ? product.stone_weight : '-')}</td>
                                                                <td>{(product.stone_pieces != '' && product.stone_pieces != null && product.stone_pieces != 'null' ? product.stone_pieces : '-')}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}
export default ProductDetail;