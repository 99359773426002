import React, { useEffect, useState, useContext } from 'react';
import { Link, useHistory } from "react-router-dom";
import { HttpService, API, swal, Title, toast, MaterialTable, Messages, DateFormat, Common, Permission_keys } from '../../config/react'
import DataTable from 'react-data-table-component';
import Spinner from '../Spinner';
const Newsletter = () => {
    const history = useHistory();
    const [IsAuth, setIsAuth] = useState(false)
    const [NewsletterList, setNewsletterList] = useState([])
    const [isForceUpdate, setForceUpdate] = useState(false);

    const columns = [
        {
            name: 'Template',
            sortable: true,
            sortField: 'template_id',
            selector: row => row.template_id,
        },
        {
            name: 'Scheduled On',
            sortable: true,
            sortField: 'schedule_on',
            selector: row => <DateFormat value={row.schedule_on}></DateFormat>
        },
        {
            name: 'Status',
            selector: row => row.is_send === 1 ? 'Sent' : (row.is_cancelled == 1 ? 'Cancelled' : 'Pending')
        },
        {
            name: 'Message',
            selector: row => row.message,
        },

        {
            name: 'Created Date',
            sortable: true,
            sortField: 'created_at',
            selector: row => <DateFormat value={row.created_at}></DateFormat>,

        },
        {
            name: 'Action',
            selector: (row) =>
                <div className="action-btn">
                    {
                        (row.is_send === 1 || row.is_cancelled === 1) ? "" :
                            <Link onClick={e => CancelNewsletter(e, row.batch_id)} to="#" className="text-dark" title="Cancel Newsletter" ><i className="fa fa-trash-o" aria-hidden="true"></i> </Link>
                    }
                </div>
        }
    ];

    const DefaultFilters = {
        page: 1,
        search: "",
        page_size: 10,
        sort_column: "",
        sort_direction: ""
    }
    const [CustomFilters, setCustomFilters] = useState(DefaultFilters)
    const [totalRows, settotalRows] = useState()

    const GetNewsletterList = () => {
        var newsletterData = {
            "page": CustomFilters.page,
            "search": CustomFilters.search,
            "page_size": CustomFilters.page_size,
            "sort_column": CustomFilters.sort_column,
            "sort_direction": CustomFilters.sort_direction
        }
        HttpService.postApi(API.GetNewsletterList, newsletterData).then(response => {
            if (response.data.status == true) {
                var data = response.data.result[1];
                console.log(data);
                setNewsletterList(data)
                setForceUpdate(!isForceUpdate)
                settotalRows(response.data.result[0][0].total_newsletters)
            }
        });
    }

    useEffect(() => {
        if (!Common.validatePermission(Permission_keys.Newsletter)) {
            history.push("/");
        }
        setIsAuth(true);
        setForceUpdate(!isForceUpdate)
        document.title = Title.Newsletter
        GetNewsletterList()
    }, [CustomFilters])

    const handlePageChange = (e) => {
        setCustomFilters({ ...CustomFilters, ['page']: e });
    }

    const handlePerRowsChange = (e) => {
        setCustomFilters({ ...CustomFilters, ['page_size']: e });
    }

    const subHeaderComponentMemo = React.useMemo(() => {
        return (
            <input type="text" title="Search For Template." className='form-control col-md-3' placeholder='Search' onChange={e => setCustomFilters({ ...CustomFilters, ['page']: 1, ['search']: e.target.value })} />
        );
    });

    const CancelNewsletter = (e, batch_id) => {
        var param = {
            batch_id: batch_id
        };
        swal(Messages.Swal_cancel_newsletter).then(function (isConfirm) {
            if (isConfirm) {
                HttpService.postApi(API.CancelNewsletter, param).then(response => {
                    toast.success(Messages.Newsletter_Cancelled)
                    GetNewsletterList()
                    setForceUpdate(!isForceUpdate)
                });
            }
        });

    }
    const handleSort = (e, direction) => {
        setCustomFilters({ ...CustomFilters, ['sort_column']: e.sortField, ['sort_direction']: direction });
    }
    return !IsAuth ? <Spinner /> : (
        <div className="content-wrapper pb-0">
            <div className="page-header">
                <h3 className="page-title"> {Title.Newsletter} </h3>
                <div className="btn-group">
                    <Link to="/newsletter/add" className="btn btn-dark btn-fw rounded mr-2"> Add Newsletter</Link>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="table-responsive">
                                <DataTable
                                    columns={columns}
                                    data={NewsletterList}
                                    pagination
                                    paginationServer
                                    paginationTotalRows={totalRows}
                                    onChangeRowsPerPage={handlePerRowsChange}
                                    onChangePage={handlePageChange}
                                    subHeader
                                    subHeaderComponent={subHeaderComponentMemo}
                                    onSort={handleSort}
                                    sortServer
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Newsletter;