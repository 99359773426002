import React, { useState, useEffect, useRef } from 'react';
import { useHistory, Link } from "react-router-dom";
import { HttpService, API, Title, toast, Messages, swal, Common, Permission_keys } from '../../../config/react'
import DataTable from 'react-data-table-component';
import Spinner from '../../Spinner';
function Settings(props) {
    const [isForceUpdate, setForceUpdate] = useState(false);
    const [SettingOptions, setSettingOptions] = useState([])
    const history = useHistory();
    const [IsAuth, setIsAuth] = useState(false)
    const columns = [
        {
            name: 'Title',
            selector: row => row.hj_title,
        },
        {
            name: 'Key',
            selector: row => row.hj_key,
        },
        {
            name: 'Value',
            selector: row => row.hj_value,
        },
        {
            name: 'Action',
            selector: (row) =>
                <div className="action-btn">
                    <Link to={"/system/settings/edit/" + row.id} className="text-dark" title="Edit"><i className="fa fa-pencil-square-o" aria-hidden="true"></i> </Link>
                    <Link onClick={e => DeleteOption(e, row.id)} to="" className="text-dark" title="Delete" ><i className="fa fa-trash-o" aria-hidden="true"></i> </Link>
                </div>
            ,
        },
    ];

    const DefaultFilters = {
        page: 1,
        search: "",
        page_size: 10
    }
    const [CustomFilters, setCustomFilters] = useState(DefaultFilters)
    const [totalRows, settotalRows] = useState()

    useEffect(() => {
        if (!Common.validatePermission(Permission_keys.System)) {
            history.push("/");
        }
        document.title = Title.Settings
        setIsAuth(true);
        setForceUpdate(!isForceUpdate)
        GetSettingOptions()
    }, [CustomFilters])

    const GetSettingOptions = () => {
        var passData = {
            "page": CustomFilters.page,
            "search": CustomFilters.search,
            "page_size": CustomFilters.page_size
        }
        HttpService.postApi(API.GetSettingAdminOptions, passData)
            .then(response => {
                if (response.data.status == true) {
                    var data = response.data.result[1];
                    // var data = response.data.result[0];

                    setSettingOptions(data)
                    setForceUpdate(!isForceUpdate)
                    settotalRows(response.data.result[0][0].total_row)
                }
            });
    }
    const handlePageChange = (e) => {
        setCustomFilters({ ...CustomFilters, ['page']: e });
    }

    const handlePerRowsChange = (e) => {
        setCustomFilters({ ...CustomFilters, ['page_size']: e });
    }

    const subHeaderComponentMemo = React.useMemo(() => {
        return (
            <input type="text" title="Search For Title, Key, Value." className='form-control col-md-3' placeholder='Search' onChange={e => setCustomFilters({ ...CustomFilters, ['search']: e.target.value })} />
        );
    });

    const DeleteOption = async (e, id) => {
        e.preventDefault()
        swal(Messages.Swal_Delete_Prop).then(function (isConfirm) {
            if (isConfirm) {
                var param = {
                    "id": id
                }
                HttpService.postApi(API.DeleteSettingOption, param)
                    .then(response => {
                        toast.success(Messages.Record_Deleted)
                        GetSettingOptions()
                        setForceUpdate(!isForceUpdate)
                    });
            }

        });
    }
    return !IsAuth ? <Spinner /> : (
        <div className="content-wrapper pb-0">
            <div className="page-header">
                <h3 className="page-title"> {Title.Settings} </h3>
                <div className="btn-group">
                    <Link to="/system/settings/add" className="btn btn-dark btn-fw rounded mr-2"> Add Option </Link>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="table-responsive">
                                <DataTable
                                    columns={columns}
                                    data={SettingOptions}
                                    // progressPending={loading}
                                    pagination
                                    paginationServer
                                    paginationTotalRows={totalRows}
                                    onChangeRowsPerPage={handlePerRowsChange}
                                    onChangePage={handlePageChange}
                                    subHeader
                                    subHeaderComponent={subHeaderComponentMemo}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Settings;