import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { SoldDiamond } from '../../constants/PageTitle';
import { HttpService, API, swal, Title, toast, MaterialTable, Messages, DateFormat,Common,Permission_keys } from '../../config/react'
import Spinner from '../Spinner';
const DiamondDetail = (props) => {
    const history = useHistory();
    const [IsAuth, setIsAuth] = useState(false)
    const [data, setData] = useState();
    let diamond = Common.GetJSON(props.props?.data.attribute_options)
    const [isForceUpdate, setForceUpdate] = useState(false);

    useEffect(() => {
        if (!Common.validatePermission(Permission_keys.Diamonds)) {
            history.push("/");
        }
        setIsAuth(true);
        setForceUpdate(!isForceUpdate)
        setData(Common.GetJSON(props.props?.data.attribute_options))

    }, [])

    useEffect(() => {
        setForceUpdate(!isForceUpdate)
    }, [data])

    const SoldButton = () => {
        if (props.props.data.is_sold == 1) {
            return <><span className='badge bg-transparent badge-danger py-2 px-0 btn-fw rounded filter-btn mr-2 fs-14'>Sold Out</span>
                <button className='btn py-2 btn-primary btn-fw rounded filter-btn border-0 mr-2' onClick={e => OnClick_RevertSoldDiamond(0)}>Add to Inventory</button></>
            //return  <button type="button" value="Sold" className='btn btn-primary btn-fw rounded filter-btn mr-2'>Sold Out</button>
        } else {
            return <button type="button" value="Sold" className='btn py-2 btn-primary btn-fw rounded filter-btn border-0 mr-2' onClick={e => OnClick_SoldDiamond(1)}>Mark as sold</button>
        }
    }
    const closeModal = () => {
        props.onCloseModal(true);
    }
    const OnClick_SoldDiamond = (issold) => {
        swal(Messages.diamond_Sold_Prop).then(function (isConfirm) {
            if (isConfirm) {
                HttpService.postApi(API.SetSoldDiamondBySKU, { "sku": props.props.data.sku, "issold": issold })
                    .then(response => {
                        props.onCloseModal(true);
                    });
            }
        })
    }
    const OnClick_RevertSoldDiamond = (issold) => {
        swal(Messages.diamond_ReverSold_Prop).then(function (isConfirm) {
            if (isConfirm) {
                HttpService.postApi(API.SetSoldDiamondBySKU, { "sku": props.props.data.sku, "issold": issold })
                    .then(response => {
                        props.onCloseModal(true);
                    });
            }
        })
    }
    return !IsAuth ? <Spinner /> : (
        <Modal show={props.props.IsShowModal} onHide={closeModal} backdrop="static" keyboard={false}>
            <Modal.Header>
                <div>
                    <h6>{props.props.data.is_gemstone ? 'Gemstone Detail' : 'Diamond Detail'}  (SKU: {props.props.data.sku})</h6>
                    {/* {props.props.data.is_sold == 1 }?<><button type="button" value="Sold" className='btn btn-primary btn-fw rounded filter-btn mr-2'>Sold Out</button> <button className='btn btn-primary btn-fw rounded filter-btn mr-2' onClick={e=>OnClick_RevertSoldDiamond(0)}>Revert sold</button></>:
                <button type="button" value="Sold" className='btn btn-primary btn-fw rounded filter-btn mr-2' onClick={OnClick_SoldDiamond(1)}>Mark as sold</button> */}
                    <SoldButton></SoldButton>
                </div>
                <button type="button" className="btn-close" aria-label="Close" onClick={closeModal}></button>
            </Modal.Header>
            <Modal.Body>
                <div className="content-wrapper p-0">
                    <div className="row">
                        <div className="col-lg-12 stretch-card">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row justify-content-between">
                                        <div className="col-md-12">
                                            <div className="card-header p-0 mb-3"></div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="address-info">
                                                <table className="table table-striped table-borderless table-diamond-detail">
                                                    <tbody>
                                                        {
                                                            data != undefined &&
                                                            data.map((item, index) => {
                                                                return <tr>
                                                                    <td className='text-black'>{item.title}</td>
                                                                    <td>{item.value}</td>
                                                                </tr>
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Modal.Body>
        </Modal>
    );
};

export default DiamondDetail;