import React, { useEffect, useState } from 'react';
import * as Title from '../../constants/PageTitle';
import { HttpService, API } from '../../config/react'
import { Link } from "react-router-dom";
import ColumnChartComponent from './ColumnChartComponent';
const Dashboard3 = () => {
    const [DashboardCounts, setDashboardCounts] = useState([])
    const [BestSellerProduct, setBestSellerProduct] = useState([])
    const [LastSearchTerm, setLastSearchTerm] = useState([])
    const [TopSearchTerm, setTopSearchTerm] = useState([])
    const [MostViewProduct, setMostViewProduct] = useState([])
    const [OrderChart, setOrderChart] = useState([]);
    const [BookAnAppointementChart, setBookAnAppointementChart] = useState([]);
    const [Type, setType] = useState('month');
    const [AppointmentType, setAppointmentType] = useState('week');
    useEffect(() => {
        document.title = Title.Dashboard
        GetCounts();
        GetOrderChart();
        GetBookAnAppointementCount();
        GetBestSeller();
        GetLastSearchTerms()
        GetTopSearchTerms()
        GetMostViewProduct()
    }, [])

    const GetCounts = () => {
        HttpService.getApi(API.DashboardCounts, null)
            .then(response => {
                setDashboardCounts(response.data.result[0][0])
            });
    }
    const GetOrderChart = () => {
        var param = {
            '_type': Type,
        }
        HttpService.postApi(API.OrderChart, param)
            .then(response => {
                var labels = [];
                var values = [];
                response.data.result[0].map(item => {
                    labels.push(item.name);
                    values.push(item.count);
                })
                var data = { labels: labels, values: values };
                setOrderChart(data)
            });
    }
    const GetBestSeller = () => {
        HttpService.postApi(API.GetBestSeller, null)
            .then(response => {
                setBestSellerProduct(response.data.result);
            });
    }
    const GetBookAnAppointementCount = () => {
        var param = {
            '_type': AppointmentType,
        }
        HttpService.postApi(API.BookAnAppointementCount, param)
            .then(response => {
                var labels = [];
                var values = [];
                response.data.result[0].map(item => {
                    labels.push(item.name);
                    values.push(item.count);
                })
                var data = { labels: labels, values: values };
                setBookAnAppointementChart(data)
            });
    }
    const GetLastSearchTerms = () => {
        HttpService.postApi(API.GetLastSearchTerm, null)
            .then(response => {
                setLastSearchTerm(response.data.result);
            });
    }
    const GetTopSearchTerms = () => {
        HttpService.postApi(API.GetTopSearchTerm, null)
            .then(response => {
                setTopSearchTerm(response.data.result);
            });
    }
    const GetMostViewProduct = () => {
        HttpService.postApi(API.GetMostViewProduct, null)
            .then(response => {
                setMostViewProduct(response.data.result[0]);
            });
    }

    const ChartType = (e) => {
        setType(e.currentTarget.value)
    }
    const BookAnAppointmentType = (e) => {
        setAppointmentType(e.currentTarget.value)
    }
    useEffect(() => {
        GetOrderChart()
    }, [Type])

    useEffect(() => {
        GetBookAnAppointementCount()
    }, [AppointmentType])
    return (
        <div className="content-wrapper pb-0">
            <div className="row">
                <div className="col-md-6 col-lg-5 stretch-card grid-margin">
                    <div className="card">
                        <div className="card-body p-3">
                            <div className="d-flex border-bottom align-items-center justify-content-between">
                                <div className="card-title mb-2 font-weight-bold d-flex align-items-center">Order By Qty
                                    <Link to={"/sales/orders"} className="d-inline-flex"> 
                                       <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="#959595" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="ml-1 feather feather-external-link"><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path><polyline points="15 3 21 3 21 9"></polyline><line x1="10" y1="14" x2="21" y2="3"></line></svg>
                                    </Link>
                                </div>
                                <div className="d-flex text-muted  font-20">
                                    <select className="form-control text-dark py-1 mb-2 select-custom" value={Type} onChange={(e) => ChartType(e)}>
                                        <option value='month'>Month</option>
                                        <option value='year'>Year</option>
                                    </select>
                                </div>
                            </div>
                            <div className="chart-wrapper mt-3 chart-medium">
                                {
                                    OrderChart != undefined &&
                                    <ColumnChartComponent data={OrderChart} />
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-lg-3 stretch-card grid-margin">
                    <div className="card">
                        <div className="card-body p-3">
                            <div className="d-flex border-bottom align-items-center justify-content-between">
                                <div className="card-title pt-1 pb-2 mb-0 font-weight-bold">Total Products
                                </div>
                                <div className="d-none text-muted font-20">
                                    <select className="form-control text-dark select-custom outline-0">
                                        <option>This Monthly</option>
                                        <option>Yearly</option>
                                    </select>
                                </div>
                            </div>
                            <div className="total-diamond-part text-center pt-3">
                                <div className="pt-3 card-title text-gray font-13 mb-1 font-weight-semibold"> Earth Mined Diamonds</div>
                                <hr className="w-25 mt-2 mb-2" />
                                <h3 className=" font-weight-bold mb-3 d-flex justify-content-center align-items-center text-success">
                                    {DashboardCounts.total_earth_mined_diamonds}
                                </h3>
                            </div>
                            <div className="total-diamond-part text-center">
                                <div className="pt-2 card-title text-gray font-13 mb-1 font-weight-semibold"> Lab Grown Diamonds</div>
                                <hr className="w-25 mt-2 mb-2" />
                                <h3 className=" font-weight-bold mb-3 d-flex justify-content-center align-items-center text-dark">
                                    {DashboardCounts.total_lab_grown_diamonds}
                                </h3>
                            </div>
                            <div className="total-diamond-part text-center">
                                <div className="pt-2 card-title text-gray font-13 mb-1 font-weight-semibold"> Engagement Rings Settings</div>
                                <hr className="w-25 mt-2 mb-2" />
                                <h3 className=" font-weight-bold mb-0 d-flex justify-content-center align-items-center text-primary-blue">
                                    {DashboardCounts.total_products}
                                </h3>
                            </div>

                            <div className="bar-chart-wrapper d-none">
                                <canvas id="barchart" height="80"></canvas>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-lg-4 stretch-card grid-margin">
                    <div className="card">
                        <div className="card-body p-3">
                            <div className="d-flex border-bottom align-items-center justify-content-between">
                                <div className="card-title pb-2 mb-0 font-weight-bold d-flex align-items-center">Book An Appointment
                                    <Link to={"/appointments"} className="d-inline-flex">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="#959595" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="ml-1 feather feather-external-link"><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path><polyline points="15 3 21 3 21 9"></polyline><line x1="10" y1="14" x2="21" y2="3"></line></svg>
                                    </Link>
                                </div>

                                <div className="d-flex text-muted  font-20">
                                    <select className="form-control text-dark py-1 mb-2 select-custom" value={AppointmentType} onChange={(e) => BookAnAppointmentType(e)}>
                                        <option value='week'>Week</option>
                                        <option value='month'>Month</option>
                                    </select>
                                </div>
                            </div>
                            <div className="line-chart-wrapper d-none">
                                <canvas id="linechart" height="80"></canvas>
                            </div>
                            <div className="chart-wrapper mt-3 chart-book-appointment">
                                {
                                    BookAnAppointementChart != undefined &&
                                    <ColumnChartComponent data={BookAnAppointementChart} />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* table row starts here  */}
            <div className="row">
                <div className="col-md-12 col-xl-6">
                    <div className="card p-3 grid-margin">
                        <div className="d-flex border-bottom align-items-center justify-content-between">
                            <div className="card-title py-2 mb-2 font-weight-bold">Best Sellers
                            </div>
                        </div>
                        <div className="tab-content" id="myTabContent">
                            <div className="tab-pane fade show active" id="bestseller" role="tabpanel" aria-labelledby="bestseller-tab">
                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Image</th>
                                                <th>Name</th>
                                                <th>SKU</th>
                                                <th>Count</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {BestSellerProduct.map(item => {
                                                return (<tr>
                                                    <td>
                                                        <img src={item.product_image} className="mr-2 product-img" alt={item.product_image} /></td>
                                                    <td>
                                                        <span>{item.name}</span>
                                                    </td>
                                                    <td>{item.sku}</td>
                                                    <td>{item.count}</td>
                                                </tr>)
                                            })}
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>    
                <div className="col-md-12 col-xl-6">    
                    <div className="card p-3 grid-margin">
                        <div className="d-flex border-bottom align-items-center justify-content-between">
                            <div className="card-title py-2 mb-2 font-weight-bold">Most Viewed Products
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Image</th>
                                        <th>Name</th>
                                        <th>SKU</th>
                                        <th>Count</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {MostViewProduct.map(item => {
                                        return (<tr>
                                            <td>
                                                <img src={item.image} className="mr-2 product-img" alt={item.image} /></td>
                                            <td>
                                                <span>{item.name}</span>
                                            </td>
                                            <td>{item.sku}</td>
                                            <td>{item.count}</td>
                                        </tr>)
                                    })}

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-md-6'>
                    <div className="card p-3 grid-margin">
                        <div className="d-flex border-bottom align-items-center justify-content-between">
                            <div className="card-title py-2 mb-2 font-weight-bold d-flex align-items-center">Last Search Terms
                                <Link to={"/report/searchterm"}  className="d-inline-flex">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="#959595" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="ml-1 feather feather-external-link"><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path><polyline points="15 3 21 3 21 9"></polyline><line x1="10" y1="14" x2="21" y2="3"></line></svg>
                                </Link>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Keyword</th>
                                        <th>Number of Uses</th>
                                        <th>Number of Result</th>
                                        <th>Last Search Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {LastSearchTerm.map(item => {
                                        return (<tr>
                                            <td>{item.keyword}</td>
                                            <td>{item.no_of_users}</td>
                                            <td>{item.no_of_result}</td>
                                            <td>{item.updated_at}</td>
                                        </tr>)
                                    })}
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
                <div className='col-md-6'>
                    <div className="card p-3 grid-margin">
                        <div className="d-flex border-bottom align-items-center justify-content-between">
                            <div className="card-title py-2 mb-2 font-weight-bold d-flex align-items-center">Top Search Terms
                                <Link to={"/report/searchterm"} className="d-inline-flex">
                                   <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="#959595" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="ml-1 feather feather-external-link"><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path><polyline points="15 3 21 3 21 9"></polyline><line x1="10" y1="14" x2="21" y2="3"></line></svg>
                                </Link>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Keyword</th>
                                        <th>Number of Uses</th>
                                        <th>Number of Result</th>
                                        <th>Last Search Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {TopSearchTerm.map(item => {
                                        return (<tr>
                                            <td>{item.keyword}</td>
                                            <td>{item.no_of_users}</td>
                                            <td>{item.no_of_result}</td>
                                            <td>{item.updated_at}</td>
                                        </tr>)
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dashboard3;