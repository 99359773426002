import * as HttpService from "../config/HttpService"
import * as API from '../constants/API'
import * as Permission_keys from '../constants/PermissionKeys'
import SimpleReactValidator from 'simple-react-validator';
import swal from '@sweetalert/with-react';
import * as Title from '../constants/PageTitle';
import { toast } from 'react-toastify';
import MaterialTable from 'material-table';
import * as Messages from "../constants/Messages"
import * as RouteLinks from "../constants/RouteLinks"
import PriceFormat from "../constants/PriceFormat";
import DateFormat from "../constants/DateFormat";
import DropdownTreeSelect from "react-dropdown-tree-select";
import FileUploadWithPreview from "file-upload-with-preview";
import Nestable from 'react-nestable';
import PasswordStrengthBar from 'react-password-strength-bar';
import { Modal, Button } from 'react-bootstrap'
import 'react-nestable/dist/styles/index.css';
import 'react-dropdown-tree-select/dist/styles.css'
import "file-upload-with-preview/dist/file-upload-with-preview.min.css";
import ReactStars from "react-rating-stars-component";
import xmlbuilder from 'xmlbuilder'
import fs from 'fs'
import * as Common from './Common'
import DatePicker from "react-datepicker";
import DateTimePicker from 'react-datetime-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css'
import "react-datepicker/dist/react-datepicker.css";
import * as moment from 'moment';
import * as XLSX from 'xlsx'
import { saveAs } from 'file-saver'

const ExportToExcel = (DATA, fileName) => {
    const ws = XLSX.utils.json_to_sheet(DATA)
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const data = new Blob([excelBuffer], { type: 'application/vnd.ms-excel;charset=utf-8' })
    saveAs(data, fileName + '.xlsx')
  }
const getBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
        cb(reader.result)
    };
    reader.onerror = function (error) {
        console.log('Error: ', error);
    };
}
export { HttpService, API,ExportToExcel, SimpleReactValidator, swal, Title, toast, MaterialTable, Messages, RouteLinks, PriceFormat, DateFormat, DropdownTreeSelect, FileUploadWithPreview, Nestable, getBase64, PasswordStrengthBar, Modal, Button, ReactStars, xmlbuilder, fs, Common, DateTimePicker, Permission_keys, DatePicker, moment }
