import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { HttpService, API, swal, Title, toast, MaterialTable, Messages, DateFormat } from '../../../config/react'
import Moment from 'moment';
import DataTable from 'react-data-table-component';
function Reviews() {
    const [ReviewList, setReviewList] = useState([]);
    const [isForceUpdate, setForceUpdate] = useState(false);
    const [totalRows, settotalRows] = useState()
    const DefaultFilters = {
        page: 1,
        search: "",
        page_size: 10
    }
    const [CustomFilters, setCustomFilters] = useState(DefaultFilters)
    const columns = [
        // {
        //     name: 'Title',
        //     selector: row => row.title,
        // },
        {
            name: 'Customer Name',
            selector: row => row.customer_name,
        },
        {
            name: 'Product',
            selector: row => row.product_name,
        },
        {
            name: 'SKU',
            selector: row => row.sku,
        },
        {
            name: 'Status',
            selector: row => row.status.charAt(0).toUpperCase() + row.status.slice(1),
        },
        {
            name: 'Created Date',
            selector: row => Moment(row.created_at).format('YYYY-MM-DD'),
            
        },
        {
            name: 'Action',
            selector: (row) =>
                <div className="action-btn">
                    <Link to={"/marketing/reviews/edit/" + row.id} className="text-dark" title="Edit"><i className="fa fa-pencil-square-o" aria-hidden="true"></i> </Link>
                </div>
            ,
        },
    ];

    useEffect(() => {
        document.title = Title.Reviews
        GetReviewsList()
    }, [CustomFilters])
    const GetReviewsList = () => {
        var param = {
            "page": CustomFilters.page,
            "search": CustomFilters.search,
            "page_size": CustomFilters.page_size
        }
        HttpService.postApi(API.GetReviewsList, param)
            .then(response => {
                if (response.data.status == true) {
                    setReviewList(response.data.result[1])
                    setForceUpdate(!isForceUpdate)
                    settotalRows(response.data.result[0][0].total)
                }

            });
    }
    const handlePageChange = (e) => {
        setCustomFilters({ ...CustomFilters, ['page']: e });
    }

    const handlePerRowsChange = (e) => {
        setCustomFilters({ ...CustomFilters, ['page_size']: e });
    }
    const subHeaderComponentMemo = React.useMemo(() => {
		return (
            <input type="text" title="Search For Title, Name, Product, SKU, Status." className='form-control col-md-3' placeholder='Search' onChange={e => setCustomFilters({ ...CustomFilters, ['search']: e.target.value })} />
		);
	});
    return (
        <div className="content-wrapper pb-0">
            <div className="page-header">
                <h3 className="page-title"> {Title.Reviews} </h3>
            </div>
            <div className="row">
                <div className="col-lg-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">

                            <div className="table-responsive">
                            <DataTable
                                    // title="Coupons"
                                    columns={columns}
                                    data={ReviewList}
                                    // progressPending={loading}
                                    pagination
                                    paginationServer
                                    paginationTotalRows={totalRows}
                                    onChangeRowsPerPage={handlePerRowsChange}
                                    onChangePage={handlePageChange}
                                    subHeader
			                        subHeaderComponent={subHeaderComponentMemo}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Reviews;