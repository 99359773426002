import React, { useEffect, useState, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import UserContext from "../../config/UserContext";
import {
  HttpService,
  API,
  swal,
  Title,
  toast,
  Messages,
  DateFormat,
  Common,
  Permission_keys,
  ExportToExcel
} from "../../config/react";
import DataTable from "react-data-table-component";
import Spinner from "../Spinner";

const ContactForm = () => {
  const [ContactFormList, setContactFormList] = useState([]);
  const [isForceUpdate, setForceUpdate] = useState(false);
  const history = useHistory();
  const [IsAuth, setIsAuth] = useState(false);

  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
    },
    {
      name: "Email",
      selector: (row) => row.email,
    },
    {
      name: "Contact Number",
      selector: (row) => row.phone,
    },
    {
      name: "Message",
      selector: (row) => row.message,
    },

    {
      name: "Created Date",
      selector: (row) => <DateFormat value={row.created_at}></DateFormat>,
    },
  ];

  const DefaultFilters = {
    page: 1,
    search: "",
    page_size: 10,
  };
  const [CustomFilters, setCustomFilters] = useState(DefaultFilters);
  const [totalRows, settotalRows] = useState();

  const GetContactFormList = () => {
    var contactformData = {
      page: CustomFilters.page,
      search: CustomFilters.search,
      page_size: CustomFilters.page_size,
    };
    HttpService.postApi(API.GetContactFormAdminList, contactformData).then(
      (response) => {
        //setContactFormList(response.data.result[0])

        if (response.data.status == true) {
          var data = response.data.result[1];
          // var data = response.data.result[0];
          console.log(data);
          setContactFormList(data);
          setForceUpdate(!isForceUpdate);
          settotalRows(response.data.result[0][0].total_form);
        }
      }
    );
  };

  useEffect(() => {
    if (!Common.validatePermission(Permission_keys.Contact_Form)) {
      history.push("/");
    }
    setIsAuth(true);
    setForceUpdate(!isForceUpdate);
    document.title = Title.ContactForm;
    GetContactFormList();
  }, [CustomFilters]);

  const handlePageChange = (e) => {
    setCustomFilters({ ...CustomFilters, ["page"]: e });
  };

  const handlePerRowsChange = (e) => {
    setCustomFilters({ ...CustomFilters, ["page_size"]: e });
  };

  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <input
        type="text"
        title="Search For Name, Email, Contact Number, Message."
        className="form-control col-md-3"
        placeholder="Search"
        onChange={(e) =>
          setCustomFilters({
            ...CustomFilters,
            ["page"]: 1,
            ["search"]: e.target.value,
          })
        }
      />
    );
  });
  const handleExportExcel = () => {
    HttpService.getApi(API.GetContactFormListExcel).then((response) => {
      if (response.data.result) {
        ExportToExcel(response.data.result[0], "ContactForm");
      }
    });
  };
  return !IsAuth ? (
    <Spinner />
  ) : (
    <div className="content-wrapper pb-0">
      <div className="page-header">
        <h3 className="page-title"> {Title.ContactForm} </h3>
      </div>
      <div className="row">
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <button
                className="btn btn-primary float-right m-1"
                onClick={handleExportExcel}
              >
                Export Excel
              </button>
              <div className="table-responsive contact-form-table">
                <DataTable
                  columns={columns}
                  data={ContactFormList}
                  // progressPending={loading}
                  pagination
                  paginationServer
                  paginationTotalRows={totalRows}
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                  subHeader
                  subHeaderComponent={subHeaderComponentMemo}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
