import React, { useState, useRef, useEffect, useContext } from 'react';
import { useHistory, useParams, Link } from "react-router-dom";
import UserContext from '../../config/UserContext'
import { HttpService, API, SimpleReactValidator, Permission_keys, Common, Title, toast, Messages, swal } from '../../config/react'
import readXlsxFile from 'read-excel-file'
import exampleSheet from '../../assests/example_sheets/Product_Sheet.xlsx'
import Spinner from '../Spinner';
const ImportProducts = () => {
    const submitButtonRef = useRef();
    const user = useContext(UserContext);
    const history = useHistory();
    const [IsAuth, setIsAuth] = useState(false)
    const [isForceUpdate, setForceUpdate] = useState(false);
    const validator = useRef(new SimpleReactValidator())
    const [loading, setLoading] = useState(false);
    const [IsImagesExist, setIsImagesExist] = useState(false);
    const [Inputs, setInputs] = useState({
        excel_sheet: []
    });

    useEffect(() => {
        if (!Common.validatePermission(Permission_keys.Products)) {
            history.push("/");
        }
        setIsAuth(true);
        setForceUpdate(!isForceUpdate)
        document.title = Title.ImportExcel
    }, [])

    const uploadExcelFile = (e) => {
        setInputs({ ...Inputs, ['excel_sheet']: e.target.files[0] });
        setIsImagesExist(false);
        setForceUpdate(!isForceUpdate);
    }
    const imageErrorMessage = (skuWithoutBaseImage, skuWithoutVideo) => {
        return <div>
            {
                skuWithoutBaseImage.length > 0 &&
                <>
                    <b>Base image not uploaded for below SKU:</b>
                    <p>{skuWithoutBaseImage.join(",")}</p>
                </>
            }

            {/* {
                skuWithoutVideo.length > 0 &&
                <>
                    <b>Video not uploaded for below SKU:</b>
                    <p>{skuWithoutVideo.join(",")}</p>
                </>
            } */}

        </div>
    }

    const SubmitForm = () => {
        if (!validator.current.allValid()) {
            validator.current.showMessages();
            setForceUpdate(!isForceUpdate)
            return
        }
        setLoading(true);
        readXlsxFile(Inputs.excel_sheet).then((rows) => {
            var headers = rows[0];
            rows.splice(0, 1);
            var datas = rows;
            var excelDatas = [];
            if (datas.length < 1) {
                setLoading(false);
                swal({
                    title: Messages.oops,
                    text: Messages.blank_file,
                    icon: "error",
                    buttons: {
                        cancel: true,
                        confirm: false,
                    }
                });
                return
            }

            for (var i = 0; i < datas.length; i++) {
                var array = {};
                for (var j = 0; j < datas[i].length; j++) {
                    array[headers[j].toLowerCase().replaceAll(" ", "_")] = datas[i][j];
                }
                excelDatas.push(array);
            }
            var products = [];
            var last_sku = "";
            if (excelDatas[0]['sku'] == undefined) {
                setLoading(false);
                swal({
                    title: Messages.oops,
                    text: Messages.invalid_file,
                    icon: "error",
                    buttons: {
                        cancel: true,
                        confirm: false,
                    }
                });
                return
            }
            for (var key in excelDatas) {
                if (excelDatas[key]['parent_sku'] == null || excelDatas[key]['parent_sku'] == '') {
                    last_sku = excelDatas[key]['sku'];
                    var categories = excelDatas[key]['category'] != null && excelDatas[key]['category'].indexOf("|") >= 0 ? excelDatas[key]['category'].split("|") : [excelDatas[key]['category']];
                    var shapes = excelDatas[key]['shape'] != null && excelDatas[key]['shape'].indexOf("|") >= 0 ? excelDatas[key]['shape'].split("|") : [excelDatas[key]['shape']];
                    var styles = excelDatas[key]['style'] != null && excelDatas[key]['style'].indexOf("|") >= 0 ? excelDatas[key]['style'].split("|") : [excelDatas[key]['style']];
                    var finalCategories = [];
                    categories.forEach(element => {
                        if (finalCategories.indexOf(element) < 0) {
                            finalCategories.push(element);
                        }
                    });
                    shapes.forEach(element => {
                        if (finalCategories.indexOf(element) < 0) {
                            finalCategories.push(element);
                        }
                    });
                    styles.forEach(element => {
                        if (finalCategories.indexOf(element) < 0) {
                            finalCategories.push(element);
                        }
                    });
                    let diamonds = [];
                    if (excelDatas[key]['stone_1'] != null && excelDatas[key]['stone_1'].length > 0) {
                        diamonds.push(excelDatas[key]['stone_1']);
                    }
                    if (excelDatas[key]['stone_2'] != null && excelDatas[key]['stone_2'].length > 0) {
                        diamonds.push(excelDatas[key]['stone_2']);
                    }
                    if (excelDatas[key]['stone_3'] != null && excelDatas[key]['stone_3'].length > 0) {
                        diamonds.push(excelDatas[key]['stone_3']);
                    }
                    let attributesArr = [];
                    var attributes = excelDatas[key]['attributes'] != null && excelDatas[key]['attributes'].indexOf("|") >= 0 ? excelDatas[key]['attributes'].split("|") : null;
                    if (attributes != '' && attributes != null && attributes.length > 0)
                        attributes.forEach(element => {
                            let attribute = element.split(':');
                            attributesArr.push({ title: attribute[0].trim(), value: attribute[1].trim() });
                        });
                    products[excelDatas[key]['sku']] = {
                        'sku': excelDatas[key]['sku'],
                        'name': excelDatas[key]['product_title'],
                        'attribute_set_id': excelDatas[key]['attribute_set'],
                        'weight': null,
                        'is_configured': 1,
                        'description': excelDatas[key]['description'],
                        'short_description': excelDatas[key]['description'],
                        'is_readytoship': (excelDatas[key]['stock'] > 0 ? 1 : 0),
                        'is_sold': 0,
                        'meta_description': excelDatas[key]['meta_description'],
                        'meta_keywords': excelDatas[key]['meta_keywords'],
                        'meta_title': excelDatas[key]['meta_title'],
                        'qty': excelDatas[key]['stock'] > 0 ? excelDatas[key]['stock'] : 0,
                        'price': excelDatas[key]['price'],
                        'status': 1,
                        'Userid': user.id,
                        'category_id': finalCategories.join("|"),
                        'can_be_set_with_shape': excelDatas[key]['can_be_set_with_shape'],
                        'can_be_set_with_carat': excelDatas[key]['can_be_set_with_carat'],
                        'diamonds': JSON.stringify(diamonds),
                        'parent_sku': null,
                        'video_url': null,
                        'base_image': null,
                        'dimention_image': null,
                        'modal_image': null,
                        'other_image': null,
                        'related_products': excelDatas[key]['related_skus'],
                        'option1_name': excelDatas[key]['option1_name'],
                        'option1_value': excelDatas[key]['option1_value'],
                        'option2_name': excelDatas[key]['option2_name'],
                        'option2_value': excelDatas[key]['option2_value'],
                        'option3_name': excelDatas[key]['option3_name'],
                        'option3_value': excelDatas[key]['option3_value'],
                        'attributes': attributesArr,
                    };
                }
                else {
                    if (products.hasOwnProperty(excelDatas[key]['parent_sku']) && products[excelDatas[key]['parent_sku']] != undefined) {
                        var attributes = excelDatas[key]['attributes'] != null && excelDatas[key]['attributes'].indexOf("|") >= 0 ? excelDatas[key]['attributes'].split("|") : null;
                        let attributesArr = [];
                        if (attributes != '' && attributes != null && attributes.length > 0)
                            attributes.forEach(element => {
                                let attribute = element.split(':');
                                attributesArr.push({ title: attribute[0].trim(), value: attribute[1].trim() });
                            });


                        if (excelDatas[key]['sku'] != "") {
                            products[excelDatas[key]['sku']] = {
                                'sku': excelDatas[key]['sku'],
                                'name': products[excelDatas[key]['parent_sku']]['name'],
                                'attribute_set_id': products[excelDatas[key].parent_sku]['attribute_set_id'],
                                'weight': excelDatas[key]['weight'],
                                'can_be_set_with_shape': products[excelDatas[key]['parent_sku']]['can_be_set_with_shape'],
                                'can_be_set_with_carat': products[excelDatas[key]['parent_sku']]['can_be_set_with_carat'],
                                'is_configured': 0,
                                'description': products[excelDatas[key]['parent_sku']]['description'],
                                'short_description': products[excelDatas[key]['parent_sku']]['short_description'],
                                'is_readytoship': (excelDatas[key]['stock'] > 0 ? 1 : 0),
                                'is_sold': 0,
                                'meta_description': products[excelDatas[key]['parent_sku']]['meta_description'],
                                'meta_keywords': products[excelDatas[key]['parent_sku']]['meta_keywords'],
                                'meta_title': products[excelDatas[key]['parent_sku']]['meta_title'],
                                'qty': excelDatas[key]['stock'] > 0 ? excelDatas[key]['stock'] : 0,
                                'price': excelDatas[key]['price'],
                                'status': 1,
                                'Userid': user.id,
                                'category_id': products[excelDatas[key]['parent_sku']]['category_id'],
                                'diamonds': products[excelDatas[key].parent_sku]['diamonds'],
                                'parent_sku': excelDatas[key]['parent_sku'],
                                'video_url': null,
                                'base_image': null,
                                'dimention_image': null,
                                'modal_image': null,
                                'other_image': null,
                                'related_products': products[excelDatas[key]['parent_sku']]['related_products'],
                                'option1_name': excelDatas[key]['option1_name'],
                                'option1_value': excelDatas[key]['option1_value'],
                                'option2_name': excelDatas[key]['option2_name'],
                                'option2_value': excelDatas[key]['option2_value'],
                                'option3_name': excelDatas[key]['option3_name'],
                                'option3_value': excelDatas[key]['option3_value'],
                                'attributes': products[excelDatas[key]['parent_sku']]['attributes']
                            };
                        }
                    }
                }
            }
            var finalArray = [];
            for (var key in products) {
                finalArray.push(products[key]);
            }
            if (finalArray.length > 0) {
                let apiUrl = IsImagesExist ? API.ImportProducts : API.CheckProductImages;
                if (submitButtonRef.current)
                    submitButtonRef.current.setAttribute("disabled", "disabled");
                HttpService.postApi(API.ImportProducts, finalArray)
                    .then(response => {
                        if (submitButtonRef.current)
                            submitButtonRef.current.removeAttribute("disabled");
                        // console.log(response);
                        // return
                        setLoading(false);
                        if (response.data.result[0][0]?.message != null && response.data.result[0][0]?.message != "") {
                            toast.warning(response.data.result[0][0]?.message)
                            setForceUpdate(!isForceUpdate);
                            return;
                        }
                        if (response.data.result.sku_without_images != undefined) {
                            let skuWithoutBaseImage = response.data.result.sku_without_images;
                            let skuWithoutVideo = response.data.result.sku_without_video;
                            if (skuWithoutBaseImage.length > 0 || skuWithoutVideo.length > 0) {
                                setIsImagesExist(false);

                                swal({
                                    title: Messages.oops,
                                    content: imageErrorMessage(skuWithoutBaseImage, skuWithoutVideo),
                                    icon: "error",
                                    buttons: {
                                        cancel: true,
                                        confirm: false,
                                    }
                                });
                            }
                        }
                        else {
                            toast.success(Messages.Record_Added)
                            setForceUpdate(!isForceUpdate);
                            history.push('/products/ring')
                        }

                    });
            }
            else {
                setLoading(false);
                swal({
                    title: Messages.oops,
                    text: Messages.blank_file,
                    icon: "error",
                    buttons: {
                        cancel: true,
                        confirm: false,
                    }
                });
            }
        });
    }

    return !IsAuth ? <Spinner /> : (
        <div className="content-wrapper pb-0">
            <div className="page-header">
                <h3 className="page-title mb-0"> {Title.ImportExcel} </h3>
                <div className="btn-group">
                    <button type="button" className="btn btn-dark btn-fw rounded mr-2" onClick={history.goBack}> Back </button>
                </div>
            </div>
            <div className="row">
                <div className="col-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <form className="forms-sample">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Upload Excel</label>
                                            <input type="file" name="excel_sheet" onChange={uploadExcelFile} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" style={{ display: 'block' }} className="form-control" />
                                            {validator.current.message('Sheet', Inputs.excel_sheet, 'required', { className: 'error' })}
                                            <a href={exampleSheet} className="float-right" style={{ 'font-size': '12px', 'color': "#000000d4", 'text-decoration': "underline" }} download>Example Sheet</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-md-6">
                                        {/* <button type="button" onClick={SubmitForm} className="btn btn-primary mr-2"> Check Images </button> */}
                                        <button type="button" onClick={SubmitForm} className={true ? "btn btn-primary mr-2" : "btn btn-primary mr-2 disabled"} ref={submitButtonRef}> Submit </button>
                                        <Link to="/products" className="btn btn-light">Cancel</Link>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ImportProducts;