import React, { useEffect, useState, useContext } from 'react';
import { Link ,useHistory} from "react-router-dom";
import UserContext from '../../config/UserContext'
import { HttpService, API, swal, Title, toast, MaterialTable, Messages, DateFormat,Common,Permission_keys } from '../../config/react'
import Moment from 'moment';
import DataTable from 'react-data-table-component';
import Spinner from '../Spinner';
const Banner = () => {

    const history = useHistory();
    const [IsAuth, setIsAuth] = useState(false)
    const [BannerList, setBannerList] = useState([])
    const user = useContext(UserContext);
    const [isForceUpdate, setForceUpdate] = useState(false);
    const [totalRows, settotalRows] = useState()
    const DefaultFilters = {
        page: 1,
        search: "",
        page_size: 10
    }
    const [CustomFilters, setCustomFilters] = useState(DefaultFilters)
    const columns = [
        // {
        //     name: 'Image',
        //     selector: (row) =>
        //         <img src={row.image} width="100" onError={(e) => { e.target.onerror = null; e.target.src = row.altimage }} />
        //     ,
        // },
        {
            name: 'Name',
            selector: row => row.name,
        },
        {
            name: 'Created Date',
            selector: row => <DateFormat value={row.created_at}></DateFormat>,

        },
        {
            name: 'Status',
            selector: row => row.status ? 'Active' : 'Inactive',

        },
        {
            name: 'Action',
            selector: (row) =>
                <div className="action-btn">
                    <Link to={"/widgets/banner/edit/" + row.id} className="text-dark" title="Edit"><i className="fa fa-pencil-square-o" aria-hidden="true"></i> </Link>
                    <Link onClick={e => DeleteBanner(e, row.id)} to="" className="text-dark" title="Delete" ><i className="fa fa-trash-o" aria-hidden="true"></i> </Link>
                </div>
            ,
        },
    ];

    useEffect(() => {
        if (!Common.validatePermission(Permission_keys.Widgets)) {
            history.push("/");
        }
        setIsAuth(true);
        setForceUpdate(!isForceUpdate)
        document.title = Title.Banner
        GetBannerList()
    }, [CustomFilters])

    const GetBannerList = () => {
        var param = {
            "page": CustomFilters.page,
            "search": CustomFilters.search,
            "page_size": CustomFilters.page_size
        }
        HttpService.postApi(API.GetBannerList, param)
            .then(response => {
                console.log(response.data.result);
                setBannerList(response.data.result[1])
                settotalRows(response.data.result[0][0].total)
            });
    }
    const DeleteBanner = async (e, id) => {
        e.preventDefault()
        swal(Messages.Swal_Delete_Prop).then(function (isConfirm) {
            if (isConfirm) {
                var param = {
                    "id": id,
                    "IsDeleted": true,
                    "deleted_by": user.id
                }
                HttpService.postApi(API.DeleteBanner, param)
                    .then(response => {
                        toast.success(Messages.Record_Deleted)
                        GetBannerList()
                        setForceUpdate(!isForceUpdate)
                    });
            }

        });
    }
    const handlePageChange = (e) => {
        setCustomFilters({ ...CustomFilters, ['page']: e });
    }

    const handlePerRowsChange = (e) => {
        setCustomFilters({ ...CustomFilters, ['page_size']: e });
    }
    const subHeaderComponentMemo = React.useMemo(() => {
		return (
            <input type="text" title="Search For Name." className='form-control col-md-3' placeholder='Search' onChange={e => setCustomFilters({ ...CustomFilters, ['search']: e.target.value })} />
		);
	});
    return !IsAuth ? <Spinner /> : (
        <div className="content-wrapper pb-0">
            <div className="page-header">
                <h3 className="page-title"> {Title.Banner} </h3>
                <div className="btn-group">
                    <Link to="/widgets/banner/add" className="btn btn-dark btn-fw rounded mr-2"> Add Banner </Link>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="table-responsive">
                                <DataTable
                                    // title="Coupons"
                                    columns={columns}
                                    data={BannerList}
                                    // progressPending={loading}
                                    pagination
                                    paginationServer
                                    paginationTotalRows={totalRows}
                                    onChangeRowsPerPage={handlePerRowsChange}
                                    onChangePage={handlePageChange}
                                    subHeader
			                        subHeaderComponent={subHeaderComponentMemo}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Banner;