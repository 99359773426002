import React, { useState, useRef, useEffect, useContext } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import UserContext from "../../config/UserContext";
import {
  HttpService,
  API,
  SimpleReactValidator,
  Title,
  toast,
  Messages,
  DropdownTreeSelect,
  Common,
  Permission_keys,
} from "../../config/react";
import Moment from "moment";
import { DateTimePickerComponent } from "@syncfusion/ej2-react-calendars";
import Spinner from "../Spinner";
const AddNewsletter = () => {
  const urlParams = useParams();
  const history = useHistory();
  const [IsAuth, setIsAuth] = useState(false);
  const validator = useRef(new SimpleReactValidator());
  const user = useContext(UserContext);
  const [isForceUpdate, setForceUpdate] = useState(false);
  const [SubscribersList, setSubscribersList] = useState([]);
  const [ExcludeSubscribersList, setExcludeSubscribersList] = useState([]);
  const [Subscribers, setSubscribers] = useState([]);
  const [ExcludeSubscribers, setExcludeSubscribers] = useState([]);
  const [ScheduleOn, setScheduleOn] = useState(new Date());
  const [IsGenderError, setIsGenderError] = useState("");
  const [Inputs, setInputs] = useState({
    template_id: "",
    send_all: 0,
    send_emails: [],
    exclude_emails: [],
    schedule_on: "",
    gender: "",
  });

  useEffect(() => {
    if (!Common.validatePermission(Permission_keys.Newsletter)) {
      history.push("/");
    }
    setIsAuth(true);
    setForceUpdate(!isForceUpdate);
    document.title = Title.AddNewsletter;
    GetSubscriberLists();
  }, []);

  const GetSubscriberLists = () => {
    var param = {
      page: 1,
      search: "",
      page_size: 5000,
    };
    HttpService.postApi(API.GetSubscribersList, param).then((response) => {
      if (response.data.result[0]) {
        var customers = [];
        var customers1 = [];
        var data = response.data.result[1].filter((x) => x.status == 1);
        var data1 = response.data.result[1].filter((x) => x.status == 1);
        data.map((item) => {
          customers.push({
            label: item.email,
            value: item.email,
            children: null,
            checked: false,
          });
        });
        data1.map((item) => {
          customers1.push({
            label: item.email,
            value: item.email,
            children: null,
            checked: false,
          });
        });
        setSubscribersList(customers);
        setExcludeSubscribersList(customers1);
      }
    });
  };

  const handleInputChanges = (e) => {
    var field = e.target.name,
      fieldval = e.target.value;

    if (field == "send_all") Inputs[field] = e.target.checked;
    else Inputs[field] = fieldval;
    if ((Inputs.send_all === 0 || !Inputs.send_all) && Inputs.gender === "") {
      setIsGenderError(true);
    } else {
      setIsGenderError(false);
    }
    setForceUpdate(!isForceUpdate);
  };

  const onChangeSubscriberList = (currentNode, selectedNodes) => {
    var array = [];
    if (selectedNodes.length > 0) {
      for (var i = 0; i < selectedNodes.length; i++) {
        array.push(selectedNodes[i].value);
      }
    }
    setSubscribers(array);
    SubscribersList.map((item, index) => {
      if (array.indexOf(item.value) >= 0) {
        SubscribersList[index].checked = true;
      } else {
        SubscribersList[index].checked = false;
      }
    });
  };

  const onChangeExcludeSubscriberList = (currentNode, selectedNodes) => {
    var array = [];
    if (selectedNodes.length > 0) {
      for (var i = 0; i < selectedNodes.length; i++) {
        array.push(selectedNodes[i].value);
      }
    }
    setExcludeSubscribers(array);
    ExcludeSubscribersList.map((item, index) => {
      if (array.indexOf(item.value) >= 0) {
        ExcludeSubscribersList[index].checked = true;
      } else {
        ExcludeSubscribersList[index].checked = false;
      }
    });
  };

  const SubmitForm = (e) => {
    if (!validator.current.allValid()) {
      validator.current.showMessages();
      if ((Inputs.send_all === 0 || !Inputs.send_all) && Inputs.gender === "") {
        setIsGenderError(true);
      } else {
        setIsGenderError(false);
      }
      setForceUpdate(!isForceUpdate);
      return;
    }
    if ((Inputs.send_all === 0 || !Inputs.send_all) && Inputs.gender === "") {
      setIsGenderError(true);
      return;
    } else {
      setIsGenderError(false);
    }
    var param = {
      template_id: Inputs.template_id,
      send_all: Inputs.send_all == true ? 1 : 0,
      send_emails: JSON.stringify(Subscribers),
      exclude_emails: JSON.stringify(ExcludeSubscribers),
      gender: Inputs.gender,
      schedule_on: new Date(
        Inputs.schedule_on.toLocaleDateString() +
          " " +
          Inputs.schedule_on.toLocaleTimeString() +
          " GMT"
      )
        .toISOString()
        .slice(0, 19)
        .replace("T", " "),
    };
    HttpService.postApi(API.PostNewsletter, param).then((response) => {
      if (response != undefined) {
        if (response.data.message) {
          toast.warning(response.data.message);
          setForceUpdate(!isForceUpdate);
          return;
        }
        if (urlParams.id == null) toast.success(Messages.Record_Added);
        else toast.success(Messages.Record_Updated);
        setForceUpdate(!isForceUpdate);
      }
      history.push("/newsletter/list");
    });
  };
  const ChangeDateTime = (dateTime) => {
    setInputs({ ...Inputs, ["schedule_on"]: dateTime.value });
    setForceUpdate(!isForceUpdate);
  };
  return !IsAuth ? (
    <Spinner />
  ) : (
    <div className="content-wrapper pb-0">
      <div className="page-header">
        <h3 className="page-title mb-0"> {Title.AddNewsletter} </h3>
        <div className="btn-group">
          <button
            type="button"
            className="btn btn-dark btn-fw rounded mr-2"
            onClick={history.goBack}
          >
            {" "}
            Back{" "}
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <form className="forms-sample">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Template Id</label>
                      <input
                        type="text"
                        className="form-control"
                        name="template_id"
                        onChange={handleInputChanges}
                        defaultValue={Inputs.template_id}
                        placeholder="Template Id"
                      />
                      {validator.current.message(
                        "Template Id",
                        Inputs.template_id,
                        "required|max:255",
                        { className: "error" }
                      )}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group row">
                      <label
                        htmlFor="exampleInputUsername2"
                        className="col-sm-12"
                      >
                        Send to All
                      </label>
                      <div className="col-sm-12">
                        <div className="custom-control custom-switch mt-1">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            name="send_all"
                            onChange={handleInputChanges}
                            id="EnableProduct"
                            checked={Inputs.send_all}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="EnableProduct"
                          >
                            {" "}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  {Inputs.send_all == 0 && (
                    <>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Select Emails</label>
                          {SubscribersList.length > 0 && (
                            <DropdownTreeSelect
                              data={SubscribersList}
                              id="userSelect"
                              mode="hierarchical"
                              onChange={onChangeSubscriberList}
                              className="bootstrap-demo w-100"
                            />
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Gender</label>
                          <select
                            className="form-control"
                            name="gender"
                            onChange={handleInputChanges}
                          >
                            <option value="">Select</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                            <option value="other">Other</option>
                          </select>
                          {IsGenderError && (
                            <div className="error">Gender is required</div>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Exclude Emails</label>
                      {ExcludeSubscribersList.length > 0 && (
                        <DropdownTreeSelect
                          data={ExcludeSubscribersList}
                          id="userSelect"
                          mode="hierarchical"
                          onChange={onChangeExcludeSubscriberList}
                          className="bootstrap-demo w-100"
                        />
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Scheduled On</label>
                      {/* <DateTimePicker onChange={ChangeDateTime} value={ScheduleOn} disableClock format='MM-d-y h:mm:ss a'/> */}
                      <DateTimePickerComponent
                        id="datetimepicker"
                        placeholder="Select a date and time"
                        onChange={ChangeDateTime}
                      />
                      {/* <input type="date" className="form-control" name="schedule_on" onChange={handleInputChanges} defaultValue={Inputs.schedule_on} min={Moment().format("YYYY-MM-DD")} placeholder="Scheduled On" /> */}
                      {validator.current.message(
                        "Scheduled On",
                        Inputs.schedule_on,
                        "required",
                        { className: "error" }
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <button
                      type="button"
                      onClick={SubmitForm}
                      className="btn btn-primary mr-2"
                    >
                      {" "}
                      Submit{" "}
                    </button>
                    <Link to="/newsletter/list" className="btn btn-light">
                      Cancel
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNewsletter;
