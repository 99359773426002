
import React, { useEffect, useState, useContext } from 'react';
import { Link ,useHistory} from "react-router-dom";
import UserContext from '../../../config/UserContext'
import { HttpService, API, swal, Title, toast, Messages, DateFormat,Common,Permission_keys } from '../../../config/react'
import DataTable from 'react-data-table-component';
import Spinner from '../../Spinner';
const CustomerGroup = () => {
    const history = useHistory();
    const [IsAuth, setIsAuth] = useState(false)
    const [CustomerGroupList, setCustomerGroupList] = useState([])
    const user = useContext(UserContext);
    const [isForceUpdate, setForceUpdate] = useState(false);

    const columns = [
        {
            name: 'Name',
            selector: row => row.name,
        },
        {
            name: 'Created Date',
            selector: row => <DateFormat value={row.created_at}></DateFormat>

        },
        {
            name: 'Status',
            selector: row => row.status == 1 ? <label>Active</label> : <label>Inactive</label>

        },
        {
            name: 'Action',
            selector: (row) =>
                <div className="action-btn">
                {
                    row.name.toLowerCase() != 'general' ?
                    <div className="action-btn">
                        <Link to={"/system/customer-group/edit/" + row.id} className="text-dark" title="Edit"><i className="fa fa-pencil-square-o" aria-hidden="true"></i> </Link>
                        <Link onClick={e => DeleteCustomerGroup(e, row.id)} to="" className="text-dark" title="Delete" ><i className="fa fa-trash-o" aria-hidden="true"></i> </Link>
                    </div>
                    :
                    '-'
                }
                </div>
            ,
        },
    ];

    const DefaultFilters = {
        page: 1,
        search: "",
        page_size: 10
    }
    const [CustomFilters, setCustomFilters] = useState(DefaultFilters)
    const [totalRows, settotalRows] = useState()

    useEffect(() => {
        if (!Common.validatePermission(Permission_keys.System)) {
            history.push("/");
        }
        document.title = Title.CustomerGroup
        setIsAuth(true);
        setForceUpdate(!isForceUpdate)
        GetCustomerGroupList()
    }, [CustomFilters])


    const GetCustomerGroupList = () => {
        var passData = {
            "page": CustomFilters.page,
            "search": CustomFilters.search,
            "page_size": CustomFilters.page_size
        }
        HttpService.postApi(API.GetCustomerGroupsAdminList, passData)
        .then(response => {
            if (response.data.status == true) {
                var data = response.data.result[1];
                // var data = response.data.result[0];

                setCustomerGroupList(data)
                setForceUpdate(!isForceUpdate)
                settotalRows(response.data.result[0][0].total_row)
            }
        });
    }
    const handlePageChange = (e) => {
        setCustomFilters({ ...CustomFilters, ['page']: e });
    }

    const handlePerRowsChange = (e) => {
        setCustomFilters({ ...CustomFilters, ['page_size']: e });
    }

    const subHeaderComponentMemo = React.useMemo(() => {
		return (
            <input type="text" title="Search For Name." className='form-control col-md-3' placeholder='Search' onChange={e => setCustomFilters({ ...CustomFilters, ['search']: e.target.value })} />
		);
	});

    const DeleteCustomerGroup = async (e, id) => {
        e.preventDefault()
        swal(Messages.Swal_Delete_Prop).then(function (isConfirm) {
            if (isConfirm) {
                var param = {
                    "id": id
                }
                HttpService.postApi(API.DeleteCustomerGroup, param)
                    .then(response => {
                        toast.success(Messages.Record_Deleted)
                        GetCustomerGroupList()
                        setForceUpdate(!isForceUpdate)
                    });
            }

        });
    }

    return !IsAuth ? <Spinner /> : (
        <div className="content-wrapper pb-0">
            <div className="page-header">
                <h3 className="page-title"> {Title.CustomerGroup} </h3>
                <div className="btn-group">
                    <Link to="/system/customer-group/add" className="btn btn-dark btn-fw rounded mr-2"> Add Customer Group </Link>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="table-responsive">
                            <DataTable
                                    columns={columns}
                                    data={CustomerGroupList}
                                    // progressPending={loading}
                                    pagination
                                    paginationServer
                                    paginationTotalRows={totalRows}
                                    onChangeRowsPerPage={handlePerRowsChange}
                                    onChangePage={handlePageChange}
                                    subHeader
			                        subHeaderComponent={subHeaderComponentMemo}
                                />
                                {/* <MaterialTable
                                    title=""
                                    columns={[
                                        { title: 'Name', field: 'name' },
                                        {
                                            title: 'Created Date', field: 'created_at', type: 'datetime',
                                            render: rowData => <DateFormat value={rowData.created_at}></DateFormat>
                                        },
                                        {
                                            title: 'Status', field: 'status',
                                            render: rowData => rowData.status == 1 ? <label>Active</label> : <label>Inactive</label>
                                        },
                                        {
                                            title: 'Action', field: 'id', className: "table_icon", sorting: false,
                                            render: rowData =>
                                                rowData.name.toLowerCase() != 'general' ?
                                                    <div className="action-btn">
                                                        <Link to={"/system/customer-group/edit/" + rowData.id} className="text-dark" title="Edit"><i className="fa fa-pencil-square-o" aria-hidden="true"></i> </Link>
                                                        <Link onClick={e => DeleteCustomerGroup(e, rowData.id)} to="" className="text-dark" title="Delete" ><i className="fa fa-trash-o" aria-hidden="true"></i> </Link>
                                                    </div>
                                                    :
                                                    '-'
                                        }
                                    ]}

                                    data={CustomerGroupList}
                                    options={{
                                        thirdSortClick: false,
                                        //exportButton: true,
                                        pageSize: 10,
                                        emptyRowsWhenPaging: false,
                                        //filtering: true,
                                        exportCsv: (columns, data) => {
                                            alert('You should develop a code to export ' + data.length + ' rows');
                                        },

                                    }}
                                /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CustomerGroup;