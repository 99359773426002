import React, { useEffect, useState, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import UserContext from "../../config/UserContext";
import {
  HttpService,
  API,
  swal,
  Title,
  toast,
  Messages,
  DateFormat,
  Common,
  Permission_keys,
  ExportToExcel
} from "../../config/react";
import Moment from "moment";
import DataTable from "react-data-table-component";
import Spinner from "../Spinner";
const Coupons = () => {
  const history = useHistory();
  const [IsAuth, setIsAuth] = useState(false);
  const [CouponList, setCouponList] = useState([]);
  const user = useContext(UserContext);
  const [isForceUpdate, setForceUpdate] = useState(false);

  const columns = [
    {
      name: "Name",
      selector: (row) => row.coupon_code,
    },
    {
      name: "Expire Date",
      selector: (row) => Moment(row.expire_date).format("YYYY-MM-DD"),
    },
    {
      name: "Created Date",
      selector: (row) => <DateFormat value={row.created_at}></DateFormat>,
    },
    {
      name: "Status",
      selector: (row) =>
        row.status == 1 ? <label>Active</label> : <label>Inactive</label>,
    },
    {
      name: "Action",
      selector: (row) => (
        <div className="action-btn">
          <Link
            to={"/coupons/edit/" + row.id}
            className="text-dark"
            title="Edit"
          >
            <i className="fa fa-pencil-square-o" aria-hidden="true"></i>{" "}
          </Link>
          <Link
            onClick={(e) => DeleteCoupon(e, row.id)}
            to=""
            className="text-dark"
            title="Delete"
          >
            <i className="fa fa-trash-o" aria-hidden="true"></i>{" "}
          </Link>
        </div>
      ),
    },
  ];

  const DefaultFilters = {
    page: 1,
    search: "",
    page_size: 10,
  };
  const [CustomFilters, setCustomFilters] = useState(DefaultFilters);
  const [totalRows, settotalRows] = useState();

  useEffect(() => {
    if (!Common.validatePermission(Permission_keys.Coupons)) {
      history.push("/");
    }
    setIsAuth(true);
    setForceUpdate(!isForceUpdate);
    document.title = Title.Coupons;
    GetCouponList();
  }, [CustomFilters]);

  const GetCouponList = () => {
    var couponData = {
      page: CustomFilters.page,
      search: CustomFilters.search,
      page_size: CustomFilters.page_size,
    };
    // HttpService.postApi(API.GetCouponList, couponData)
    HttpService.postApi(API.GetCouponAdminList, couponData).then((response) => {
      if (response.data.status == true) {
        var data = response.data.result[1];
        // var data = response.data.result[0];

        setCouponList(data);
        setForceUpdate(!isForceUpdate);
        settotalRows(response.data.result[0][0].total_coupon);
      }
    });
  };
  const handlePageChange = (e) => {
    setCustomFilters({ ...CustomFilters, ["page"]: e });
  };

  const handlePerRowsChange = (e) => {
    setCustomFilters({ ...CustomFilters, ["page_size"]: e });
  };

  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <input
        type="text"
        title="Search For Coupon Code."
        className="form-control col-md-3"
        placeholder="Search"
        onChange={(e) =>
          setCustomFilters({ ...CustomFilters, ["search"]: e.target.value })
        }
      />
    );
  });

  const DeleteCoupon = async (e, id) => {
    e.preventDefault();
    swal(Messages.Swal_Delete_Prop).then(function (isConfirm) {
      if (isConfirm) {
        var param = {
          id: id,
          IsDeleted: true,
          deleted_by: user.id,
        };
        HttpService.postApi(API.DeleteCoupon, param).then((response) => {
          toast.success(Messages.Record_Deleted);
          GetCouponList();
          setForceUpdate(!isForceUpdate);
        });
      }
    });
  };
  const handleExportExcel = () => {
    HttpService.getApi(API.GetCouponExcel).then((response) => {
      if (response.data.result) {
        ExportToExcel(response.data.result[0], 'Coupons')
      }
    })
  }
  return !IsAuth ? (
    <Spinner />
  ) : (
    <div className="content-wrapper pb-0">
      <div className="page-header">
        <h3 className="page-title"> {Title.Coupons} </h3>
        <div className="btn-group">
          <Link to="/coupons/add" className="btn btn-dark btn-fw rounded mr-2">
            {" "}
            Add Coupon{" "}
          </Link>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <button
                className="btn btn-primary float-right m-1"
                onClick={handleExportExcel}
              >
                Export Excel
              </button>
              <div className="table-responsive">
                <DataTable
                  // title="Coupons"
                  columns={columns}
                  data={CouponList}
                  // progressPending={loading}
                  pagination
                  paginationServer
                  paginationTotalRows={totalRows}
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                  subHeader
                  subHeaderComponent={subHeaderComponentMemo}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Coupons;
