import React, { useEffect, useState, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import UserContext from "../../config/UserContext";
import {
  HttpService,
  API,
  Permission_keys,
  swal,
  Title,
  toast,
  MaterialTable,
  Messages,
  DateFormat,
  Common,
  ExportToExcel,
} from "../../config/react";
import MultiSelect from "react-multiple-select-dropdown-lite";
import "react-multiple-select-dropdown-lite/dist/index.css";
import { DateRangePickerComponent } from "@syncfusion/ej2-react-calendars";
import "../../assests/fonts/mdi/css/material.css";
import DataTable from "react-data-table-component";
import Spinner from "../Spinner";
const Orders = () => {
  const history = useHistory();
  const [OrderList, setOrderList] = useState([]);
  const user = useContext(UserContext);
  const [IsAuth, setIsAuth] = useState(false);
  const [isForceUpdate, setForceUpdate] = useState(false);
  const [OrderStatusList, setOrderStatusList] = useState([]);
  const [ShowFilters, setShowFilters] = useState(false);
  const [Countries, setCountries] = useState([]);
  const [totalRows, settotalRows] = useState();

  const columns = [
    // {
    //     name: 'Id',
    //     selector: row => row.id,
    // },
    {
      name: "Ordernumber",
      selector: (row) => row.order_number,
    },
    {
      name: "Email",
      selector: (row) => row.email,
    },
    {
      name: "Is Guest",
      selector: (row) => (row.customer_is_guest == 1 ? "Y" : "N"),
    },
    {
      name: "Total",
      selector: (row) => row.symbol + row.final_price,
    },
    {
      name: "Quantity",
      selector: (row) => row.qty,
    },
    {
      name: "Order Status",
      selector: (row) => row.order_status,
    },
    {
      name: "Order Date",
      selector: (row) => <DateFormat value={row.created_at}></DateFormat>,
    },
    {
      name: "Action",
      selector: (row) => (
        <div className="action-btn">
          <Link
            to={"/order/view/order_id/" + row.order_number}
            className="text-dark"
            title="View"
          >
            <i className="fa fa-eye" aria-hidden="true"></i>{" "}
          </Link>
          {row.invoice_path != undefined && row.invoice_path != "" ? (
            <a
              href={row.invoice_path}
              target="_blank"
              className="text-dark"
              title="Invoice"
            >
              <i className="fa fa-file-pdf-o" aria-hidden="true"></i>{" "}
            </a>
          ) : (
            ""
          )}
        </div>
      ),
    },
  ];

  const DefaultFilters = {
    ordernumber: "",
    email: "",
    status: "",
    orderfromdate: "",
    ordertodate: "",
    country: "",
    name: "",
    paymentmethod: "",
    page: 1,
    page_size: 10,
  };
  const [CustomFilters, setCustomFilters] = useState(DefaultFilters);
  useEffect(() => {
    if (!Common.validatePermission(Permission_keys.Sales)) {
      history.push("/");
    }
    setIsAuth(true);
    setForceUpdate(!isForceUpdate);
    document.title = Title.Order;
    GetOrderList();
    GetCountryList();
    GetOrderStatusList();
  }, [CustomFilters]);

  const GetOrderStatusList = (statusId) => {
    HttpService.getApi(API.GetOrderStatusList).then((response) => {
      var data = response.data.result;
      var productData = [];
      for (var key in data) {
        productData.push({
          label: data[key].status,
          value: data[key].status.toUpperCase(),
        });
      }
      setOrderStatusList(productData);
    });
  };

  const GetOrderList = () => {
    var orderData = {
      ordernumber: CustomFilters["ordernumber"],
      email: CustomFilters["email"],
      status: CustomFilters["status"],
      orderfromdate: CustomFilters["orderfromdate"],
      ordertodate: CustomFilters["ordertodate"],
      country: CustomFilters["country"],
      name: CustomFilters["name"],
      paymentmethod: CustomFilters["paymentmethod"],
      page: CustomFilters.page,
      page_size: CustomFilters.page_size,
    };
    // HttpService.postApi(API.GetOrderLists, orderData)
    HttpService.postApi(API.GetOrderAdminLists, orderData).then((response) => {
      var data = response.data.result[1];
      setOrderList(data);
      settotalRows(response.data.result[0][0].total_row);

      setForceUpdate(!isForceUpdate);
      console.log(CustomFilters);
    });
  };

  const handlePageChange = (e) => {
    setCustomFilters({ ...CustomFilters, ["page"]: e });
  };

  const handlePerRowsChange = (e) => {
    setCustomFilters({ ...CustomFilters, ["page_size"]: e });
  };

  const handleInputChanges = (e, fieldName) => {
    if (
      fieldName == "status" ||
      fieldName == "country" ||
      fieldName == "paymentmethod"
    )
      Object.assign(CustomFilters, { [fieldName]: e });
    else if (fieldName == "orderdate") {
      if (e.value != null) {
        Object.assign(CustomFilters, { ["orderfromdate"]: e.value[0] });
        Object.assign(CustomFilters, { ["ordertodate"]: e.value[1] });
      } else {
        Object.assign(CustomFilters, { ["orderfromdate"]: "" });
        Object.assign(CustomFilters, { ["ordertodate"]: "" });
      }
    } else Object.assign(CustomFilters, { [fieldName]: e.target.value });
  };

  const GetCountryList = () => {
    HttpService.getApi(API.GetCountryWithCurrencyList).then((response) => {
      var data = response.data.result[0];
      let obj = [];
      data.map((item, index) => {
        obj.push({ label: item.name, value: item.id });
      });
      Object.assign(Countries, obj);
    });
  };

  const openFilterBox = () => {
    if (ShowFilters) {
      setShowFilters(false);
    } else {
      setShowFilters(true);
    }
  };
  const handleExportExcel = () => {
    HttpService.getApi(API.GetOrderExcel).then((response) => {
      if (response.data.result) {
        ExportToExcel(response.data.result[0], "Orders");
      }
    });
  };

  return !IsAuth ? (
    <Spinner />
  ) : (
    <div className="content-wrapper pb-0">
      <div className="page-header">
        <h3 className="page-title"> {Title.Order} </h3>
        <div className="btn-group ml-auto">
          <Link
            to="#"
            onClick={openFilterBox}
            className="btn btn-primary btn-fw rounded filter-btn mr-2"
          >
            {" "}
            Filters{" "}
          </Link>
        </div>
      </div>
      <div
        className={ShowFilters ? "filter-bar d-block" : "filter-bar"}
        id="filter-bar"
      >
        <div className="grid-margin w-100 stretch-card">
          <div className="card">
            <div className="card-body">
              <form>
                <div className="active-filter-box d-none mb-">
                  <div className="row justify-content-between">
                    <div className="col-md-10">
                      <div className="filter-result-list">
                        <span className="filter-result-repeat-title">
                          Active Filter :{" "}
                        </span>

                        {/* <span className="filter-result-repeat"><a>Name : <button className="btn-close"></button></a></span> */}
                        {/* <span className="filter-result-repeat"><a>Email :  <button className="btn-close"></button></a></span> */}
                        {/* <span className="filter-result-repeat"><a>Customer Group :  <button className="btn-close"></button></a></span>                                      */}
                      </div>
                    </div>
                    <div className="col-md-2 text-right">
                      <Link to="#" className="rounded mr-2 font-13">
                        {" "}
                        Clear All{" "}
                      </Link>
                    </div>
                  </div>
                  <hr></hr>
                </div>
                <div className="row">
                  <div className="col-md-6 col-lg-3">
                    <div className="form-group">
                      <label for="exampleInputEmail1">
                        <strong>Order Number</strong>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => handleInputChanges(e, "ordernumber")}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-3">
                    <div className="form-group">
                      <label for="exampleInputEmail1">
                        <strong>Email</strong>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => handleInputChanges(e, "email")}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-3">
                    <div className="form-group">
                      <label for="exampleInputEmail1">
                        <strong>Name</strong>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => handleInputChanges(e, "name")}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-3">
                    <div className="form-group">
                      <label for="exampleInputEmail1">
                        <strong>Order Date</strong>
                      </label>
                      <DateRangePickerComponent
                        onChange={(e) => handleInputChanges(e, "orderdate")}
                      ></DateRangePickerComponent>
                    </div>
                  </div>

                  {/* <div className="col-md-6 col-lg-6">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1"><strong>Date</strong></label>
                                            <div className="form-group mb-2 row align-items-center">
                                                <div className="col-md-6 pr-md-2">
                                                    <input type="date" className="form-control" placeholder="From" onChange={(e) => handleInputChanges(e, 'orderfromdate')} />
                                                </div>
                                                <div className="col-md-6 pl-md-2">
                                                    <input type="date" className="form-control" placeholder="To" onChange={(e) => handleInputChanges(e, 'ordertodate')} />
                                                </div>
                                            </div>

                                        </div>
                                    </div> */}
                  <div className="col-md-6 col-lg-3">
                    <div className="form-group">
                      <label for="exampleInputEmail1">
                        <strong>Order Status</strong>
                      </label>
                      {
                        <MultiSelect
                          onChange={(e) => handleInputChanges(e, "status")}
                          options={OrderStatusList}
                        />
                      }
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-3">
                    <div className="form-group">
                      <label for="exampleInputEmail1">
                        <strong>Payment Method</strong>
                      </label>
                      {
                        <MultiSelect
                          onChange={(e) =>
                            handleInputChanges(e, "paymentmethod")
                          }
                          options={[
                            { label: "Credit Card", value: "credit_card" },
                            { label: "Paypal", value: "paypal" },
                            { label: "Bank Transfer", value: "bank_transfer" },
                          ]}
                        />
                      }
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-3">
                    <div className="form-group">
                      <label for="exampleInputEmail1">
                        <strong>Country</strong>
                      </label>
                      <MultiSelect
                        onChange={(e) => handleInputChanges(e, "country")}
                        options={Countries}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-3 mt-1 pt-3 text-left">
                    <div className="btn-group">
                      <Link
                        to="#"
                        className="btn btn-dark btn-fw-130 rounded mr-2"
                        onClick={(e) => GetOrderList(e)}
                      >
                        {" "}
                        Apply{" "}
                      </Link>
                    </div>
                    <div className="btn-group ml-auto">
                      {/* <Link to="#" className="btn btn-primary btn-fw rounded mr-2" onClick={e => ClearFilter(e)}> Cancel </Link> */}
                      <span className="filter-result-repeat">
                        <a>
                          <button className="btn btn-primary btn-fw-130 rounded mr-2">
                            Cancel
                          </button>
                        </a>
                      </span>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <button
                className="btn btn-primary float-right m-1"
                onClick={handleExportExcel}
              >
                Export Excel
              </button>
              <div className="table-responsive">
                <DataTable
                  columns={columns}
                  data={OrderList}
                  pagination
                  paginationServer
                  paginationTotalRows={totalRows}
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Orders;
