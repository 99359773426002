import React, { useState, useRef, useEffect } from 'react';
import { HttpService, API, SimpleReactValidator } from '../../config/react'
import { useHistory } from "react-router-dom";
// import logo from '/assests/images/logo-light.png'
// import usericon from '/assests/images/user-icon.svg'
// import passwordicon from '/assests/images/pass-icon.svg'
import { S3_BUCKET_URL } from '../../config/HttpService';
const Login = () => {

    const history = useHistory()
    const isAuthed = JSON.parse(localStorage.getItem("appState"));
    const [IsShowPassword, setIsShowPassword] = useState(false);
    const validator = useRef(new SimpleReactValidator())
    const [errorMessage, seterrorMessage] = useState();
    const [isForceUpdate, setForceUpdate] = useState(false);
    const [inputs, setinputs] = useState({
        Username: "",
        Password: ""
    })

    useEffect(() => {
        if (isAuthed != null && isAuthed.user) {
            ValidateToken(isAuthed.user.token)
            history.push('/')
        }
    }, []);

    const ValidateToken = (token) => {
        var param = {
            "token": token
        }
        HttpService.postApi(API.ValidateToken, param)
            .then(response => {
                console.log(response);
                history.push('/')
            });
    }

    const handleInputChanges = (e) => {
        inputs[e.target.name] = e.target.value;
        setForceUpdate(!isForceUpdate)
    }
    const GetPermissionList = () => {
        HttpService.getApi(API.GetPermission)
            .then(response => {
                if (response.data.status == true) {
                    var data = response.data.result[0];
                    localStorage.setItem("PermissionList",JSON.stringify(data))
                    history.push('/')
                }
            });
    }
    const onKeyUp = (e) => {
        if (e.keyCode === 13) {
            ValidateUser();
        }
    }

    const ValidateUser = () => {
        if (!validator.current.allValid()) {
            if (inputs.Username == "") validator.current.showMessageFor('Username');
            if (inputs.Password == "") validator.current.showMessageFor('Password');
            setForceUpdate(!isForceUpdate)
            return
        }

        var param = {
            "Username": inputs.Username,
            "Password": inputs.Password
        }
        HttpService.postApi(API.ValidateUser, param)
            .then(response => {
                console.log(response);
                if (response.data.result.id == undefined || response.data.result.id == 0) {
                    seterrorMessage(response.data.message)
                    return
                }
                let appState = {
                    isLoggedIn: true,
                    permission: response.data.result.permissions,
                    session_token: response.data.result.token
                };
                localStorage.setItem("PermissionList", JSON.stringify(response.data.result.all_permissions))
                localStorage["appState"] = JSON.stringify(appState);
                //GetPermissionList()
                history.push('/')
            });
    }
    return (
        <div className="login-page" style={{
            backgroundImage: "url(" + S3_BUCKET_URL + 'assests/images/login-dark-bg.jpg' + ")"
        }}>
            <div className="container">
                <div className="login-box">
                    <div className="row align-items-center">
                        <div className="col-md-5 text-center">
                            <div className="login-logo">
                                <img src={S3_BUCKET_URL + 'assests/images/logo-brand-white.svg'} alt="logo" />
                            </div>
                        </div>
                        <div className="col-md-7">
                            <div className="login-form bg-white">
                                <h1 className="logo-name-text">HARMONY JEWELS</h1>
                                <form action="#" id="form" className="login-form-box" method="post">
                                    <div className="input-group mb-4">
                                        <span className="input-group-text"><img src={S3_BUCKET_URL + 'assests/images/user-icon.svg'} className="" alt="user-icon" /></span>
                                        <input type="text" className="form-control" name="Username"
                                            onChange={handleInputChanges}
                                            onKeyUp={onKeyUp}
                                            defaultValue={inputs.Username} placeholder="Username" required />
                                        {validator.current.message('Username', inputs.Username, 'required', { className: 'text-danger' })}
                                    </div>
                                    <div className="input-group mb-4">
                                        <span className="input-group-text"><img src={S3_BUCKET_URL + 'assests/images/pass-icon.svg'} className="" alt="pass-icon" /></span>
                                        <input type={IsShowPassword ? "text" : "password"} className="form-control" name="Password"
                                            onChange={handleInputChanges}
                                            onKeyUp={onKeyUp}
                                            defaultValue={inputs.Password} placeholder="Password" required />
                                            <span className={IsShowPassword ? "fa-eye fa fa-fw field-icon toggle-password" : "fa-eye-slash fa fa-fw field-icon toggle-password"} onClick={() => setIsShowPassword(prevState => !prevState)}></span>
                                        {validator.current.message('Password', inputs.Password, 'required', { className: 'text-danger' })}
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col col-md-7 mb-2">
                                            <div className="form-check my-0">
                                                <label className='text-danger font-12' >{errorMessage}</label>
                                                {/* <input type="checkbox" className="form-check-input" id="exampleCheck1" /> */}
                                                {/* <label className="form-check-label" htmlFor="exampleCheck1">Remember Me</label> */}
                                            </div>
                                        </div>
                                        <div className="col col-md-5">
                                            <div className="text-right">
                                                <p className="mb-0"><a href="#" className="">Forgot Password?</a></p>
                                            </div>
                                        </div>
                                    </div>
                                    <button type="button" onClick={ValidateUser} className="w-100 rounded btn btn-dark login-btn">LOGIN</button>
                                </form>

                                <p className="text-center">&copy; {new Date().getFullYear()} Harmony Jewels.  All Right Reserved.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;