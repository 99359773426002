
import React, { useEffect, useState, useContext } from 'react';
import { Link } from "react-router-dom";
import UserContext from '../../config/UserContext'
import { HttpService, Common,Permission_keys,API, swal, Title, toast, Messages, DateFormat } from '../../config/react'
import DataTable from 'react-data-table-component';
import { useHistory } from 'react-router-dom';
import Spinner from '../Spinner';
const Categories = () => {
    const history = useHistory();
    const [IsAuth, setIsAuth] = useState(false)
    const [CategoryList, setCategoryList] = useState([])
    const user = useContext(UserContext);
    const [isForceUpdate, setForceUpdate] = useState(false);

    const DefaultFilters = {
        page: 1,
        search: "",
        page_size: 10
    }
    const [CustomFilters, setCustomFilters] = useState(DefaultFilters)
    const [totalRows, settotalRows] = useState()

    const columns = [
        {
            name: 'Image',
            selector: row => <img className="py-2" src={row.image} width="100" onError={(e) => { e.target.onerror = null; e.target.src = row.altimage }} />
        },
        {
            name: 'Name',
            selector: row => row.name,
        },
        {
            name: 'Slug',
            selector: row => row.slug,
        },
        {
            name: 'Parent Category',
            selector: row => row.parent_id != 1 ? row.parent_category : '-',
        },
        {
            name: 'Created Date',
            selector: row => <DateFormat value={row.created_at}></DateFormat>,

        },
        {
            name: 'Status',
            selector: row => row.status == 1 ? <label>Active</label> : <label>Inactive</label>,
        },
        {
            name: 'Action',
            selector: (row) =>
                <div className="action-btn">
                    <Link to={"/categories/edit/" + row.id} className="text-dark" title="Edit"><i className="fa fa-pencil-square-o" aria-hidden="true"></i> </Link>
                    <Link onClick={e => DeleteCategory(e, row.id)} to="" className="text-dark" title="Delete" ><i className="fa fa-trash-o" aria-hidden="true"></i> </Link>
                </div>
            ,
        },
    ];

    useEffect(() => {
        if (!Common.validatePermission(Permission_keys.Categories)) {
            history.push("/");
        }
        setIsAuth(true);
        setForceUpdate(!isForceUpdate)
        document.title = Title.Category
        GetCategoryList()
    },[CustomFilters])

    const GetCategoryList = () => {
        var categoryData = {
            "page": CustomFilters.page,
            "search": CustomFilters.search,
            "page_size": CustomFilters.page_size
        }
        // HttpService.postApi(API.GetCategory,null)
        HttpService.postApi(API.GetCategoryAdminList, categoryData)
            .then(response => {
                if (response.data.status == true) {
                    var data = response.data.result[0];
                    setCategoryList(data)
                    setForceUpdate(!isForceUpdate)
                    settotalRows(response.data.result[0][0].total_category)
                }
            });
    }

    const handlePageChange = (e) => {
        setCustomFilters({ ...CustomFilters, ['page']: e });
    }

    const handlePerRowsChange = (e) => {
        setCustomFilters({ ...CustomFilters, ['page_size']: e });
    }
    const subHeaderComponentMemo = React.useMemo(() => {
		return (
            <input type="text" title="Search For Name, Slug, Parent Category." className='form-control col-md-3' placeholder='Search' onChange={e => setCustomFilters({ ...CustomFilters, ['page']: 1, ['search']: e.target.value })} />
		);
	});

    const DeleteCategory = async (e, id) => {
        e.preventDefault()
        swal(Messages.Swal_Delete_Prop).then(function (isConfirm) {
            if (isConfirm) {
                var param = {
                    "id": id,
                    "IsDeleted": true,
                    "deleted_by": user.id
                }
                HttpService.postApi(API.DeleteCategory, param)
                    .then(response => {
                        toast.success(Messages.Record_Deleted)
                        GetCategoryList()
                        setForceUpdate(!isForceUpdate)
                    });
            }

        });
    }

    return !IsAuth ? <Spinner /> : (
        <div className="content-wrapper pb-0">
            <div className="page-header">
                <h3 className="page-title"> {Title.Category} </h3>
                <div className="btn-group">
                    <Link to="/categories/add" className="btn btn-dark btn-fw rounded mr-2"> Add Category </Link>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="table-responsive">
                            <DataTable
                                    columns={columns}
                                    data={CategoryList}
                                    // progressPending={loading}
                                    pagination
                                    paginationServer
                                    paginationTotalRows={totalRows}
                                    onChangeRowsPerPage={handlePerRowsChange}
                                    onChangePage={handlePageChange}
                                    subHeader
			                        subHeaderComponent={subHeaderComponentMemo}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Categories;