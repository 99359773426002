import React, { useState, useRef, useEffect, useContext } from 'react';
import { useHistory, useParams, Link } from "react-router-dom";
import UserContext from '../../config/UserContext'
import { HttpService, API, SimpleReactValidator, Title, toast, Messages, DropdownTreeSelect, FileUploadWithPreview, getBase64, Common, Permission_keys } from '../../config/react'
import Spinner from '../Spinner';
import Moment from 'moment';
const AddCatalogPriceRule = () => {
    const urlParams = useParams()
    const history = useHistory()
    const [IsAuth, setIsAuth] = useState(false)
    const validator = useRef(new SimpleReactValidator())
    let submitbtnRef = useRef();
    const user = useContext(UserContext)
    const [isForceUpdate, setForceUpdate] = useState(false)
    const [Categories, setCategories] = useState([])
    const [DiamondCategories, setDiamondCategories] = useState([])
    const [ExcludeCategories, setExcludeCategories] = useState([])
    const [SelectedCategoryIds, setSelectedCategoryIds] = useState([]);
    const [tempSelectedCategoryIds, settempSelectedCategoryIds] = useState([]);
    const [SelectedExcludeCategoryIds, setSelectedExcludeCategoryIds] = useState([]);
    const [Products, setProducts] = useState([])
    const [ExcludeProducts, setExcludeProducts] = useState([])
    const [SelectedProductsIds, setSelectedProductsIds] = useState([]);
    const [SelectedExcludeProductsIds, setSelectedExcludeProductsIds] = useState([]);
    const today = new Date().toISOString().split('T')[0];
    const [Inputs, setInputs] = useState({
        id: 0,
        rule_name: "",
        description: "",
        discount_type: "percentage",
        discount_percentage: 0,
        discount_amount: 0,
        start_date: "",
        end_date: "",
        status: 0
    });
    useEffect(() => {
        if (!Common.validatePermission(Permission_keys.Marketing)) {
            history.push("/");
        }
        setIsAuth(true);
        setForceUpdate(!isForceUpdate)
        document.title = Title.AddCatalogPriceRule
        if (urlParams.id != null) {
            document.title = Title.EditCatalogPriceRule
            GetCatalogPriceRuleDetail()
        }
        else {
            GetCategories();
            GetDiamondCategories();
            GetProductLists();
        }
    }, [urlParams.id])
    const GetProductLists = (selectedProducts = null, selectedExcludeProducts = null) => {
        HttpService.postApi(API.GetProductsList, null).then(response => {
            if (response.data.result) {
                var products = [];
                var ExcludeProducts = [];
                var data = response.data.result;
                data.map((item) => {
                    products.push({
                        label: item.name + " - " + item.sku,
                        value: item.id,
                        children: null,
                        checked: false
                    })
                    ExcludeProducts.push({
                        label: item.name + " - " + item.sku,
                        value: item.id,
                        children: null,
                        checked: false
                    })
                });
                if (selectedProducts != null) {
                    for (var i = 0; i < products.length; i++) {
                        selectedProducts.map((cate) => {
                            if (cate == products[i].value) {
                                products[i].checked = true;
                            }
                        });
                    }
                }
                if (selectedExcludeProducts != null) {
                    for (var i = 0; i < ExcludeProducts.length; i++) {
                        selectedExcludeProducts.map((cate) => {
                            if (cate == ExcludeProducts[i].value) {
                                ExcludeProducts[i].checked = true;
                            }
                        });
                    }
                }
                setProducts(products);
                setExcludeProducts(ExcludeProducts);
            }
        });
    }
    const GetCategories = (selectedCategory = null, selectedExcludeCategory = null) => {
        HttpService.postApi(API.GetCategory, null).then(response => {
            if (response.data.result) {
                //filter parent category
                var parentCategories = response.data.result[0].filter(x => x.parent_id == 1 && x.is_diamond_category == 0)
                var categories = [];
                var excludeCategories = [];
                parentCategories.map((item) => {
                    //filter sub categories by parent
                    var filteredSubcategories = response.data.result[0].filter(x => x.parent_id == item.id && x.is_diamond_category == 0)
                    var subcategories = [];
                    filteredSubcategories.map((subitem) => {
                        //filter sub child categories by parent
                        var filteredSubChildCategories = response.data.result[0].filter(x => x.parent_id == subitem.id && x.is_diamond_category == 0)
                        var subchildcategories = [];
                        filteredSubChildCategories.map((childitem) => {
                            subchildcategories.push({
                                label: childitem.name,
                                value: childitem.id,
                                checked: false,
                                children: []
                            })
                        });
                        subcategories.push({
                            label: subitem.name,
                            value: subitem.id,
                            checked: false,
                            children: subchildcategories
                        })
                    });
                    var subcategories1 = [];
                    filteredSubcategories.map((subitem) => {
                        //filter sub child categories by parent
                        var filteredSubChildCategories = response.data.result[0].filter(x => x.parent_id == subitem.id && x.is_diamond_category == 0)
                        var subchildcategories1 = [];
                        filteredSubChildCategories.map((childitem) => {
                            subchildcategories1.push({
                                label: childitem.name,
                                value: childitem.id,
                                checked: false,
                                children: []
                            })
                        });
                        subcategories1.push({
                            label: subitem.name,
                            value: subitem.id,
                            checked: false,
                            children: subchildcategories1
                        })
                    });
                    categories.push({
                        label: item.name,
                        value: item.id,
                        children: subcategories,
                        checked: false
                    });
                    excludeCategories.push({
                        label: item.name,
                        value: item.id,
                        children: subcategories1,
                        checked: false
                    });
                });
                let _selectedCategory = []
                if (selectedCategory != null) {
                    for (var i = 0; i < categories.length; i++) {
                        selectedCategory.map((cate) => {
                            if (cate == categories[i].value) {
                                categories[i].checked = true;
                            }
                        });
                        var children = categories[i].children;
                        if (children.length > 0) {
                            for (var j = 0; j < children.length; j++) {
                                selectedCategory.map((cate1) => {
                                    if (cate1 == children[j].value) {
                                        children[j].checked = true;
                                    }
                                });
                                var children1 = children[j].children;
                                if (children1.length > 0) {
                                    for (var k = 0; k < children1.length; k++) {
                                        selectedCategory.map((cate2) => {
                                            if (cate2 == children1[k].value) {
                                                children1[k].checked = true;
                                                _selectedCategory.push(cate2);
                                            }
                                        });
                                    }
                                }
                            }
                        }
                    }
                }
                setCategories(categories)
                setSelectedCategoryIds(_selectedCategory);
                console.log("cat" + Categories);
                if (selectedExcludeCategory != null) {
                    for (var i = 0; i < categories.length; i++) {
                        selectedExcludeCategory.map((cate) => {
                            if (cate == excludeCategories[i].value) {
                                excludeCategories[i].checked = true;
                            }
                        });
                        var children = excludeCategories[i].children;
                        if (children.length > 0) {
                            for (var j = 0; j < children.length; j++) {
                                selectedExcludeCategory.map((cate1) => {
                                    if (cate1 == children[j].value) {
                                        children[j].checked = true;
                                    }
                                });
                                var children1 = children[j].children;
                                if (children1.length > 0) {
                                    for (var k = 0; k < children1.length; k++) {
                                        selectedExcludeCategory.map((cate2) => {
                                            if (cate2 == children1[k].value) {
                                                children1[k].checked = true;
                                            }
                                        });
                                    }
                                }
                            }
                        }
                    }
                }
                setExcludeCategories(excludeCategories)
            }
        });
    }
    const GetDiamondCategories = (selectedCategory = null, selectedExcludeCategory = null) => {
        HttpService.postApi(API.GetCategory, null).then(response => {
            if (response.data.result) {
                //filter parent category
                var parentCategories = response.data.result[0].filter(x => x.parent_id == 1 && x.is_diamond_category == 1)
                var categories = [];
                var excludeCategories = [];
                parentCategories.map((item) => {
                    //filter sub categories by parent
                    var filteredSubcategories = response.data.result[0].filter(x => x.parent_id == item.id && x.is_diamond_category == 1)
                    var subcategories = [];
                    filteredSubcategories.map((subitem) => {
                        //filter sub child categories by parent
                        var filteredSubChildCategories = response.data.result[0].filter(x => x.parent_id == subitem.id && x.is_diamond_category == 1)
                        var subchildcategories = [];
                        filteredSubChildCategories.map((childitem) => {
                            subchildcategories.push({
                                label: childitem.name,
                                value: childitem.id,
                                checked: false,
                                children: []
                            })
                        });
                        subcategories.push({
                            label: subitem.name,
                            value: subitem.id,
                            checked: false,
                            children: subchildcategories
                        })
                    });
                    var subcategories1 = [];
                    filteredSubcategories.map((subitem) => {
                        //filter sub child categories by parent
                        var filteredSubChildCategories = response.data.result[0].filter(x => x.parent_id == subitem.id && x.is_diamond_category == 1)
                        var subchildcategories1 = [];
                        filteredSubChildCategories.map((childitem) => {
                            subchildcategories1.push({
                                label: childitem.name,
                                value: childitem.id,
                                checked: false,
                                children: []
                            })
                        });
                        subcategories1.push({
                            label: subitem.name,
                            value: subitem.id,
                            checked: false,
                            children: subchildcategories1
                        })
                    });
                    categories.push({
                        label: item.name,
                        value: item.id,
                        children: subcategories,
                        checked: false
                    });
                    excludeCategories.push({
                        label: item.name,
                        value: item.id,
                        children: subcategories1,
                        checked: false
                    });
                });
                let _selectedCategory = [];
                if (selectedCategory != null) {
                    for (var i = 0; i < categories.length; i++) {
                        selectedCategory.map((cate) => {
                            if (cate == categories[i].value) {
                                categories[i].checked = true;
                            }
                        });
                        var children = categories[i].children;
                        if (children.length > 0) {
                            for (var j = 0; j < children.length; j++) {
                                selectedCategory.map((cate1) => {
                                    if (cate1 == children[j].value) {
                                        children[j].checked = true;
                                    }
                                });
                                var children1 = children[j].children;
                                if (children1.length > 0) {
                                    for (var k = 0; k < children1.length; k++) {
                                        selectedCategory.map((cate2) => {
                                            if (cate2 == children1[k].value) {
                                                children1[k].checked = true;
                                                _selectedCategory.push(cate2);
                                            }
                                        });
                                    }
                                }
                            }
                        }
                    }
                }
                setDiamondCategories(categories)
                settempSelectedCategoryIds(_selectedCategory);
                console.log("cat" + DiamondCategories);
                if (selectedExcludeCategory != null) {
                    for (var i = 0; i < categories.length; i++) {
                        selectedExcludeCategory.map((cate) => {
                            if (cate == excludeCategories[i].value) {
                                excludeCategories[i].checked = true;
                            }
                        });
                        var children = excludeCategories[i].children;
                        if (children.length > 0) {
                            for (var j = 0; j < children.length; j++) {
                                selectedExcludeCategory.map((cate1) => {
                                    if (cate1 == children[j].value) {
                                        children[j].checked = true;
                                    }
                                });
                                var children1 = children[j].children;
                                if (children1.length > 0) {
                                    for (var k = 0; k < children1.length; k++) {
                                        selectedExcludeCategory.map((cate2) => {
                                            if (cate2 == children1[k].value) {
                                                children1[k].checked = true;
                                            }
                                        });
                                    }
                                }
                            }
                        }
                    }
                }
                setExcludeCategories(excludeCategories)
            }
        });
    }
    const onChangeCategory = (currentNode, selectedNodes) => {
        var array = [];
        if (selectedNodes.length > 0) {
            for (var i = 0; i < selectedNodes.length; i++) {
                array.push(selectedNodes[i].value);
            }
        }
        setSelectedCategoryIds(array);
        setForceUpdate(!isForceUpdate);
        console.log(array);
        Categories.map((item, index) => {
            if (array.indexOf(item.value) >= 0) {
                Categories[index].checked = true;
            }
            else {
                Categories[index].checked = false;
            }
            item.children.map((subitem, subindex) => {
                if (array.indexOf(subitem.value) >= 0) {
                    Categories[index].children[subindex].checked = true;
                }
                else {
                    Categories[index].children[subindex].checked = false;
                }
                subitem.children.map((subitem1, subindex1) => {
                    if (array.indexOf(subitem1.value) >= 0) {
                        Categories[index].children[subindex].children[subindex1].checked = true;
                    }
                    else {
                        Categories[index].children[subindex].children[subindex1].checked = false;
                    }
                })
            })
        });
    };
    const onChangeDiamondCategory = (currentNode, selectedNodes) => {
        var array = [];
        if (selectedNodes.length > 0) {
            for (var i = 0; i < selectedNodes.length; i++) {
                array.push(selectedNodes[i].value);
            }
        }
        settempSelectedCategoryIds(array);
        setForceUpdate(!isForceUpdate);
        console.log(array);
        DiamondCategories.map((item, index) => {
            if (array.indexOf(item.value) >= 0) {
                DiamondCategories[index].checked = true;
            }
            else {
                DiamondCategories[index].checked = false;
            }
            item.children.map((subitem, subindex) => {
                if (array.indexOf(subitem.value) >= 0) {
                    DiamondCategories[index].children[subindex].checked = true;
                }
                else {
                    DiamondCategories[index].children[subindex].checked = false;
                }
                subitem.children.map((subitem1, subindex1) => {
                    if (array.indexOf(subitem1.value) >= 0) {
                        DiamondCategories[index].children[subindex].children[subindex1].checked = true;
                    }
                    else {
                        DiamondCategories[index].children[subindex].children[subindex1].checked = false;
                    }
                })
            })
        });
    };
    const onChangeExcludeCategory = (currentNode, selectedNodes) => {
        var array = [];
        if (selectedNodes.length > 0) {
            for (var i = 0; i < selectedNodes.length; i++) {
                array.push(selectedNodes[i].value);
            }
        }
        setSelectedExcludeCategoryIds(array);
        setForceUpdate(!isForceUpdate);
        ExcludeCategories.map((item, index) => {
            if (array.indexOf(item.value) >= 0) {
                ExcludeCategories[index].checked = true;
            }
            else {
                ExcludeCategories[index].checked = false;
            }
            item.children.map((subitem, subindex) => {
                if (array.indexOf(subitem.value) >= 0) {
                    ExcludeCategories[index].children[subindex].checked = true;
                }
                else {
                    ExcludeCategories[index].children[subindex].checked = false;
                }
                subitem.children.map((subitem1, subindex1) => {
                    if (array.indexOf(subitem1.value) >= 0) {
                        ExcludeCategories[index].children[subindex].children[subindex1].checked = true;
                    }
                    else {
                        ExcludeCategories[index].children[subindex].children[subindex1].checked = false;
                    }
                })
            })
        });
    };
    const onChangeProduct = (currentNode, selectedNodes) => {
        var array = [];
        if (selectedNodes.length > 0) {
            for (var i = 0; i < selectedNodes.length; i++) {
                array.push(selectedNodes[i].value);
            }
        }
        setSelectedProductsIds(array);
        setForceUpdate(!isForceUpdate);
        Products.map((item, index) => {
            if (array.indexOf(item.value) >= 0) {
                Products[index].checked = true;
            }
            else {
                Products[index].checked = false;
            }
        });
    };
    const onChangeExcludeProduct = (currentNode, selectedNodes) => {
        var array = [];
        if (selectedNodes.length > 0) {
            for (var i = 0; i < selectedNodes.length; i++) {
                array.push(selectedNodes[i].value);
            }
        }
        setSelectedExcludeProductsIds(array);
        setForceUpdate(!isForceUpdate);
        ExcludeProducts.map((item, index) => {
            if (array.indexOf(item.value) >= 0) {
                ExcludeProducts[index].checked = true;
            }
            else {
                ExcludeProducts[index].checked = false;
            }
        });
    };
    const handleInputChanges = (e) => {
        var field = e.target.name, fieldval = e.target.value;

        if (field == "status")
            Inputs[field] = e.target.checked;
        else
            Inputs[field] = fieldval;
        setForceUpdate(!isForceUpdate);
    }
    const SubmitForm = (e) => {
        if (!validator.current.allValid()) {
            validator.current.showMessages();
            setForceUpdate(!isForceUpdate)
            return
        }
        let sDate = new Date(Inputs.start_date)
        let eDate = new Date(Inputs.end_date)

        if (eDate == sDate) {
            toast.warning(Messages.same_date)
            return
        }
        if (eDate < sDate) {
            toast.warning(Messages.small_date)
            return
        }
        let cat_diamondcat = (SelectedCategoryIds != null ? SelectedCategoryIds : []).concat(tempSelectedCategoryIds);
        if (submitbtnRef.current)
            submitbtnRef.current.setAttribute("disabled", "disabled");
        var param = {
            "id": Inputs.id,
            "rule_name": Inputs.rule_name,
            "description": Inputs.description,
            "discount_type": Inputs.discount_type,
            "discount_amount": parseInt(Inputs.discount_amount),
            "discount_percentage": parseInt(Inputs.discount_percentage),
            "start_date": Inputs.start_date,
            "end_date": Inputs.end_date,
            // "category_ids": (SelectedProductsIds.length > 0 || SelectedExcludeProductsIds.length > 0) ? '[]' : JSON.stringify(cat_diamondcat),
            "category_ids": cat_diamondcat,
            "exclude_category_ids": (SelectedProductsIds?.length > 0 || SelectedExcludeProductsIds?.length > 0) ? '[]' : JSON.stringify(SelectedExcludeCategoryIds),
            "product_ids": (SelectedCategoryIds?.length > 0 || SelectedExcludeCategoryIds?.length > 0) ? '[]' : JSON.stringify(SelectedProductsIds),
            'exclude_product_ids': (SelectedCategoryIds?.length > 0 || SelectedExcludeCategoryIds?.length > 0) ? '[]' : JSON.stringify(SelectedExcludeProductsIds),
            "status": Inputs.status ? 1 : 0,
            "Userid": user.id
        }
        if (Inputs.discount_type == "percentage") {
            param.discount_amount = 0;
        }
        else {
            param.discount_percentage = 0;
        }
        if (urlParams.id == null) {
            HttpService.postApi(API.AddCatalogPriceRule, param).then(response => {
                if (response.data.message) {
                    toast.warning(response.data.message)
                    setForceUpdate(!isForceUpdate);
                    submitbtnRef.current.removeAttribute("disabled");
                    return
                }
                toast.success(Messages.Record_Added)
                setForceUpdate(!isForceUpdate);
                history.push('/marketing/catalog-price-rule')
            });
        }
        else {
            HttpService.postApi(API.UpdateCatalogPriceRule, param).then(response => {
                if (response.data.message) {
                    toast.warning(response.data.message)
                    setForceUpdate(!isForceUpdate);
                    submitbtnRef.current.removeAttribute("disabled");
                    return
                }
                toast.success(Messages.Record_Updated)
                setForceUpdate(!isForceUpdate);
                history.push('/marketing/catalog-price-rule')
            });
        }
    }
    const GetCatalogPriceRuleDetail = () => {
        var param = {
            "id": urlParams.id
        }
        HttpService.postApi(API.GetCatalogPriceRuleDetail, param).then(response => {
            if (response.data.result) {
                var data = response.data.result[0][0];

                var responseData = {
                    id: data.id,
                    rule_name: data.rule_name,
                    description: data.description,
                    start_date: data.start_date,
                    end_date: data.end_date,
                    discount_type: data.discount_type,
                    discount_amount: data.discount_amount,
                    discount_percentage: data.discount_percentage,
                    status: data.status
                }
                if (responseData.start_date != "") {
                    var date = Moment(responseData.start_date).format("YYYY-MM-DD");
                    date = date + 'T' + Moment(responseData.start_date).format("HH:mm");
                    responseData.start_date = date;
                }
                if (responseData.end_date != "") {
                    var date = Moment(responseData.end_date).format("YYYY-MM-DD");
                    date = date + 'T' + Moment(responseData.end_date).format("HH:mm");
                    responseData.end_date = date;
                }
                setInputs(responseData)
                setForceUpdate(!isForceUpdate)
                //setSelectedCategoryIds(data.category_ids != null ? Common.GetJSON(data.category_ids) : []);
                setSelectedExcludeCategoryIds(data.exclude_categories != null ? Common.GetJSON(data.exclude_categories) : []);
                setSelectedProductsIds(data.product_ids.length > 0 ? Common.GetJSON(data.product_ids) : []);
                setSelectedExcludeProductsIds(data.exclude_products != null ? Common.GetJSON(data.exclude_products) : []);
                GetCategories(Common.GetJSON(data.category_ids), Common.GetJSON(data.exclude_categories));
                GetDiamondCategories(Common.GetJSON(data.category_ids), Common.GetJSON(data.exclude_categories));
                GetProductLists(Common.GetJSON(data.product_ids), Common.GetJSON(data.exclude_products));
                setForceUpdate(!isForceUpdate)
            }
        });
    }
    return !IsAuth ? <Spinner /> : (
        <div className="content-wrapper pb-0">
            <div className="page-header">
                <h3 className="page-title mb-0"> {(urlParams.id == null) ? Title.AddCatalogPriceRule : Title.EditCatalogPriceRule} </h3>
                <div className="btn-group">
                    <button type="button" className="btn btn-dark btn-fw rounded mr-2" onClick={history.goBack}> Back </button>
                </div>
            </div>
            <div className="row">
                <div className="col-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <form className="forms-sample">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Rule Name</label>
                                            <input type="text" className="form-control" name="rule_name" onChange={handleInputChanges} value={Inputs.rule_name} placeholder="Rule Name" />
                                            {validator.current.message('Rule Name', Inputs.rule_name, 'required|max:255', { className: 'error' })}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group row">
                                            <label htmlFor="exampleInputUsername2" className="col-sm-12">Status</label>
                                            <div className="col-sm-12">
                                                <div className="custom-control custom-switch mt-1">
                                                    <input type="checkbox" className="custom-control-input" name="status" onChange={handleInputChanges} id="EnableProduct" checked={Inputs.status} />
                                                    <label className="custom-control-label" htmlFor="EnableProduct"> </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>Description</label>
                                            <textarea className="form-control" name="description" onChange={handleInputChanges} value={Inputs.description} placeholder="Description" rows="2"></textarea>
                                            {validator.current.message('Description', Inputs.description, 'required', { className: 'error' })}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Discount Type</label>
                                            <select className="form-control" name="discount_type" onChange={handleInputChanges} value={Inputs.discount_type}>
                                                <option value="percentage">Percentage</option>
                                                <option value="fixed_amount">Fixed Amount</option>
                                            </select>
                                            {validator.current.message('Discount Type', Inputs.discount_type, 'required', { className: 'error' })}
                                        </div>
                                    </div>
                                    {
                                        Inputs.discount_type == "percentage" ?
                                            <div className="col-md-6">
                                                <label>Percentage</label>
                                                <input type="number" className="form-control" name="discount_percentage" onChange={handleInputChanges} value={Inputs.discount_percentage} placeholder="Discount Percentage" />
                                                {validator.current.message('Discount Percentage', Inputs.discount_percentage, 'required|numeric|max:99,num|min:0,num', { className: 'error' })}
                                            </div>
                                            : ""
                                    }
                                    {
                                        Inputs.discount_type == "fixed_amount" ?
                                            <div className="col-md-6">
                                                <label>Amount</label>
                                                <input type="number" className="form-control" name="discount_amount" onChange={handleInputChanges} value={Inputs.discount_amount} placeholder="Discount Amount" />
                                                {validator.current.message('Discount Amount', Inputs.discount_amount, 'required|numeric|min:0,num', { className: 'error' })}
                                            </div>
                                            : ""
                                    }
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Start Date</label>
                                            <input type="datetime-local" className="form-control" name="start_date" onChange={handleInputChanges} value={Inputs.start_date} placeholder="Start Date" min={today} />
                                            {validator.current.message('Start Date', Inputs.start_date, 'required', { className: 'error' })}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>End Date</label>
                                            <input type="datetime-local" className="form-control" name="end_date" onChange={handleInputChanges} value={Inputs.end_date} placeholder="End Date" min={today} />
                                            {validator.current.message('End Date', Inputs.end_date, 'required', { className: 'error' })}
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <div className="form-group">
                                                    <label>Diamond Category</label>
                                                    {
                                                        <DropdownTreeSelect data={DiamondCategories} id="diamondSelect" mode="hierarchical" onChange={onChangeDiamondCategory} className="bootstrap-demo w-100" />
                                                    }
                                                </div>
                                            </div>
                                            <div className={(SelectedCategoryIds?.length > 0 || SelectedExcludeCategoryIds?.length > 0) ? 'col-md-6 d-none' : 'col-md-6'}>
                                                <div className="form-group">
                                                    <label>Products</label>
                                                    {
                                                        Products.length > 0 &&
                                                        <DropdownTreeSelect data={Products} id="productSelect" mode="hierarchical" onChange={onChangeProduct} className="bootstrap-demo w-100" />
                                                    }
                                                </div>
                                            </div>
                                            <div className={(SelectedCategoryIds?.length > 0 || SelectedExcludeCategoryIds?.length > 0) ? 'col-md-6 d-none' : 'col-md-6'}>
                                                <div className="form-group">
                                                    <label>Exclude Products</label>
                                                    {
                                                        ExcludeProducts.length > 0 &&
                                                        <DropdownTreeSelect data={ExcludeProducts} id="excludeProductSelect" mode="hierarchical" onChange={onChangeExcludeProduct} className="bootstrap-demo w-100" />
                                                    }
                                                </div>
                                            </div>
                                            <div className={(SelectedProductsIds?.length > 0 || SelectedExcludeProductsIds?.length > 0) ? 'col-md-6' : 'col-md-6'}>
                                                <div className="form-group">
                                                    <label>Category</label>
                                                    {
                                                        Categories.length > 0 &&
                                                        <DropdownTreeSelect data={Categories} id="categorySelect" mode="hierarchical" onChange={onChangeCategory} className="bootstrap-demo w-100" />
                                                    }
                                                </div>
                                            </div>
                                            <div className={(SelectedProductsIds?.length > 0 || SelectedExcludeProductsIds?.length > 0) ? 'col-md-6 d-none' : 'col-md-6'}>
                                                <div className="form-group">
                                                    <label>Exclude Category</label>
                                                    {
                                                        ExcludeCategories.length > 0 &&
                                                        <DropdownTreeSelect data={ExcludeCategories} id="excludeCategorySelect" mode="hierarchical" onChange={onChangeExcludeCategory} className="bootstrap-demo w-100" />
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <button ref={submitbtnRef} type="button" onClick={SubmitForm} className="btn btn-primary mr-2"> Submit </button>
                                        <Link to="/marketing/catalog-price-rule" className="btn btn-light">Cancel</Link>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddCatalogPriceRule;