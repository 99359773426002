
import React, { useEffect, useState, useContext } from 'react';
import { Link, useHistory } from "react-router-dom";
import UserContext from '../../../config/UserContext'
import { HttpService, API, swal, Title, toast, Messages, DateFormat, Common, Permission_keys } from '../../../config/react'
import DataTable from 'react-data-table-component';
import Spinner from '../../Spinner';

const Permissions = () => {
    const history = useHistory();
    const [IsAuth, setIsAuth] = useState(false)
    const [PermissionList, setPermissionList] = useState([])
    const user = useContext(UserContext);
    const [isForceUpdate, setForceUpdate] = useState(false);

    const columns = [
        {
            name: 'Name',
            selector: row => row.name,
        },
        {
            name: 'Description',
            selector: row => row.description,
        },
        {
            name: 'Key',
            selector: row => row.key,
        },
        {
            name: 'Created Date',
            selector: row => <DateFormat value={row.created_at}></DateFormat>

        },
        {
            name: 'Action',
            selector: (row) =>
                <div className="action-btn">
                    {

                        <div className="action-btn">
                            <Link to={"/permission/edit/" + row.id} className="text-dark" title="Edit"><i className="fa fa-pencil-square-o" aria-hidden="true"></i> </Link>
                            <Link onClick={e => DeletePermission(e, row.id)} to="" className="text-dark" title="Delete" ><i className="fa fa-trash-o" aria-hidden="true"></i> </Link>
                        </div>

                    }
                </div>
            ,
        },
    ];

    const DefaultFilters = {
        page: 1,
        search: "",
        page_size: 10
    }
    const [CustomFilters, setCustomFilters] = useState(DefaultFilters)

    useEffect(() => {
        if (!Common.validatePermission(Permission_keys.role_permission)) {
            history.push("/");
        }
        setIsAuth(true);
        setForceUpdate(!isForceUpdate)
        GetPermissionList()
    }, [CustomFilters])
    useEffect(() => {
        document.title = Title.Permissions
    }, [])

    const GetPermissionList = () => {
        HttpService.getApi(API.GetPermission)
            .then(response => {
                if (response.data.status == true) {
                    var data = response.data.result[0];
                    setPermissionList(data)
                    setForceUpdate(!isForceUpdate)
                }
            });
    }
    const handlePageChange = (e) => {
        setCustomFilters({ ...CustomFilters, ['page']: e });
    }

    const handlePerRowsChange = (e) => {
        setCustomFilters({ ...CustomFilters, ['page_size']: e });
    }

    const subHeaderComponentMemo = React.useMemo(() => {
        return (
            <input type="text" title="Search For Name." className='form-control col-md-3' placeholder='Search' onChange={e => setCustomFilters({ ...CustomFilters, ['search']: e.target.value })} />
        );
    });

    const DeletePermission = async (e, id) => {
        e.preventDefault()
        swal(Messages.Swal_Delete_Prop).then(function (isConfirm) {
            if (isConfirm) {
                var param = {
                    "id": id
                }
                HttpService.postApi(API.DeletePermission, param)
                    .then(response => {
                        toast.success(Messages.Record_Deleted)
                        GetPermissionList()
                        setForceUpdate(!isForceUpdate)
                    });
            }

        });
    }

    return !IsAuth ? <Spinner /> : (
        <div className="content-wrapper pb-0">
            <div className="page-header">
                <h3 className="page-title"> {Title.Permissions} </h3>
                <div className="btn-group">
                    <Link to="/permission/add" className="btn btn-dark btn-fw rounded mr-2"> Add Permission </Link>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <div className="table-responsive">
                                <DataTable
                                    columns={columns}
                                    data={PermissionList}
                                    // progressPending={loading}
                                    // pagination
                                    paginationServer
                                    // paginationTotalRows={totalRows}
                                    onChangeRowsPerPage={handlePerRowsChange}
                                    onChangePage={handlePageChange}
                                    subHeader
                                // subHeaderComponent={subHeaderComponentMemo}
                                />

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Permissions;